import axios from "axios";

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const NEWSLETTER_URI = '/newsletter-subscriptions'

// Sign up for newsletter
const signUpForNewsletter = async (data) => {

    const config = {
        headers: {
            "Content-Type": 'application/json'
        }
    }

    const response = await axios.post(`${API_URL}${NEWSLETTER_URI}`, {
        data: data
    }, config)

    return response
}

const newsletterService = {
    signUpForNewsletter
}

export default newsletterService