import React from 'react'
import { FaWhatsapp, FaForward, FaMessage, FaShareNodes, FaLinkedin, FaXTwitter, FaFacebook } from 'react-icons/fa6'
import './styles/ShareFloatingActionButton.css'

const ShareFloatingActionButton = ({ article }) => {

    const handleFacebookShareButtonClick = () => {
        const articleId = article?.data?.attributes?.Slug
        const shareableLink = `${window.location.origin}?article=${articleId}`

        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableLink)}`

        window.open(facebookShareUrl, '_blank')

    }

    const handleTwitterShareButtonClick = () => {
        const articleId = article?.data?.attributes?.Slug
        const shareableLink = `${window.location.origin}?article=${articleId}`

        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareableLink)}&text=${encodeURIComponent(article?.data?.attributes?.Title)}`

        window.open(twitterShareUrl, '_blank')
    }

    const handleLinkedInButtonClick = () => {
        const articleId = article?.data?.attributes?.Slug
        const shareableLink = `${window.location.origin}?article=${articleId}`

        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareableLink)}`

        window.open(linkedInShareUrl, '_blank')
    }

    return (
        <>
            <div className="share-fab-container fixed bottom-32 right-12 md:right-24 z-10">
                <div className="share-fab share-fab-icon-holder pt-3">
                    <FaShareNodes className='size-8 mx-auto text-white' />
                </div>

                <ul className="share-fab-options">
                    <li>
                        <span className="share-fab-label">Share to Facebook</span>
                        <a href="#!" id="feedbackBtn">
                            <div className="share-fab-icon-holder pt-3">
                                <FaFacebook className='size-6 mx-auto text-white' onClick={handleFacebookShareButtonClick} />
                            </div>
                        </a>
                    </li>
                    <li>
                        <span className="share-fab-label">Share to X</span>
                        <a href="#!" id="feedbackBtn">
                            <div className="share-fab-icon-holder pt-3">
                                <FaXTwitter className='size-6 mx-auto text-white' onClick={handleTwitterShareButtonClick} />
                            </div>
                        </a>
                    </li>
                    <li>
                        <span className="share-fab-label">Share to LinkedIn</span>
                        <a href="#!" id="feedbackBtn">
                            <div className="share-fab-icon-holder pt-3">
                                <FaLinkedin className='size-6 mx-auto text-white' onClick={handleLinkedInButtonClick} />
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ShareFloatingActionButton