import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import shopService from './shopService'

const initialState = {
    shopCategories: [],
    products: [],
    isErrorShop: false,
    isSuccessShop: false,
    isLoadingShop: false,
    message: ''
}

// Get Shop Categories
export const getShopCategories = createAsyncThunk('shopCategories/list', async (_, thunkAPI) => {
    try {
        
        return await shopService.getShopCategories()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Products
export const getProducts = createAsyncThunk('products/list', async (_, thunkAPI) => {
    try {
        
        return await shopService.getProducts()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingShop = false
            state.isErrorShop = false
            state.isSuccessShop = false
            state.message = ''
            state.shopCategories = []
            state.products = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShopCategories.pending, (state) => {
                state.isLoadingShop = true
            })
            .addCase(getShopCategories.fulfilled, (state, action) => {
                state.isLoadingShop = false
                state.isSuccessShop = true
                state.shopCategories = action.payload
            })
            .addCase(getShopCategories.rejected, (state, action) => {
                state.isLoadingShop = false
                state.isErrorShop = true
                state.message = action.payload
                state.shopCategories = []
            })
            .addCase(getProducts.pending, (state) => {
                state.isLoadingShop = true
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.isLoadingShop = false
                state.isSuccessShop = true
                state.products = action.payload
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.isLoadingShop = false
                state.isErrorShop = true
                state.message = action.payload
                state.products = []
            })
    }
})

export const { reset } = shopSlice.actions
export default shopSlice.reducer