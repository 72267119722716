import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { scroller, scrollSpy } from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import './output.css'
import './App.css'
import { getBlogArticle } from './features/blog/blogSlice'

import NavBar from './components/Navigation/NavBar/NavBar'
import Home from './pages/Home/Home'
import Shop from './pages/Shop/Shop'
import ShoppingCart from './components/ShoppingCart/ShoppingCart'
import AboutUs from './pages/AboutUs/AboutUs'
import Media from './pages/Media/Media'
import Blog from './pages/Blog/Blog'
import Footer from './pages/Footer/Footer'
import Checkout from './components/Checkout/Checkout'
import ResourceArticle from './pages/Blog/ArticlePages/ResourcesArticles/ResourceArticle'
import AgribusinessArticle from './pages/Blog/ArticlePages/AgribusinessArticles/AgribusinessArticle'
import EventsArticle from './pages/Blog/ArticlePages/EventsArticles/EventsArticle'
import NewsletterArticle from './pages/Blog/ArticlePages/NewsletterArticles/NewsletterArticle'
import Testimonials from './components/Testimonials/Testimonials'


function App() {

  const dispatch = useDispatch()

  const [activeLink, setActiveLink] = useState(null)
  const [cartItemCount, setCartItemCount] = useState(0)
  const [articleSlug, setArticleSlug] = useState(null)

  const { blogArticles } = useSelector((state) => state.blog)

  const checkLocalStorage = localStorage.getItem('cart')

  const cartItems = {
    items: [],
    quantity: 0
  }

  if (!checkLocalStorage) {
    localStorage.setItem('cart', JSON.stringify(cartItems))
  }

  // Get sections and nav elements
  const sectionsRef = useRef([])
  const navRef = useRef(null)
  const nav_heightRef = useRef(0)

  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const slug = params.get('article')
    
    if (slug) {
      setArticleSlug(slug)
    }
    
  }, [])

  useEffect(() => {
    if (articleSlug) {
      const article = blogArticles?.data?.find(article => article?.attributes?.Slug === articleSlug)
      
      if (article) {
        dispatch(getBlogArticle(article?.id))

        // Hide all panels first
        document.getElementById('resource-article-panel')?.classList.add('hidden');
        document.getElementById('agribusiness-article-panel')?.classList.add('hidden');
        document.getElementById('newsletter-article-panel')?.classList.add('hidden');

        const category = article?.attributes?.BlogCategory?.data?.attributes?.Category?.toLowerCase()

        if (category === 'resources') {
          document.getElementById('resource-article-panel').classList.remove('hidden')
        } else if (category === 'agribusiness') {
          document.getElementById('agribusiness-article-panel').classList.remove('hidden')
        } else if (category === 'newsletters') {
          document.getElementById('newsletter-article-panel').classList.remove('hidden')
        }
      }
    }
  }, [articleSlug, blogArticles, dispatch])

  useEffect(() => {
    scroller.scrollTo('home', {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -nav_heightRef.current,
      isDynamic: true // Needed for updating active link state
    })
  }, [])

  useEffect(() => {
    const sections = sectionsRef.current
    const nav = navRef.current

    if (!nav) return // If nav is null, return early

    nav_heightRef.current = nav.offsetHeight

    function onScroll() {
      const cur_pos = window.scrollY

      sections.forEach(sectionRef => {
        const section = sectionRef.current
        const top = section.offsetTop - nav_heightRef.current
        const bottom = top + section.offsetHeight

        if (cur_pos >= top && cur_pos <= bottom) {
          // Remove active class from all navigation links and sections
          nav.querySelectorAll('a').forEach(link => {
            link.classList.remove('active')
          })

          sections.forEach(secRef => {
            secRef.current.classList.remove('active')
          })

          // Add active class to current section and its corresponding navigation link
          section.classList.add('active')
          nav.querySelector(`a[href="#$${section.id}"]`).classList.add('active')
        }
      })
    }

    scrollSpy.update()

    // Add scroll event listener
    window.addEventListener('scroll', onScroll)

    return () => {
      // Clean up scroll event listener
      window.removeEventListener('scroll', onScroll)
    }

  }, [])

  function onNavClick(id) {
    scroller.scrollTo(id, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -nav_heightRef.current,
      isDynamic: true // Needed for updating active link state
    })
  }

  useEffect(() => {
    const nav = navRef.current
    if (!nav) return

    // Add click event listener to all navigation links
    nav.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', onNavClick)
    })

    return () => {
      // Clean up click event listener
      nav.querySelectorAll('a').forEach(link => {
        link.removeEventListener('click', onNavClick)
      })
    }
  }, [])

  return (
    <>
      <div>
        <NavBar onNavClick={onNavClick} activeLink={activeLink} cartItemCount={cartItemCount} setCartItemCount={setCartItemCount} />
           <div className='sections w-full'>
            <section name="home" id="home" className='w-full'>
              <div className="pt-24"></div>
              <Home onNavClick={onNavClick} activeLink={activeLink} />
            </section>
            <section name="shop" id='shop' className='w-full text-left'>
              <div className='pt-40'></div>
              <Shop setCartItemCount={setCartItemCount} />
            </section>
            <section name="aboutUs" id="aboutUs" className='w-full text-left'>
              <div className='pt-80 mt-96 sm:pt-32 sm:mt-80 md:mt-80 lg:mt-80'></div>
              <AboutUs onNavClick={onNavClick} activeLink={activeLink} />
              <div className='pb-64 sm:pb-0'></div>
            </section>
            <section name="media" id="media" className='w-full text-left'>
              <div className='mt-96 sm:mt-0 pt-96 sm:pt-80'></div>
              <Media />
            </section>
            <section name="blog" id="blog" className='w-full text-left'>
              <div className='pt-40'></div>
              <Blog />
            </section>
            <section name="testimonials" id="testimonials" className='w-full text-left'>
              <div className='pt-48 mt-60 sm:pt-32 sm:mt-0'></div>
              <Testimonials />
            </section>
            <footer name="footer" className='bg-dark-olive sm:w-full max-[480px]:mt-96'>
              <div className='pt-5'></div>
              <Footer />
            </footer>
          </div>
      </div>
      
      <ToastContainer />
    </>
  );
}

export default App;
