import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaCartArrowDown, FaCircleXmark, FaLink, FaLocationPin, FaMapLocation, FaSquarePhone } from 'react-icons/fa6'
import './styles/Checkout.css'
import { getRegions } from '../../features/location/locationSlice'
import { getDeliveryModes } from '../../features/shop/shopSlice'
import { submitOrder, submitOrderProducts } from '../../features/orders/ordersSlice'
import Swal from 'sweetalert2'

const FormattedText = ({ text }) => {
    return (
        <div dangerouslySetInnerHTML={{__html: text}} />
    )
}

const Checkout = ({ cartItemCount }) => {

    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.auth)
    const { regions } = useSelector((state) => state.locations)
    const { deliveryModes, orderProductIds, paymentModes } = useSelector((state) => state.orders)

    const deliveryMode = JSON.parse(localStorage.getItem('customerDeliveryMode'))

    const [selectedCheckoutTab, setSelectedCheckoutTab] = useState('checkoutTab1')

    const [cartItems, setCartItems] = useState([])
    const [cartTotal, setCartTotal] = useState(0)
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('')
    const [nearestRetailers, setNearestRetailers] = useState([])
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {

        const shoppingCart = JSON.parse(localStorage.getItem('cart'))

        if (shoppingCart && shoppingCart?.items?.length > 0) {
            setCartItems(shoppingCart.items)

            let totalAmount = 0

            shoppingCart.items.forEach((item) => {
                totalAmount += parseFloat(item?.attributes?.UnitPrice) * item.quantity

                setCartTotal(totalAmount)
            })
        }

    }, [cartItemCount])

    const handleCheckoutTabChange = (event) => {
        setSelectedCheckoutTab(event.target.id)
    }

    const handleCheckoutClose = () => {

        document.getElementById('checkout-panel').classList.toggle('hidden')

    }

    const toggleAirtelProcedureModal = () => {
        document.getElementById('airtel-procedure-modal').classList.toggle('hidden')
    }

    const handleRegionChange = (e) => {

        const regionId = e.target.value;
        setSelectedRegion(regionId);

        const region = regions?.data?.find(region => region?.id === parseInt(regionId));
        setFilteredDistricts(region ? region?.attributes?.Districts : []);

        const retailers = region?.attributes?.Retailers
        setNearestRetailers(retailers)
    }

    const handleDistrictChange = (e) => {
        setSelectedDistrict(e.target.value)

        document.getElementById('checkoutRetailersModal').classList.toggle('hidden')
    }

    const toggleCheckoutRetailersModal = (e) => {
        document.getElementById('checkoutRetailersModal').classList.toggle('hidden')
    }

    // Function to generate a random alphanumeric string of given length
    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    
    // Function to generate a unique order number
    function generateUniqueOrderNumber(length) {
        const prefix = 'ORD';
        const randomString = generateRandomString(length - prefix.length);
        const orderNumber = `${prefix}${randomString}`;
        return orderNumber;
    }

    // Function to find region name based on ID
    const findRegionName = (regionId) => {
        const region = regions?.data?.find(region => region?.id === regionId);
        return region ? region?.attributes?.Region : '';
    };

    // Function to find district name based on ID
    const findDistrictName = (districtId) => {
        let districtName = '';
        regions?.data?.forEach(region => {
            const district = region?.attributes?.Districts?.data?.find(district => parseInt(district?.id) === parseInt(districtId));
            if (district) {
                districtName = district?.attributes?.Name;
            }
        });
        return districtName;
    };

    const getDeliveryModeId = (selectedMode) => {
        let modeId = null;
    
        deliveryModes?.data?.forEach(mode => {
            const modeValue = mode?.attributes?.Mode?.toLowerCase();
            if (selectedMode === 'pick_up' && modeValue.includes('pick')) {
                modeId = mode?.id;
            } else if (selectedMode === 'deliver' && modeValue.includes('deliver')) {
                modeId = mode?.id;
            }
        });
    
        return modeId;
    };

    const handleSubmitOrder = async (e) => {
        e.preventDefault()

        setIsSubmitting(true)

        const region = regions?.data?.find(region => region?.id === parseInt(selectedRegion));

        const districtName = findDistrictName(selectedDistrict)

        // Get client's preferred delivery mode from localStorage and filter data to retrieve ID
        const clientPreferredDeliveryMode = JSON.parse(localStorage.getItem('customerDeliveryMode'))

        const deliveryModeId = getDeliveryModeId(clientPreferredDeliveryMode?.deliveryMode)

        dispatch(submitOrderProducts(cartItems))
            .then((orderProductIdsResponse) => {

                const orderData = {
                    OrderNumber: generateUniqueOrderNumber(7),
                    Processed: false,
                    Total: parseInt(cartTotal),
                    Currency: cartItems[0]?.attributes?.Currency?.data?.id,
                    OrderProducts: orderProductIdsResponse?.payload,
                    Customer: user?.user?.id,
                    Comments: `Client Location: ${region?.attributes?.Region}, ${districtName}`,
                    DeliveryMode: deliveryModeId,
                    customerEmail: user?.user?.email
                }

                dispatch(submitOrder(orderData))

                // Reset cart
                const shoppingCartReset = {
                    items: [],
                    quantity: 0
                }

                localStorage.setItem('cart', JSON.stringify(shoppingCartReset))

                Swal.fire({
                    icon: 'success',
                    title: "Order Submitted",
                    text: "Thank you for your order. We shall be in touch with you soon.",
                    timer: 5000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    color: "#99b400",
                }).then(() => {
                    let reload = window.location.reload()
                })
                
            })
            .then((response) => {
                console.log('Order created successfully')
            })
            .catch((error) => {
                console.error('Error during order creation')
            })
    }

    return (
        <>
            <div className="hidden fixed z-50 bg-radiant-gray h-full w-full" id='checkout-panel'>
                <div className="relative flex justify-center text-white sm:justify-start">
                    <div className="container pl-20">
                        <h1 className="text-4xl mt-2 text-dark-olive">
                            Checkout.
                        </h1>
                        <FaCircleXmark className='absolute top-12 right-10 sm:top-10 sm:right-20 md:top-10 md:right-20 lg:top-10 lg:right-20 xl:top-10 xl:right-20 size-10 text-dark-olive cursor-pointer' id='closeCartElement' onClick={handleCheckoutClose} />
                    </div>
                </div>
                
                <div className="flex my-4">
                    <div className="w-full px-5 sm:h-full md:h-full lg:h-full xl:full overflow-y-auto checkout-section" id='checkout-scroll'>
                        <div className="flex flex-wrap items-center rounded-lg px-6 py-5">
                            <div className="w-4/4 sm:w-2/4 md:2/4 lg:2/4 xl:2/4 pl-4 pt-2 pb-6">
                                <h3 className="text-3xl font-semibold text-gray-600">
                                    Payment Options
                                </h3>

                                <section className="checkout-tabs-wrapper">
                                    <div className="checkout-tabs-container">
                                        <div className="checkout-tabs-block">
                                            <div className="checkout-tabs">
                                                <input 
                                                    type="radio" 
                                                    name="checkout-tabs" 
                                                    id="checkoutTab1"  
                                                    checked={selectedCheckoutTab === 'checkoutTab1'} 
                                                    onChange={handleCheckoutTabChange}
                                                />
                                                <label htmlFor="checkoutTab1">
                                                    <h2 className="text-2xl font-normal">
                                                        Mobile Money
                                                    </h2>
                                                </label>
                                                <div className="checkout-tab text-left">
                                                    <div className="mt-4">
                                                        {/* New district input section */}
                                                    <div className="flex justify-center mt-8 mb-8">
                                                        
                                                    </div>
                                                    </div>
                                                    {/* Mobile money details go here */}

                                                    {/* <div className="flex mt-4">
                                                        <div className="w-15">
                                                            <img src={require('../../img/mtn-seeklogo.png')} alt="" className="h-12 rounded-md" />
                                                        </div>
                                                        <div className="flex flex-col justify-between ml-4">
                                                            <p className='text-dark-olive font-semibold'>MTN MOMO</p>
                                                            <p>
                                                                <span className="text-dark-olive font-semibold">
                                                                    Account Name: 
                                                                </span> Radiant Farm Uganda Limited</p>

                                                            <p className='mt-4'>Merchant Code: 896123</p>

                                                            <button type="button" className="py-2 px-2 mt-4 bg-radiant-yellow bg-blue-500 text-dark-olive rounded transition font-medium duration-500" onClick={toggleMTNProcedureModal}>
                                                                Payment Procedures
                                                            </button>

                                                            <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden" id="mtn-procedure-modal">
                                                                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                                    <div className="fixed inset-0 transition-opacity">
                                                                        <div className="absolute inset-0 bg-gray-900 opacity-75">
                                                                            <div className="bg-gray-200 px-4 py-3 text-right">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                                                                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                                                                        <div className="bg-black py-3">
                                                                            <p className="text-2xl text-radiant-yellow font-normal ml-6">
                                                                                Pay with MTN MOMO
                                                                            </p>
                                                                            <button className="py-2 px-4 text-radiant-yellow rounded mr-2 absolute right-0 -mt-9" onClick={toggleMTNProcedureModal}>
                                                                                <FaCircleXmark className='text-radiant-yellow size-6' />
                                                                            </button>
                                                                        </div>
                                                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                                            <div className="text-radiant-yellow text-md">Pay with MTN MOMO</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="z-50">
                                                        <div className="container">
                                                            <h4 className="font-semibold text-dark-olive text-lg">
                                                                Select Region and District
                                                            </h4>
                                                            <p className='text-black'>
                                                                <span className='text-radiant-lime-green font-bold'>Your Delivery Mode:</span> <span className=''>{ deliveryMode?.deliveryMode === 'pick_up' ? 'Pick Up Your Order' : 'Deliver Order to your Location' }</span>
                                                            </p>
                                                            <div className='mt-3'>
                                                                <select name="region" onChange={handleRegionChange} className="bg-white text-black p-2 border rounded w-full">
                                                                    <option value="" className='text-red'>Select Region</option>
                                                                    {
                                                                        regions?.data?.length > 0 ? (
                                                                            regions?.data?.map((region) => {
                                                                                return <option key={region?.id} value={region?.id} style={{ color: 'black' }}>{region?.attributes?.Region}</option>
                                                                            })
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </select>
                                                                <select name="district" id="" className="bg-white text-black p-2 border rounded mt-2 w-full" onChange={handleDistrictChange}>
                                                                    <option value="">Select District</option>
                                                                    {/* Add district options here */}
                                                                    {
                                                                        filteredDistricts?.data?.length > 0 ? (
                                                                            filteredDistricts?.data?.map((district) => {
                                                                                return <option key={district?.id} value={district?.id} style={{ color: 'black' }}>{district?.attributes?.Name}</option>
                                                                            })
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="flex mt-6">
                                                        <div className="w-15">
                                                            <img src={require('../../img/airtel-seeklogo.png')} alt="" className="h-12 rounded-md" />
                                                        </div>
                                                        <div className="flex flex-col justify-between ml-4">
                                                            {
                                                                paymentModes?.data?.length > 0 ? (
                                                                    paymentModes?.data?.map((mode) => {
                                                                        return (
                                                                            mode?.attributes?.Mode?.toLowerCase().includes('airtel') ? (
                                                                                <>
                                                                                    <p className='text-dark-olive font-semibold'>
                                                                                        {
                                                                                            mode?.attributes?.Mode
                                                                                        }
                                                                                    </p>

                                                                                    <p>
                                                                                        <span className="text-dark-olive font-semibold">
                                                                                            Account Name:
                                                                                        </span> { mode?.attributes?.AccountName }
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                            {/* <p className='text-dark-olive font-semibold'>AIRTEL MONEY</p>
                                                            <p>
                                                                <span className="text-dark-olive font-semibold">
                                                                    Account Name:
                                                                </span> Radiant Farm Uganda Limited
                                                            </p> */}

                                                            <button type="button" className="py-2 px-2 mt-4 bg-red-700 bg-blue-500 text-white rounded transition font-medium duration-500" onClick={toggleAirtelProcedureModal}>
                                                                Payment Procedures
                                                            </button>

                                                            <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden" id="airtel-procedure-modal">
                                                                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                                    <div className="fixed inset-0 transition-opacity">
                                                                        <div className="absolute inset-0 bg-gray-900 opacity-75">
                                                                            <div className="bg-gray-200 px-4 py-3 text-right">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                                                                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                                                                        <div className="bg-gray-200 py-3">
                                                                            <p className="text-2xl text-red-700 font-normal ml-6">
                                                                                Pay with Airtel Money
                                                                            </p>
                                                                            <button className="py-2 px-4 text-white rounded mr-2 absolute right-0 -mt-9" onClick={toggleAirtelProcedureModal}>
                                                                                <FaCircleXmark className='text-red-700 size-6' />
                                                                            </button>
                                                                        </div>
                                                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                                        {
                                                                            paymentModes?.data?.length > 0 ? (
                                                                                paymentModes?.data?.map((mode) => {
                                                                                    return (
                                                                                        mode?.attributes?.Mode?.toLowerCase().includes('airtel') ? (
                                                                                            <FormattedText text={mode?.attributes?.Details.split('\n').join('<br />')} />
                                                                                        ) : (
                                                                                            <></>
                                                                                        )
                                                                                    )
                                                                                })
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <input 
                                                    type="radio" 
                                                    name="checkout-tabs" 
                                                    id="checkoutTab2" 
                                                    checked={selectedCheckoutTab === 'checkoutTab2'} 
                                                    onChange={handleCheckoutTabChange}
                                                />
                                                <label htmlFor="checkoutTab2">
                                                    <h2 className="text-2xl font-normal">
                                                        Bank Details
                                                    </h2>
                                                </label>
                                                <div className="checkout-tab text-left">
                                                    
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="bg-dark-olive rounded-lg w-4/4 sm:w-2/4 md:2/4 lg:2/4 xl:2/4 pl-4 pt-2 pb-6 -mt-96">
                                <div className="flex flex-wrap mt-2 mb-2">
                                    <div className="w-3/6 sm:w-4/6 md:w-4/6 lg:w-4/6 xl:w-4/6">
                                        <h4 className="text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl text-radiant-yellow font-semibold">
                                            ORDER SUMMARY
                                        </h4>
                                    </div>
                                    <div className="w-3/6 sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 text-right pr-2">
                                        <h4 className="text-radiant-yellow font-semibold sm:ml-16">
                                            UGX { cartTotal }
                                        </h4>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="w-4/6">
                                        <h4 className="text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl text-white">
                                        <span className="text-radiant-yellow font-semibold">{ cartItemCount }</span> Items
                                        </h4>
                                    </div>
                                    <div className="w-2/6">
                                        <h4 className="text-white ml-16">
                                            {/* { cartItemCount } */}
                                        </h4>
                                    </div>
                                </div>
                                {
                                    cartItems?.map((item) => {

                                        return (
                                            <div className="flex mt-8">
                                                <div className="w-1/6">
                                                    <img src={item?.attributes?.ProductImage?.data?.attributes?.formats?.thumbnail?.url} alt={item?.attributes?.ProductName} className="h-12 rounded-md" />
                                                </div>
                                                <div className="w-3/6">
                                                    {item?.attributes?.ProductName} <span className="text-radiant-yellow font-semibold">x {item?.quantity}</span>
                                                </div>
                                                <div className="w-2/6 pr-2 text-right">
                                                    {item?.attributes?.Currency?.data?.attributes?.Symbol} { item?.attributes?.UnitPrice * item?.quantity }
                                                </div>
                                            </div>
                                        )

                                    })
                                }

                                <div className="mt-6">
                                    <button 
                                        className={`text-2xl text-center bg-radiant-yellow text-dark-olive w-full p-2 -ml-2 rounded-lg ${!selectedDistrict || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`} 
                                        onClick={handleSubmitOrder} 
                                        disabled={!selectedDistrict || isSubmitting}
                                    >
                                        <h3 className="font-semibold">
                                            CONFIRM ORDER
                                        </h3>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Retailers Modal */}
            <div className="fixed z-50 overflow-y-auto top-0 w-full left-0 hidden" id="checkoutRetailersModal">
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#203;</span>
                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full h-[80vh]" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                        <div className="bg-dark-olive py-3">
                            <p className="text-2xl text-radiant-lime-green font-semibold ml-6">Retailers Near You</p>
                            <button className="py-2 px-4 text-black rounded mr-2 absolute right-0 -mt-9" onClick={toggleCheckoutRetailersModal}>
                                <FaCircleXmark className='text-radiant-lime-green size-6' />
                            </button>
                        </div>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-[70vh] overflow-y-auto" id="shop-scroll">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                {
                                    nearestRetailers?.data?.length > 0 ? (
                                        nearestRetailers?.data?.map((retailer) => {
                                            return (
                                                <>
                                                    <div className="block  rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 p-3 cursor-pointer" id="shop-scroll" key={retailer?.id}>
                                                        <p className="text-xl font-bold text-radiant-lime-green">
                                                            { retailer?.attributes?.Name }
                                                        </p>
                                                        <p className="flex flex-column mt-2">
                                                            <FaLocationPin className="mr-2 text-radiant-lime-green" /> {retailer?.attributes?.Address}
                                                        </p>
                                                        <p className="flex flex-column mt-2">
                                                            <FaSquarePhone className="mr-2 text-radiant-lime-green" /> {retailer?.attributes?.PhoneContact}
                                                        </p>
                                                        <p className="flex flex-column mt-2">
                                                            <FaMapLocation className="mr-2 text-radiant-lime-green" /> <a href={retailer?.attributes?.GoogleMapsLocation !== null ? retailer?.attributes?.GoogleMapsLocation : '#!'}>Locate on Google Maps</a>
                                                        </p>
                                                        <div>
                                                            {
                                                                retailer?.attributes?.districts?.data?.map((district) => {
                                                                    return (
                                                                        <div className="mt-3" key={district?.id}>
                                                                            <p><span className="text-radiant-lime-green font-semibold">District:</span> {district?.attributes?.Name}</p>
                                                                            <p><span className="text-radiant-lime-green font-semibold">Region:</span> {district?.attributes?.Region?.data?.attributes?.Region}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout