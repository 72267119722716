import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const SUBSCRIBE_URI = '/newsletter-subscriptions'

// Subscribe to newsletter
const subscribeToNewsletter = async (data) => {

    const response = await axios.post(`${API_URL}${SUBSCRIBE_URI}`, data)

    return response.data
}

const subscriptionService = {
    subscribeToNewsletter
}

export default subscriptionService