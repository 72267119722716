import React, { useEffect, useState } from 'react'
import './styles/NavBar.css'
import { FaBell, FaUser, FaBars, FaCircleXmark, FaAngleDown, FaCartShopping, FaRightFromBracket } from 'react-icons/fa6'
import { Link as ScrollLink, scrollSpy } from 'react-scroll'
import ShoppingCart from '../../ShoppingCart/ShoppingCart'
import Checkout from '../../Checkout/Checkout'
import FloatingActionButton from '../../FloatingActionButton/FloatingActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { login, register, logout } from '../../../features/auth/authSlice'
import Swal from 'sweetalert2'
import { formatDistanceToNow } from 'date-fns'
import { getNotificationsPeriodically } from '../../../features/auth/authSlice'
import { getPrivacyPolicy, getTermsAndConditions } from '../../../features/footer/footerSlice'

const NavBar = ({onNavClick, activeLink, cartItemCount, setCartItemCount}) => {

    // const [cartItemCount, setCartItemCount] = useState(0)

    let { user, notifications, isError, isSuccess } = useSelector((state) => state.auth)

    const [formData, setFormData] = useState({
        names: '',
        email: '',
        phone: '',
        password: '',
        terms: false
    })

    const [loginFormData, setLoginFormData] = useState({
        identifier: '',
        loginPassword: ''
    })

    const { names, email, phone, password, terms } = formData

    const { identifier, loginPassword } = loginFormData

    const dispatch = useDispatch()

    const handleNavLinkClick = () => {
        if (window.innerWidth <= 800) {
            document.getElementById('check').checked = false
        }
    }

    useEffect(() => {
        // Fetch notifications initially when component mounts
        dispatch(getNotificationsPeriodically())

        // Set up interval to fetch notifications every 10 minutes
        const intervalId = setInterval(() => {
            dispatch(getNotificationsPeriodically())
        }, 10 * 60 * 1000) // 10 minutes

        // Clean up interval on component mount
        return () => clearInterval(intervalId)
    }, [dispatch])

    useEffect(() => {
        // Fetch notifications initially when component mounts
        // dispatch(getNotificationsPeriodically())
        scrollSpy.update()

        const shoppingCart = JSON.parse(localStorage.getItem('cart'))

        const itemCount = shoppingCart.quantity

        setCartItemCount(itemCount)

    }, [])

    useEffect(() => {
        const links = document.querySelectorAll('.nav-link')
        links.forEach((link) => {
            link.addEventListener('click', handleNavLinkClick)
        })

        return () => {
            links.forEach((link) => {
                link.removeEventListener('click', handleNavLinkClick)
            })
        }
    }, [])

    const handlePrivacyPolicyClick = async (e) => {

        await dispatch(getPrivacyPolicy())

        document.getElementById('privacy-policy-panel').classList.toggle('hidden')

    }

    const handleTermsAndConditionsClick = async (e) => {

        await dispatch(getTermsAndConditions())

        document.getElementById('terms-conditions-panel').classList.toggle('hidden')

    }

    const toggleLoginModal = (e) => {
        toggleSignUpModal()
        document.getElementById('loginModal').classList.toggle('hidden')
    }

    const createUsername = (fullName) => {
        return fullName.replace(/\s+/g, '').toLowerCase()
    }

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!terms) {
            Swal.fire({
                title: "Terms & Conditions",
                text: "Please agree to our Terms and Conditions before you sign up",
                timer: 5000,
                timerProgressBar: true,
                icon: 'warning',
                showCancelButton: true,
            })

            return
        }

        try {
         
            const username = createUsername(names)
            const confirmed = true

            const userData = {
                names,
                username,
                email,
                phone,
                password,
                confirmed,
                terms
            }

            dispatch(register(userData))

            Swal.fire({
                icon: 'success',
                title: "Sign Up Successful",
                text: "Your account has been registered successfully.",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400",
            }).then(() => {
                let reload = window.location.reload()
            })

        } catch (error) {
            console.error('Error')
        }
        
    }

    const handleLoginChange = (e) => {

        setLoginFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    const handleLogin = (e) => {
        e.preventDefault()

        try {
            
            const userData = {
                identifier,
                password: loginPassword
            }

            dispatch(login(userData))

            if (isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: "Login Successful",
                    text: "Your login was successful",
                    timer: 5000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    color: "#99b400",
                })
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred. Please try again',
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: '#99b400'
            })
        }
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleCartClick = (e) => {

        document.getElementById('cart-panel').classList.toggle('hidden')

    }

    const toggleSignUpModal = (e) => {

        document.getElementById('signUpModal').classList.toggle('hidden')
         
    }

    const getButtonLetter = (message) => {
        if (message.toLowerCase().includes('event')) return 'E'
        if (message.toLowerCase().includes('newsletter')) return 'N'
        if (message.toLowerCase().includes('order') || message.toLowerCase().includes('orders'))  return 'O'
        
        return 'S'
    }

    return (
        <>
            <header>
                <nav className="bg-dark-olive fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 p-1">
                    <ul className="navigation max-w-[90vw] flex flex-wrap justify-between items-center relative mx-auto py-4">
                        <a className="logo" href="#">
                            <img src={require('../../../resources/logos/RF PRIMARY LOGO with slogan copy.png')} className="w-64 h-14" alt="" />
                        </a>
                        <input type="checkbox" id="check" />

                        <span className="menu flex [&>li]:pl-8 [&>li>a]:text-center [&>li>a]:relative [&>li>a]:transition [&>li>a]:duration-200 [&>li>a]:ease-in-out [&>li>a]:font-medium [&>li>a]:text-lg bg-dark-olive">
                            <li className='text-white pr-4'>
                                <ScrollLink 
                                    to="home" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "home" ? "active" : ""}`} 
                                    smooth={true} 
                                    onClick={() => onNavClick("home")} 
                                    id="home-link"
                                >
                                    Home
                                </ScrollLink>
                            </li>
                            <li className='text-white'>
                                <ScrollLink 
                                    to="shop" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "shop" ? "active" : ""}`}  
                                    smooth={true} 
                                    onClick={() => onNavClick("shop")} 
                                    id="shop-link"
                                >
                                    Shop
                                </ScrollLink>
                            </li>
                            <li className='text-white'>
                                <ScrollLink 
                                    to="aboutUs" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "aboutUs" ? "active" : ""}`} 
                                    smooth={true} 
                                    onClick={() => onNavClick("aboutUs")} 
                                    id="aboutUs-link"
                                >
                                    About Us
                                </ScrollLink>
                            </li>
                            <li className='text-white'>
                                <ScrollLink 
                                    to="media" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "media" ? "active" : ""}`} 
                                    smooth={true} 
                                    onClick={() => onNavClick("media")}  
                                    id='media-link'
                                >
                                    Media
                                </ScrollLink>
                            </li>
                            <li className='text-white'>
                                <ScrollLink 
                                    to="blog" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "blog" ? "active" : ""}`} 
                                    smooth={true} 
                                    onClick={() => onNavClick("blog")} 
                                    id='blog-link'
                                >
                                    Blog
                                </ScrollLink>
                            </li>
                            <li className='text-white'>
                                <ScrollLink 
                                    to="footer" 
                                    spy={true} 
                                    className={`nav-link ${activeLink === "footer" ? "active" : ""}`} 
                                    smooth={true} 
                                    onClick={() => onNavClick("footer")} 
                                    id='footer-link'
                                >
                                    Contact Us
                                </ScrollLink>
                            </li>
                            
                            <li>
                                <ul className='flex flex-wrap justify-between items-center py-0.5'>
                                    <li className='notifications-icon px-5 text-white cursor-pointer'>
                                        {
                                            user?.jwt ? (
                                                <>
                                                    <FaBell />
                                                    <div className="dropdown-menu absolute hidden text-gray-700 p-5 md:p-3 bg-white rounded-md top-52 md:top-8 lg:top-8 xl:top-8 right-12 md:right-0.5" style={{width: '24rem'}}>
                                                        <p className="text-md"><button className="bg-red-500 rounded-2xl w-5 h-5"></button> <span className="-mt-2 ml-1 md:-mt-4 font-semibold absolute top-6 left-10">Notifications</span></p> 
                                                        <hr />
                                                        <div className="mt-3 h-72 overflow-y-auto" id='notifications-scroll'>
                                                            <div className="grid grid-cols-1 cursor-pointer">
                                                                {
                                                                    notifications?.length > 0 ? (
                                                                        notifications?.map((notification) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="flex mt-6" key={notification?.id}>
                                                                                        <div className="w-45">
                                                                                            <button className="pl-4 pr-4 pt-2 pb-2 bg-dark-olive rounded-lg text-2xl text-radiant-yellow font-bold">
                                                                                                {
                                                                                                    getButtonLetter(notification?.Message)
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="flex flex-col justify-between ml-4">
                                                                                            <p className="text-dark-olive">
                                                                                                {
                                                                                                    notification?.Message
                                                                                                }
                                                                                            </p>
                                                                                            <small className="text-sm">
                                                                                                {
                                                                                                    formatDistanceToNow(new Date(notification?.publishedAt), {
                                                                                                        addSuffix: true
                                                                                                    })
                                                                                                }
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </li>
                                    <li className='text-radiant-yellow flex avatar cursor-pointer'>
                                        <FaUser className='border border-radiant-yellow p-1 size-7' /><FaAngleDown className='mt-1.5 ml-1' />
                                        <div className="dropdown-menu absolute hidden text-gray-700 p-5 md:p-3 bg-white rounded-md top-52 md:top-10 lg:top-10 xl:top-9 right-12 md:right-0.5" style={{width: '20rem'}}>
                                            <p className="text-md"><button className="bg-radiant-yellow rounded-2xl w-5 h-5"></button> <span className="-mt-2 ml-2 md:-mt-4 font-semibold absolute top-6 left-10">My Account</span></p> 
                                            <hr />
                                            <div className="mt-3">
                                                {
                                                    user?.jwt ? (
                                                        <>
                                                            <h3 className='text-md'>
                                                                <span className='text-radiant-lime-green font-semibold'>Names:</span> &nbsp;
                                                                {
                                                                    user?.user?.names
                                                                }
                                                            </h3>
                                                            <h5>
                                                                <span className='text-radiant-lime-green font-semibold'>Username:</span> &nbsp;
                                                                {
                                                                    user?.user?.username
                                                                }
                                                            </h5>
                                                            <h5>
                                                                <span className='text-radiant-lime-green font-semibold'>Email:</span> &nbsp;
                                                                {
                                                                    user?.user?.email
                                                                }
                                                            </h5>
                                                            <h5>
                                                                <span className='text-radiant-lime-green font-semibold'>Phone:</span> &nbsp;
                                                                {
                                                                    user?.user?.phone
                                                                }
                                                            </h5>
                                                            <button className="rounded-3xl bg-radiant-lime-green hover:bg-gray-400 mt-4 px-4 block w-full text-white justify-center whitespace-no-wrap" onClick={handleLogout}>
                                                                Logout
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className="font-semibold mb-2">Already a Member? Sign in here</p>

                                                            <form onSubmit={handleLogin}>
                                                                <input type="text" name='identifier' className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3" placeholder='Email Address' onChange={handleLoginChange} />

                                                                <input type="password" name='loginPassword' className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3" placeholder='Password' onChange={handleLoginChange} />

                                                                <button type="submit" className="rounded-3xl bg-radiant-lime-green hover:bg-gray-400 py-2 px-4 block w-full text-white justify-center whitespace-no-wrap">
                                                                    <span className="ml-4">Login</span>
                                                                </button>
                                                            </form>

                                                            <p className='font-normal mt-2'>Not a member? <a href="#!" onClick={toggleSignUpModal}><span className="text-radiant-lime-green">Sign Up Here</span></a></p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <label for="check" className="close-menu">
                                <FaCircleXmark className='size-8' />
                            </label>
                        </span>

                        <label for="check" className="open-menu">
                            <FaBars className='text-white size-8' />
                        </label>
                    </ul>
                </nav>
                <div className='fixed top-32 right-12 md:right-24 z-10' id='cartElement'>
                    <FaCartShopping className='text-radiant-lime-green size-10 cursor-pointer' onClick={handleCartClick} />
                    <button className='text-white bg-radiant-lime-green rounded-2xl size-7 fixed top-32 mt-1 right-4 md:right-16'>
                        {cartItemCount}
                    </button>
                </div>
                
            </header>
            <ShoppingCart setCartItemCount={setCartItemCount} cartItemCount={cartItemCount} />
            <FloatingActionButton />
            <Checkout cartItemCount={cartItemCount} />

            {/* SignUp Modal */}
            <div className="fixed z-50 overflow-y-auto  w-full left-0 hidden" id="signUpModal">
                <div className="flex items-center justify-center min-height-100vh pt-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-44">&#203;</span>
                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                        <div className="bg-gray-200">
                            {/* <p className="text-2xl text-radiant-dark-green font-semibold ml-6">Sign Up</p> */}
                            <button className="py-2 px-2 text-black rounded mr-1 absolute right-0 " onClick={toggleSignUpModal}>
                                <FaCircleXmark className='text-white size-6' />
                            </button>
                        {/* </div>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"> */}
                            <div className="w-full flex flex-wrap">

                                {/* Image Section */}
                                <div className="w-1/2 shadow-xl">
                                    <img src={require('../../../img/gardener.jpg')} alt="Backgronud" className="object-cover w-full h-full hidden md:block" />
                                </div>

                                {/* Register Section */}
                                <div className="w-full md:w-1/2 flex flex-col bg-dark-olive">
                                    <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 mt-8 mb-8">
                                        <p className="text-left text-3xl text-radiant-yellow">Welcome.</p>
                                        <p className="text-xl text-white">to our Society</p>
                                        <form action="#!" className="flex flex-col pt-3 md:pt-2" onSubmit={handleSubmit}>
                                            <div className="flex flex-col pt-4">
                                                <label htmlFor="name" className="text-white text-lg">Names</label>
                                                <input type="text" placeholder="John Smith" name='names' id='names' className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" onChange={handleChange} />
                                            </div>

                                            <div className="flex flex-col pt-4">
                                                <label htmlFor="email" className="text-white text-lg">Email</label>
                                                <input type="email" name="email" id="email" placeholder='your@email.com' className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" onChange={handleChange} />
                                            </div>

                                            <div className="flex flex-col pt-4">
                                                <label htmlFor="phone" className="text-white text-lg">Phone</label>
                                                <input type="text" name="phone" id="phone" placeholder='Phone' className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" onChange={handleChange} />
                                            </div>

                                            <div className="flex flex-col pt-4">
                                                <label htmlFor="password" className="text-white text-lg">Password</label>
                                                <input type="password" name="password" id="password" placeholder='Password' className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:sh" onChange={handleChange} />
                                            </div>

                                            <div className='pt-2 text-base text-white'>
                                                <input type="checkbox" name="terms" id="terms" className='bg-radiant-yellow mr-2 -mt-1' onChange={handleChange} /> I agree to the <span className="text-radiant-yellow cursor-pointer" onClick={handleTermsAndConditionsClick}>Terms</span> &amp; <span className="text-radiant-yellow cursor-pointer" onClick={handlePrivacyPolicyClick}>Privacy Policy</span>.
                                            </div>

                                            <button type="submit" className="bg-radiant-yellow text-dark-olive font-bold text-lg rounded-lg hover:bg-gray-700 p-1 mt-4">Sign up</button>

                                            <p className='font-normal text-base mt-3 text-white'>Already signed up? <a href="#!" onClick={toggleLoginModal}><span className="text-radiant-lime-green font-semibold">Login Here</span></a></p>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) 
}

export default NavBar