import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const OFFICE_ADDRESSES_URI = '/office-addresses?populate=*'
const BUSINESS_HOURS_URI = '/operating-hours'
const PRIVACY_POLICY_URI = '/privacy-policy'
const COOKIE_POLICY_URI = '/cookie-policy'
const TERMS_AND_CONDITIONS_URI = '/terms-and-condition'
const SOCIAL_MEDIA_HANDLES_URI = '/social-media-handles'

const getOfficeAddresses = async () => {

    const response = await axios.get(`${API_URL}${OFFICE_ADDRESSES_URI}`)

    return response.data
}

const getBusinessHours = async () => {

    const response = await axios.get(`${API_URL}${BUSINESS_HOURS_URI}`)

    return response.data
}

const getPrivacyPolicy = async () => {

    const response = await axios.get(`${API_URL}${PRIVACY_POLICY_URI}`)

    return response.data
}

const getCookiePolicy = async () => {

    const response = await axios.get(`${API_URL}${COOKIE_POLICY_URI}`)

    return response.data
}

const getTermsAndConditions = async () => {

    const response = await axios.get(`${API_URL}${TERMS_AND_CONDITIONS_URI}`)

    return response.data
}

const getSocialMediaHandles = async () => {

    const response = await axios.get(`${API_URL}${SOCIAL_MEDIA_HANDLES_URI}`)

    return response.data
}

const footerService = {
    getOfficeAddresses,
    getBusinessHours,
    getPrivacyPolicy,
    getCookiePolicy,
    getTermsAndConditions,
    getSocialMediaHandles
}

export default footerService