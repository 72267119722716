import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import { FaCircleXmark, FaXmark, FaPlus, FaMinus, FaShareNodes } from 'react-icons/fa6'
import { getRecentAgribusinessArticles, getBlogAuthor } from '../../../../features/blog/blogSlice'
import Swal from 'sweetalert2'
import ShareFloatingActionButton from '../../../../components/ShareFloatingActionButton/ShareFloatingActionButton'

const AgribusinessArticle = () => {

    let { recentAgribusinessArticles, blogAuthor, blogArticle, isLoadingBlog, isSuccessBlog, isErrorBlog, message } = useSelector((state) => state.blog)

    const agribusinessArticle = JSON.parse(localStorage.getItem('agribusinessArticle'))

    const handleAgribusinessArticleClose = () => {
        document.getElementById('agribusiness-article-panel').classList.toggle('hidden')
        window.history.replaceState(null, '', window.location.pathname) // Clear the URL parameters
    }

    const formatDate = (dateString) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    }

    const handleShareArticle = (article) => {

        const articleId = article?.data?.attributes?.Slug
        const shareableLink = `${window.location.origin}?article=${articleId}`

        // Copy the shareable link to the clipboard
        navigator.clipboard.writeText(shareableLink).then(() => {
            Swal.fire({
                icon: 'success',
                title: "Link Copied",
                text: "Shareable link shared to clipboard. You can now share it far and wide :)",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400",
            })
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: "Link Copy Failed",
                text: "Failed to copy the shareable link :(. Please try again",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400",
            })
        })

    }

    return (
        <>
            <div className="hidden fixed top-0 right-0 z-50 bg-gray-200 w-full h-full overflow-y-auto blog-resource-scroll" id='agribusiness-article-panel'>
                <div className="flex">
                    <div className="flex flex-wrap md:w-1/6"></div>
                    <div className="flex flex-wrap md:w-4/6">

                        <div className="relative flex justify-center text-white sm:justify-start">
                            <div className="container md:pl-20 pl-5 pr-5 mt-4">
                                <h1 className="text-5xl text-dark-olive">
                                    Blog.
                                </h1>

                                <h2 className="text-2xl text-dark-olive underline mt-4 mb-8">Agribusiness</h2>

                                <h1 className="md:text-6xl text-4xl font-semibold mt-2 text-dark-olive">
                                    {
                                        blogArticle?.data?.attributes?.Title
                                    }
                                </h1>
                                
                                <FaCircleXmark className='fixed top-10 md:right-20 right-10 size-10 text-dark-olive cursor-pointer text-radiant-lime-green' id='closeAgribusinessArticle' onClick={handleAgribusinessArticleClose}/>

                                {/* <FaShareNodes className='fixed top-24 md:right-20 right-10 size-10 text-dark-olive cursor-pointer text-radiant-dark-olive' onClick={() => handleShareArticle(blogArticle)} /> */}
                                <ShareFloatingActionButton article={blogArticle} />
                            </div>
                        </div>
                        <div className="container mt-4">
                            <div className="flex my-4 md:pl-20 pl-5 pr-5">
                                <div className="w-full bg-transparent ">
                                    {/* Article */}
                                    <div className="block h-80 w-full md:w-5/5 flex md:pt-0 bg-cover bg-right" style={{backgroundImage: `url('${blogArticle?.data?.attributes?.CoverImage?.data?.attributes?.url}')`}}>
                                    </div>
                                    <div className="flex flex-column gap-10">
                                        <div className="md:w-5/6">
                                            <div className="mt-8 pb-8 text-dark-olive">
                                                <div className="sm:hidden mg:hidden lg:hidden xl:hidden mt-8 mb-8 text-center items-center w-2/3 px-auto mx-auto">
                                                    <p className="text-xl font-light">About this Writer</p>

                                                    <img 
                                                        src={`${blogAuthor?.data?.attributes?.AuthorPicture?.data?.attributes?.formats?.thumbnail?.url}`} 
                                                        className='h-24 w-24 rounded-full mt-4 mx-auto' 
                                                        alt="" 
                                                    />

                                                    <p className='mt-3'>
                                                        <span className='font-semibold'>
                                                            {
                                                                blogAuthor?.data?.attributes?.Name
                                                            }
                                                        </span> {
                                                            blogAuthor?.data?.attributes?.Bio
                                                        }
                                                    </p>
                                                </div>

                                                <h3 className="md:text-3xl text-2xl font-semibold text-dark-olive">
                                                    {
                                                        blogArticle?.data?.attributes?.SubTitle
                                                    }
                                                </h3>
                                                <p className="text-sm italic mt-4">
                                                {
                                                        formatDate(blogArticle?.data?.attributes?.publishedAt)
                                                    }
                                                </p>
                                                <p className="text-sm italic">
                                                    {
                                                        blogArticle?.data?.attributes?.AuthorLocation
                                                    }
                                                </p>

                                                <div className="mt-6">
                                                    <ReactMarkdown>
                                                        {
                                                            blogArticle?.data?.attributes?.BodyText
                                                        }
                                                    </ReactMarkdown>
                                                </div>

                                                {
                                                    recentAgribusinessArticles?.data?.length > 0 ? (
                                                        <>
                                                           <h3 className="text-xl mt-16 font-semibold">Read more...</h3>
                                                        </>
                                                    ) : (<></>)
                                                }

                                                <div className="grid md:grid-cols-2 cursor-pointer">
                                                    {
                                                        recentAgribusinessArticles?.data?.length > 0 ? (
                                                            recentAgribusinessArticles?.data?.map((article) => {
                                                                return (
                                                                    article?.id !== blogArticle?.data?.id ? (
                                                                        <div className="flex mt-6">
                                                                            <div className="w-45">
                                                                                <img src={`${article?.attributes?.CoverImage?.data?.attributes?.formats?.medium?.url}`} alt="" className="w-22 h-12 rounded-md" />
                                                                            </div>
                                                                            <div className="flex flex-col justify-between ml-4">
                                                                                <p className="text-dark-olive font-semibold">
                                                                                    {
                                                                                        article?.attributes?.Title
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                )
                                                            })
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="md:w-1/6 max-[480px]:hidden">
                                            <div className="mt-8 text-center items-center px-auto">
                                                <p className="text-3xl font-light">About this <br />Writer</p>

                                                <img src={`${blogAuthor?.data?.attributes?.AuthorPicture?.data?.attributes?.formats?.thumbnail?.url}`} className='h-38 w-40 rounded-full mt-4 mx-auto' alt="" />

                                                <p className='mt-3'>
                                                    <span className='font-semibold'>
                                                        {
                                                            blogAuthor?.data?.attributes?.Name
                                                        }    
                                                    </span> {
                                                        blogAuthor?.data?.attributes?.Bio
                                                    }
                                                </p>

                                                <div className="mt-14 bg-green-500">
                                                    <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD3_kiuwdo.gif" alt="" />
                                                </div>

                                                <div className="mt-64 bg-green-500">
                                                    <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD3_kiuwdo.gif" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-wrap md:w-1/6"></div>
                </div>
            </div>
        </>
    )
}

export default AgribusinessArticle