import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/auth/authSlice'
import navigationSlice from '../features/navigation/navigationSlice'
import aboutUsSlice from '../features/aboutUsPageContent/aboutUsPageSlice'
import blogSlice from '../features/blog/blogSlice'
import homePageSlice from '../features/homePageContent/homePageSlice'
import mediaSlice from '../features/media/mediaSlice'
import footerSlice from '../features/footer/footerSlice'
import ordersSlice from '../features/orders/ordersSlice'
import retailersSlice from '../features/retailers/retailersSlice'
import shopSlice from '../features/shop/shopSlice'
import subscriptionSlice from '../features/subscription/subscriptionSlice'
import testimonialsSlice from '../features/testimonials/testimonialsSlice'
import newsletterSlice from '../features/newsletters/newsletterSlice'
import locationSlice  from '../features/location/locationSlice'

export const store = configureStore({
    reducer: {
        auth: authSlice,
        navigation: navigationSlice,
        aboutUs: aboutUsSlice,
        blog: blogSlice,
        homePage: homePageSlice,
        media: mediaSlice,
        orders: ordersSlice,
        retailers: retailersSlice,
        locations: locationSlice,
        shop: shopSlice,
        subscriptions: subscriptionSlice,
        testimonials: testimonialsSlice,
        newsletter: newsletterSlice,
        footer: footerSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
})