import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import subscriptionService from './subscriptionService'

const initialState = {
    subscriptionCompleted: false,
    isErrorSubscribers: false,
    isSuccessSubscribers: false,
    isLoadingSubscribers: false,
    message: ''
}

// Subscribe to newsletter
export const subscribeToNewsletter = createAsyncThunk('subscription/newsletter', async (data, thunkAPI) => {
    try {
        
        return await subscriptionService.subscribeToNewsletter(data)

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        reset: (state) => {
            state.subscriptionCompleted = false
            state.isLoadingSubscribers = false
            state.isErrorSubscribers = false
            state.isSuccessSubscribers = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscribeToNewsletter.pending, (state) => {
                state.isLoadingSubscribers = true
            })
            .addCase(subscribeToNewsletter.fulfilled, (state, action) => {
                state.isLoadingSubscribers = false
                state.isSuccessSubscribers = true
                state.subscriptionCompleted = true
            })
            .addCase(subscribeToNewsletter.rejected, (state, action) => {
                state.isLoadingSubscribers = false
                state.isErrorSubscribers = true
                state.message = action.payload
                state.subscriptionCompleted = false
            })
    }
})

export const { reset } = subscriptionSlice.actions
export default subscriptionSlice.reducer