import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsletterService from './newsletterService'

const initialState = {
    signedUp: false,
    isSuccessNewsletter: false,
    isErrorNewsletter: false,
    isLoadingNewsletter: false,
    message: ''
}

// Sign Up for Newsletter
export const signUpForNewsletter = createAsyncThunk('newsletters/signUp', async (data, thunkAPI) => {
    try {
        
        return await newsletterService.signUpForNewsletter(data)

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const newsletterSlice = createSlice({
    name: 'newsletter',
    initialState,
    reducers: {
        reset: (state) => {
            state.signedUp = false
            state.isLoadingNewsletter = false
            state.isErrorNewsletter = false
            state.isSuccessNewsletter = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUpForNewsletter.pending, (state) => {
                state.isLoadingNewsletter = true
            })
            .addCase(signUpForNewsletter.fulfilled, (state, action) => {
                state.isLoadingNewsletter = false
                state.isSuccessNewsletter = true
                state.signedUp = true
            })
            .addCase(signUpForNewsletter.rejected, (state, action) => {
                state.isLoadingNewsletter = false
                state.isErrorNewsletter = true
                state.message = action.payload
                state.signedUp = false
            })
    }
})

export const { reset } = newsletterSlice.actions
export default newsletterSlice.reducer