import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import homePageService from './homePageService'

const initialState = {
    carouselContent: [],
    isErrorHome: false,
    isSuccessHome: false,
    isLoadingHome: false,
    message: ''
}

// Get all carousel content
export const getAllCarouselContent = createAsyncThunk('homePage/allCarouselContent', async (_, thunkAPI) => {
    try {
        return await homePageService.getAllCarouselContent()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const homePageSlice = createSlice({
    name: 'homePage',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingHome = false
            state.isSuccessHome = false
            state.isErrorHome = false
            state.message = ''
            state.carouselContent = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCarouselContent.pending, (state) => {
                state.isLoadingHome = true
            })
            .addCase(getAllCarouselContent.fulfilled, (state, action) => {
                state.isLoadingHome = false
                state.isSuccessHome = true
                state.carouselContent = action.payload
            })
            .addCase(getAllCarouselContent.rejected, (state, action) => {
                state.isLoadingHome = false
                state.isErrorHome = true
                state.message = action.payload
                state.carouselContent = []
            })
    }
})

export const { reset } = homePageSlice.actions
export default homePageSlice.reducer