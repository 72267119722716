import React from 'react'
import { FaWhatsapp, FaForward, FaMessage } from 'react-icons/fa6'
import './styles/FloatingActionButton.css'

const FloatingActionButton = () => {

    const handleFeedbackButtonClick = () => {

        const NODE_ENV = process.env.REACT_APP_NODE_ENV
        const DEV_WHATSAPP_LINE = process.env.REACT_APP_DEV_WHATSAPP_SUPPORT_LINE
        const PROD_WHATSAPP_LINE = process.env.REACT_APP_PROD_WHATSAPP_SUPPORT_LINE

        const phoneNumber = NODE_ENV === 'development' ? DEV_WHATSAPP_LINE : PROD_WHATSAPP_LINE

        const isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)

        // Create Whatsapp Link
        const whatsappLink = isMobile ? `whatsapp://send?phone=${phoneNumber}` : `https://web.whatsapp.com/send?phone=${phoneNumber}`
        window.open(whatsappLink, '_blank')

    }

    return (
        <>
            <div className="fab-container fixed bottom-32 right-12 md:right-24 z-10">
                <div className="fab fab-icon-holder pt-3">
                    <FaWhatsapp className='size-8 mx-auto text-white' />
                </div>

                <ul className="fab-options">
                    <li>
                        <span className="fab-label">Feedback</span>
                        <a href="#!" id="feedbackBtn">
                            <div className="fab-icon-holder pt-3">
                                <FaMessage className='size-6 mx-auto text-white' onClick={handleFeedbackButtonClick} />
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default FloatingActionButton