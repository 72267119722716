import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FaCircleXmark, FaLink, FaLocationDot, FaLocationPin, FaMapLocation, FaSquarePhone } from 'react-icons/fa6'
import './styles/Shop.css'
import { FaCartPlus, FaRegRegistered, FaRegistered, FaTrademark } from "react-icons/fa6"
import { getRetailers } from '../../features/retailers/retailersSlice'
import { getProducts, getShopCategories } from "../../features/shop/shopSlice"
import { getPaymentModes } from "../../features/orders/ordersSlice"

const Shop = ({ setCartItemCount }) => {

    const dispatch = useDispatch()

    const [selectedShopTab, setSelectedShopTab] = useState('shopTab1')

    let { retailers, isLoadingRetailers, isErrorRetailers } = useSelector((state) => state.retailers)
    let { shopCategories, products, isLoadingShop, isErrorShop } = useSelector((state) => state.shop)

    useEffect(() => {
        dispatch(getRetailers())
        dispatch(getShopCategories())
        dispatch(getProducts())
        dispatch(getPaymentModes())
    }, [])

    const handleShopTabChange = (event) => {
        setSelectedShopTab(event.target.id)
    }

    const handleAddToCartClick = (e, product) => {

        const checkLocalStorage = localStorage.getItem('cart')

        let shoppingCart = checkLocalStorage ? JSON.parse(localStorage.getItem('cart')) : { items: [], quantity: 0 }

        let cartItems = shoppingCart.items
        let productExists = false

        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].id === product.id) {
                cartItems[i].quantity += 1
                productExists = true
                break
            }
        }

        // If the product doesn't exist in the cart, add it
        if (!productExists) {
            
            let productToAdd = { ...product, quantity: 1 }
            cartItems.push(productToAdd)
        }

        // Update the overall quantity of items in the cart
        shoppingCart.quantity = cartItems.reduce((total, item) => total + item.quantity, 0)

        setCartItemCount(shoppingCart.quantity)

        // Save the updated cart to localStorage
        localStorage.setItem('cart', JSON.stringify(shoppingCart))

    }

    const toggleRetailersModal = (e) => {
        document.getElementById('retailersModal').classList.toggle('hidden')
    }

    return (
        <>
            <div className="relative flex justify-center text-white sm:justify-start">
                <div className="container pl-20">
                    <h1 className="text-5xl text-dark-olive">
                        Shop.
                    </h1>
                    <button className="bg-dark-olive rounded-md mt-4 pl-4 pr-4 pt-2 pb-2 text-radiant-yellow" onClick={toggleRetailersModal}>
                        Our Retailers
                    </button>
                </div>
            </div>
            <div className="container">
                <section className="shop-tabs-wrapper">
                    <div className="shop-tabs-container">
                        <div className="shop-tabs-block">
                            <div className="shop-tabs">
                                <input 
                                    type="radio" 
                                    name="shop-tabs" 
                                    id="shopTab1" 
                                    checked={selectedShopTab === 'shopTab1'} 
                                    onChange={handleShopTabChange}
                                />
                                <label htmlFor="shopTab1">
                                    <h2 className="text-2xl font-normal">
                                        All
                                    </h2>
                                </label>
                                <div className="shop-tab text-left">
                                    <section className="overflow-x-hidden overflow-auto h-140" id="shop-scroll">
                                        {/* <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full mt-6">
                                            {
                                                products?.data?.length > 0 ? (
                                                    products?.data?.map((product, index) => {
                                                        const isAdRow = Math.floor(index / 3) === 0 || Math.floor(index / 3) === 2; // First and third rows
                                                        const isAdPosition = (index + 1) % 3 === 0; // Every third product
                                                        return (
                                                            <>
                                                            <div className="">
                                                                
                                                                <div>
                                                                    <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                        <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                    </a>
                                                                    <div className="flex items-center justify-between mt-3">
                                                                        <div>
                                                                            <a href="#!" className="font-medium">{ product?.attributes?.ProductName }</a>
                                                                            <a href="#!" className="flex items-center">
                                                                                <span className="text-sm font-medium text-dark-olive">
                                                                                    { product?.attributes?.Currency?.data?.attributes?.Symbol } { product?.attributes?.UnitPrice }/ { product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit }
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                        <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                            <FaCartPlus onClick={(e) => handleAddToCartClick(e, product)} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(index + 1) % 2 === 2 && (
                        <div className="">
                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716548112/Radiant%20Farm/Website%20ADs/RF_WEB_AD_2_copy_ysaow1.png" alt="Ad" className="w-full h-auto" />
                        </div>
                    )}
                                                            </>
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </div> */}
                                        <div className="flex flex-wrap w-full mt-6">
                                            {/* Column One: Product Grid */}
                                            <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12">
                                                {products?.data?.length > 0 ? (
                                                products?.data?.map((product) => (
                                                    <div key={product.id}>
                                                        <div>
                                                            <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                            </a>
                                                            <div className="flex items-center justify-between mt-3">
                                                                <div>
                                                                    <a href="#!" className="font-medium">{product?.attributes?.ProductName}</a>
                                                                    <a href="#!" className="flex items-center">
                                                                    <span className="text-sm font-medium text-dark-olive">
                                                                        {product?.attributes?.Currency?.data?.attributes?.Symbol} {product?.attributes?.UnitPrice} / {product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit}
                                                                    </span>
                                                                    </a>
                                                                </div>
                                                                <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                    <FaCartPlus onClick={(e) => handleAddToCartClick(e, product)} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>

                                            {/* Column Two: Ad */}
                                            <div className="md:w-1/4 pl-6 pr-0 md:pr-3 pt-0">
                                                <div className="hidden md:block">
                                                    <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570300/Radiant%20Farm/Website%20ADs/GIFs/AD2_onrxb1.gif" alt="Ad" className="w-full h-auto pt-0" />
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    
                                </div>

                                <input 
                                    type="radio" 
                                    name="shop-tabs" 
                                    id="shopTab2" 
                                    checked={selectedShopTab === 'shopTab2'} 
                                    onChange={handleShopTabChange}
                                />
                                <label htmlFor="shopTab2">
                                    <h2 className="text-2xl font-bold flex">
                                        Radigro <FaTrademark className="size-3" /> <span className="ml-2 font-bold">Feeds</span>
                                    </h2>
                                </label>
                                <div className="shop-tab text-left">
                                    {/* Radigro Catalogue */}

                                    <section className="overflow-x-hidden overflow-auto h-140" id="shop-scroll">
                                        <div className="flex flex-wrap w-full mt-6">
                                            {/* Column One: Product Grid */}
                                            <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 gap-x-6 gap-y-12">
                                            {
                                                products?.data?.length > 0 ? (
                                                    products?.data?.map((product) => {
                                                        return (
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category.toLowerCase() === 'radigro feeds' || 
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category.toLowerCase() === 'radigro™ feeds' ? (
                                                                <div>
                                                                    <div>
                                                                        <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                            <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                        </a>
                                                                        <div className="flex items-center justify-between mt-3">
                                                                            <div>
                                                                                <a href="#!" className="font-medium">
                                                                                    { product?.attributes?.ProductName }
                                                                                </a>
                                                                                <a href="#!" className="flex items-center">
                                                                                    <span className="text-sm font-medium text-dark-olive">
                                                                                        { product?.attributes?.Currency?.data?.attributes?.Symbol } { product?.attributes?.UnitPrice }/ { product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit }
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                                <FaCartPlus onClick={(e) => handleAddToCartClick(e, product?.attributes)} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            </div>

                                            {/* Column Two: Ad */}
                                            <div className="md:w-1/4 pl-6 pr-3 pt-0">
                                                <div className="hidden md:block">
                                                    <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716548112/Radiant%20Farm/Website%20ADs/RF_WEB_AD_2_copy_ysaow1.png" alt="Ad" className="w-full h-auto pt-0" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full mt-6">
                                            {
                                                products?.data?.length > 0 ? (
                                                    products?.data?.map((product) => {
                                                        return (
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category.toLowerCase() === 'radigro feeds' || 
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category.toLowerCase() === 'radigro™ feeds' ? (
                                                                <div>
                                                                    <div>
                                                                        <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                            <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                        </a>
                                                                        <div className="flex items-center justify-between mt-3">
                                                                            <div>
                                                                                <a href="#!" className="font-medium">
                                                                                    { product?.attributes?.ProductName }
                                                                                </a>
                                                                                <a href="#!" className="flex items-center">
                                                                                    <span className="text-sm font-medium text-dark-olive">
                                                                                        { product?.attributes?.Currency?.data?.attributes?.Symbol } { product?.attributes?.UnitPrice }/ { product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit }
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                                <FaCartPlus onClick={(e) => handleAddToCartClick(e, product?.attributes)} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </div> */}
                                    </section>
                                </div>

                                <input 
                                    type="radio" 
                                    name="shop-tabs" 
                                    id="shopTab3" 
                                    checked={selectedShopTab === 'shopTab3'} 
                                    onChange={handleShopTabChange}
                                />
                                <label htmlFor="shopTab3">
                                    <h2 className="text-2xl font-normal">Branded Merchandise</h2>
                                </label>
                                <div className="shop-tab text-left">
                                    {/* Merchandise Catalogue */}

                                    <section className="overflow-x-hidden overflow-auto h-140" id="shop-scroll">
                                        <div className="flex flex-wrap w-full mt-6">
                                            {/* Column One: Product Grid */}
                                            <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 gap-x-6 gap-y-12">
                                            {
                                                products?.data?.length > 0 ? (
                                                    products?.data?.map((product) => {
                                                        return (
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category?.toLowerCase() === 'branded merchandise' ? (
                                                                <div>
                                                                    <div>
                                                                        <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                            <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                        </a>
                                                                        <div className="flex items-center justify-between mt-3">
                                                                            <div>
                                                                                <a href="#!" className="font-medium">
                                                                                    { product?.attributes?.ProductName }
                                                                                </a>
                                                                                <a href="#!" className="flex items-center">
                                                                                    <span className="text-sm font-medium text-dark-olive">
                                                                                        { product?.attributes?.Currency?.data?.attributes?.Symbol } { product?.attributes?.UnitPrice }/ { product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit }
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                                <FaCartPlus onClick={(e) => handleAddToCartClick(e, product?.attributes)} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            </div>

                                            {/* Column Two: Ad */}
                                            <div className="md:w-1/4 pl-6 pr-3 pt-0">
                                                <div className="hidden md:block">
                                                    <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716548112/Radiant%20Farm/Website%20ADs/RF_WEB_AD_2_copy_ysaow1.png" alt="Ad" className="w-full h-auto pt-0" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full mt-6">
                                            {
                                                products?.data?.length > 0 ? (
                                                    products?.data?.map((product) => {
                                                        return (
                                                            product?.attributes?.ShopCategory?.data?.attributes?.Category?.toLowerCase() === 'branded merchandise' ? (
                                                                <div>
                                                                    <div>
                                                                        <a href="#!" className="block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                            <img src={`${product?.attributes?.ProductImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                        </a>
                                                                        <div className="flex items-center justify-between mt-3">
                                                                            <div>
                                                                                <a href="#!" className="font-medium">
                                                                                    { product?.attributes?.ProductName }
                                                                                </a>
                                                                                <a href="#!" className="flex items-center">
                                                                                    <span className="text-sm font-medium text-dark-olive">
                                                                                        { product?.attributes?.Currency?.data?.attributes?.Symbol } { product?.attributes?.UnitPrice }/ { product?.attributes?.UnitsOfMeasure?.data?.attributes?.Unit }
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer duration-500 hover:scale-150">
                                                                                <FaCartPlus onClick={(e) => handleAddToCartClick(e, product?.attributes)} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </div> */}
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* Retailers Modal */}
            <div className="fixed z-50 overflow-y-auto top-0 w-full left-0 hidden" id="retailersModal">
                <div className="flex items-center justify-center min-h-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#203;</span>
                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full md:w-3/4 lg:w-3/3 xl:w-3/3 h-[80vh] md:h-[90vh]" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                        <div className="bg-dark-olive py-3">
                            <p className="text-2xl text-radiant-lime-green font-semibold ml-6">Our Retailers</p>
                            <button className="py-2 px-4 text-black rounded mr-2 absolute right-0 -mt-9" onClick={toggleRetailersModal}>
                                <FaCircleXmark className='text-radiant-lime-green size-6' />
                            </button>
                        </div>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-[70vh] overflow-y-auto" id="shop-scroll">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                {
                                    retailers?.data?.length > 0 ? (
                                        retailers?.data?.map((retailer) => {
                                            return (
                                                <>
                                                    <div className="block  rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 cursor-pointer" id="shop-scroll" key={retailer?.id}>
                                                        <p className="text-md font-bold text-radiant-yellow bg-dark-olive pl-2 pr-2 pt-3 pb-3 rounded-t-md">
                                                            { retailer?.attributes?.Name }
                                                        </p>
                                                        <div className="p-2">
                                                            <p className="flex flex-column mt-2">
                                                                <FaLocationDot className="mr-2 text-xl text-dark-olive" /> {retailer?.attributes?.Address}
                                                            </p>
                                                            <p className="flex flex-column mt-2">
                                                                <FaSquarePhone className="mr-2 text-dark-olive" /> {retailer?.attributes?.PhoneContact}
                                                            </p>
                                                            <p className="flex flex-column mt-2">
                                                                <FaMapLocation className="mr-2 text-dark-olive" /> <a href={retailer?.attributes?.GoogleMapsLocation !== null ? retailer?.attributes?.GoogleMapsLocation : '#!'}>Locate on Google Maps</a>
                                                            </p>
                                                            <div className="mt-3">
                                                                <p><span className="text-radiant-lime-green font-semibold">District:</span> {retailer?.attributes?.DistrictLocation}</p>
                                                                <p><span className="text-radiant-lime-green font-semibold">Region:</span> {retailer?.attributes?.RegionalLocation}</p>
                                                            </div>

                                                            <div>
                                                                <p className="text-xl text-radiant-lime-green font-semibold underline mt-3">Catalogue</p>
                                                                {
                                                                    retailer?.attributes?.retailer_catalogues?.data?.map((catalogue) => {
                                                                        return (
                                                                            <>
                                                                                <div key={catalogue?.id} className="mt-2">
                                                                                    {
                                                                                        catalogue?.attributes?.products?.data?.map((product) => {
                                                                                            return <p className="font-semibold">{product?.attributes?.ProductName}</p>
                                                                                        })
                                                                                    }
                                                                                    <p><span className="text-radiant-lime-green font-semibold">Price:</span> {catalogue?.attributes?.currencies?.data[0].attributes?.Symbol} {catalogue?.attributes?.Price.toLocaleString()}</p>
                                                                                    <p><span className="text-radiant-lime-green font-semibold">Qty:</span> {catalogue?.attributes?.unit_of_measure?.data?.attributes?.Unit}</p>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shop