import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector, useDispatch } from 'react-redux'
import './styles/Blog.css'
import { FaCircleXmark } from 'react-icons/fa6'
import ResourceArticle from './ArticlePages/ResourcesArticles/ResourceArticle'
import AgribusinessArticle from './ArticlePages/AgribusinessArticles/AgribusinessArticle'
import EventsArticle from './ArticlePages/EventsArticles/EventsArticle'
import NewsletterArticle from './ArticlePages/NewsletterArticles/NewsletterArticle'
import { getBlogArticle, getBlogArticles, getBlogCategories, getBlogAuthor, getEvents, getRecentResourcesArticles, getRecentAgribusinessArticles, getRecentNewsletterArticles, getRecentEvents, getUpcomingEvents } from '../../features/blog/blogSlice'
import { signUpForNewsletter } from '../../features/newsletters/newsletterSlice'
import Swal from 'sweetalert2'

const Blog = () => {

    const dispatch = useDispatch()

    const [selectedBlogTab, setSelectedBlogTab] = useState('blogTab1')
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredArticles, setFilteredArticles] = useState([])
    const [subscriberEmail, setSubscriberEmail] = useState('')

    let { blogCategories, blogArticles, events, blogArticle, blogAuthor, recentResourcesArticles, recentAgribusinessArticles, recentNewsletterArticles, recentEvents, upcomingEvents, isLoadingBlog, isSuccessBlog, isErrorBlog, message } = useSelector((state) => state.blog)
    let { signedUp, isSuccessNewsletter } = useSelector((state) => state.newsletter)
    let counter = 1

    useEffect(() => {

        dispatch(getBlogCategories())

        dispatch(getBlogArticles())
        dispatch(getEvents())

        dispatch(getRecentResourcesArticles())   

        dispatch(getRecentAgribusinessArticles())   

        dispatch(getRecentNewsletterArticles())   

        dispatch(getRecentEvents())
        dispatch(getUpcomingEvents())

    }, [])

    useEffect(() => {

        // Initialise filteredArticles with the full list of articles
        setFilteredArticles(blogArticles?.data)

    }, [blogArticles])

    useEffect(() => {

        // Update filteredArticles based on the search term
        if (searchTerm === '') {
            setFilteredArticles(blogArticles?.data)
        } else {
            const filtered = blogArticles?.data?.filter(article => article?.attributes?.Title?.toLowerCase().includes(searchTerm.toLowerCase()))
            setFilteredArticles(filtered)
        }

    }, [searchTerm, blogArticles])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleBlogTabChange = (event) => {
        setSelectedBlogTab(event.target.id)
    }

    const toggleSubscribeModal = (e) => {
        document.getElementById('subscribeModal').classList.toggle("hidden")
    }

    const handleResourceArticleClick = async (e, article) => {

        const authorId = article?.attributes?.BlogAuthor?.data?.id

        await dispatch(getBlogArticle(article?.id))
        await dispatch(getBlogAuthor(authorId))

        document.getElementById('resource-article-panel').classList.toggle('hidden')

    }

    const handleAgribusinessArticleClick = async (e, article) => {

        const authorId = article?.attributes?.BlogAuthor?.data?.id

        await dispatch(getBlogArticle(article?.id))
        await dispatch(getBlogAuthor(authorId))

        document.getElementById('agribusiness-article-panel').classList.toggle('hidden')

    }

    const handleEventsArticleClick = () => {

        document.getElementById('events-article-panel').classList.toggle('hidden')

    }

    const handleNewslettersArticleClick = async (e, article) => {

        const authorId = article?.attributes?.BlogAuthor?.data?.id

        await dispatch(getBlogArticle(article?.id))
        await dispatch(getBlogAuthor(authorId))

        document.getElementById('newsletter-article-panel').classList.toggle('hidden')

    }

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }
        const date = new Date(dateString)

        if (isNaN(date)) {
            throw new RangeError('Invalid time value')
        }

        // return new Intl.DateTimeFormat('en-US', options).format(date)
        return date.toLocaleDateString(undefined, options)
    }

    const createArticlePreview = (text, wordLimit) => {

        const words = text.split(' ')

        // Check if the text has more words than the limit
        const isTruncated = words.length > wordLimit

        // Get the first `wordLimit` words and join them back into a string
        const preview = words.slice(0, wordLimit).join(' ')

        // Add ellipsis if the text was truncated
        return isTruncated ? `${preview}...` : preview

    }

    const handleSubscriberEmailChange = (e) => {
        setSubscriberEmail(e.target.value)
    }

    const handleNewsletterSubscriptionSubmit = (e) => {
        e.preventDefault()

        if (!subscriberEmail) {
            Swal.fire({
                title: "Missing Email",
                text: "Please provide an email to successfully subscribe to our newsletter.",
                timer: 5000,
                timerProgressBar: true,
                icon: 'warning',
                showCancelButton: true,
            })

            return
        }

        try {
            
            const data = {
                SubscriberEmail: subscriberEmail
            }

            dispatch(signUpForNewsletter(data))

            Swal.fire({
                icon: 'success',
                title: "Sign Up Successful",
                text: "Thanks for signing up for the Radiant Newsletter. Look out for newsletter notifications in your email and on our website.",
                timer: 6000,
                timerProgressBar: true,
                showCancelButton: false,
            }).then(() => {
                let reload = window.location.reload()
            })

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: "An Error Occurred",
                text: "Please try to sign up for our newsletter once again.",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: true,
            })

            return
        }
    }

    return (
        <>
            <div className="relative flex justify-center text-white sm:justify-start">
                <div className="container pl-20">
                    <h1 className="text-5xl text-dark-olive">
                        Blog.
                    </h1>
                </div>
            </div>
            <div className='container'>
                <section className="blog-tabs-wrapper">
                    <div className="blog-tabs-container">
                        <div className="blog-tabs-block">
                            <div className="blog-tabs">
                                <input 
                                    type="radio" 
                                    name="blog-tabs" 
                                    id="blogTab1" 
                                    checked={selectedBlogTab === 'blogTab1'} 
                                    onChange={handleBlogTabChange}
                                />
                                <label htmlFor="blogTab1">
                                    {
                                        blogCategories?.data?.length > 0 ? (
                                            blogCategories?.data?.map((category) => {
                                                return (
                                                    category?.attributes?.Category?.toLowerCase() === 'resources' ? (
                                                        <h2 className="text-xl md:font-normal">
                                                            {
                                                                category?.attributes?.Category
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="blog-tab text-left">
                                    
                                    {/* Resources go here */}
                                    <div className="overflow-auto h-140" id='blog-scroll'>
                                        <div className="container px-2 py-2 mx-auto">
                                            <div className="flex flex-column">
                                                <div className="grid md:w-1/4 mt-4 pr-8 ml-0 pl-0 max-[480px]:hidden">
                                                    <input 
                                                        type="search" 
                                                        placeholder='Search Articles' 
                                                        className="w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" 
                                                        value={searchTerm} 
                                                        onChange={handleSearchChange}
                                                    />

                                                    {/* <div className='xl:-mt-[300px]'> */}
                                                    <div className=''>
                                                        <div className="bg-dark-olive font-semibold rounded-t-md pt-1 pb-1 pl-2 mt-6">
                                                            <h4 className='text-white'>Recent Articles</h4>
                                                        </div>
                                                        <div className="bg-white h-66 pb-6">
                                                            <ol className='pl-2 pt-2 pr-2'>
                                                                {
                                                                    recentResourcesArticles?.data?.length > 0 ? (
                                                                        recentResourcesArticles?.data?.map((article) => {
                                                                            return (
                                                                                <a href="#!" key={article?.id} onClick={(e) => handleResourceArticleClick(e, article)}>
                                                                                    <li className="text-sm text-forest-green-vintage mt-2">
                                                                                        {/* <span className="text-black font-semibold">{counter++}.</span>  */}
                                                                                        {article?.attributes?.Title}
                                                                                    </li>
                                                                                </a>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </ol>
                                                        </div>

                                                        <div className="h-[400px] mt-14 bg-green-500">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD1_ncpsbv.gif" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap w-full mt-6">
                                                    <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full w-3/4 mt-6">
                                                        <input 
                                                            type="search" 
                                                            placeholder='Search Articles' 
                                                            className="md:hidden w-full h-8 outline-none rounded bg-gray-100 mt-2" 
                                                            value={searchTerm} 
                                                            onChange={handleSearchChange}
                                                        />
                                                        {
                                                            filteredArticles?.length > 0 ? (
                                                                filteredArticles?.map((article) => {
                                                                    const category = article?.attributes?.BlogCategory?.data?.attributes?.Category?.toLowerCase()
                                                                    return (
                                                                        category === 'resources' ? (
                                                                            <>
                                                                                <div key={article?.id}>
                                                                                    <a href="#!" className="block h-54 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl" onClick={(e) => handleResourceArticleClick(e, article)}>
                                                                                        {
                                                                                            article?.attributes?.CoverImage?.data?.attributes?.url ? (
                                                                                                <img src={`${article?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                                            ) : (
                                                                                                <div className="rounded-lg h-full bg-gray-200 flex items-center justify-center">
                                                                                                    <span>No Image</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </a>
                                                                                    <div className="flex items-center justify-between mt-3">
                                                                                        <a href="#!" onClick={(e) => handleResourceArticleClick(e, article)}>
                                                                                            <div>
                                                                                                <span className="font-medium text-sm">
                                                                                                    {article?.attributes?.BlogCategory?.data?.attributes?.Category} - {formatDate(article?.attributes?.Date)}
                                                                                                </span>
                                                                                                <p className="flex items-center text-forest-green-vintage font-semibold mt-1">
                                                                                                    { article?.attributes?.Title }
                                                                                                </p>
                                                                                                <p className="font-normal text-sm mt-1">
                                                                                                    {
                                                                                                        <ReactMarkdown>
                                                                                                            {createArticlePreview(article?.attributes?.BodyText, 10)}
                                                                                                        </ReactMarkdown>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    )
                                                                })
                                                            ) : (
                                                                <>
                                                                    <p>No articles available</p>
                                                                </>
                                                            )
                                                        }
                                                        
                                                        <ResourceArticle />
                                                    </div>
                                                    <div className="md:w-1/4 pl-6 pr-0md:pr-3 pt-0">
                                                        <div className="hidden md:block">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD3_kiuwdo.gif" alt="Ad" className="w-full h-auto pt-0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <input 
                                    type="radio" 
                                    name="" 
                                    id="blogTab2" 
                                    checked={selectedBlogTab === 'blogTab2'} 
                                    onChange={handleBlogTabChange}
                                />
                                <label htmlFor="blogTab2">
                                    {
                                        blogCategories?.data?.length > 0 ? (
                                            blogCategories?.data?.map((category) => {
                                                return (
                                                    category?.attributes?.Category?.toLowerCase() === 'agribusiness' ? (
                                                        <h2 className="text-xl md:font-normal">
                                                            {
                                                                category?.attributes?.Category
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="blog-tab text-left">
                                    {/* Agribusiness articles go here*/}
                                    <div className="overflow-auto h-140" id='blog-scroll'>
                                        <div className="container px-2 py-2 mx-auto">
                                            <div className="flex flex-column">
                                                <div className="grid md:w-1/4 mt-4 pr-8 ml-0 pl-0 max-[480px]:hidden">
                                                    <input 
                                                        type="search" 
                                                        placeholder='Search Articles' 
                                                        className="w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" 
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />

                                                    <div className=''>
                                                    {/* <div className='xl:-mt-[100px]'> */}
                                                        <div className="bg-dark-olive font-semibold rounded-t-md p-1 mt-6">
                                                            <h4 className='text-white pl-2'>Recent Articles</h4>
                                                        </div>
                                                        <div className="bg-white h-70">
                                                            <ol className='pl-2 pt-2 pr-2 text-md'>
                                                            {
                                                                    recentAgribusinessArticles?.data?.length > 0 ? (
                                                                        recentAgribusinessArticles?.data?.map((article) => {
                                                                            return (
                                                                                <a href="#!" key={article?.id} onClick={(e) => handleAgribusinessArticleClick(e, article)}>
                                                                                    <li className="text-sm text-forest-green-vintage mt-2">
                                                                                        {/* <span className="text-black font-semibold">{counter++}.</span>  */}
                                                                                        {article?.attributes?.Title}
                                                                                    </li>
                                                                                </a>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </ol>
                                                        </div>

                                                        <div className="h-[400px] mt-14 bg-green-500">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD1_ncpsbv.gif" alt="" />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap w-full mt-6">
                                                    <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full w-3/4 mt-6">
                                                        <input 
                                                            type="search" 
                                                            placeholder='Search Articles' 
                                                            className="md:hidden w-full h-8 outline-none rounded bg-gray-100 mt-2" 
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                        />
                                                        {
                                                            filteredArticles?.length > 0 ? (
                                                                filteredArticles?.map((article) => {
                                                                    const category = article?.attributes?.BlogCategory?.data?.attributes?.Category?.toLowerCase()

                                                                    return (
                                                                        category === 'agribusiness' ? (
                                                                            <>
                                                                                <div key={article?.id}>
                                                                                    <a href="#!" className="block h-54 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-x" onClick={(e) => handleAgribusinessArticleClick(e, article)}>
                                                                                        {
                                                                                            article?.attributes?.CoverImage?.data?.attributes?.url ? (
                                                                                                <img src={`${article?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                                            ) : (
                                                                                                <div className="rounded-lg h-full bg-gray-200 flex items-center justify-center">
                                                                                                    <span>No Image</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </a>
                                                                                    <div className="flex items-center justify-between mt-3">
                                                                                        <a href="#!" onClick={(e) => handleAgribusinessArticleClick(e, article)}>
                                                                                            <div>
                                                                                                <span className="font-medium text-sm">
                                                                                                    { article?.attributes?.BlogCategory?.data?.attributes?.Category } - {formatDate(article?.attributes?.Date)}
                                                                                                </span>
                                                                                                <p className="flex items-center text-forest-green-vintage font-semibold mt-1">
                                                                                                    {
                                                                                                        article?.attributes?.Title
                                                                                                    }
                                                                                                </p>
                                                                                                <p className="font-normal text-sm mt-1">
                                                                                                    {
                                                                                                        <ReactMarkdown>
                                                                                                            {createArticlePreview(article?.attributes?.BodyText, 10)}
                                                                                                        </ReactMarkdown>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    )
                                                                })
                                                            ) : (
                                                                <>
                                                                    <p>No articles available</p>
                                                                </>
                                                            )
                                                        }

                                                        <AgribusinessArticle />
                                                    </div>
                                                    <div className="md:w-1/4 pl-6 pr-0 md:pr-3 pt-0">
                                                        <div className="hidden md:block">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570300/Radiant%20Farm/Website%20ADs/GIFs/AD2_onrxb1.gif" alt="Ad" className="w-full h-auto pt-0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <input 
                                    type="radio" 
                                    name="" 
                                    id="blogTab3" 
                                    checked={selectedBlogTab === 'blogTab3'} 
                                    onChange={handleBlogTabChange}
                                />
                                <label htmlFor="blogTab3">
                                    {
                                        blogCategories?.data?.length > 0 ? (
                                            blogCategories?.data?.map((category) => {
                                                return (
                                                    category?.attributes?.Category?.toLowerCase() === 'events' ? (
                                                        <h2 className="text-xl md:font-normal">
                                                            {
                                                                category?.attributes?.Category
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="blog-tab text-left">
                                    {/* Events go here */}

                                    <div className="overflow-auto h-140" id="blog-scroll">
                                        <div className="container px-2 py-2 mx-auto">
                                            <div className="flex flex-column">
                                                <div className="grid w-1/4 mt-4 pr-8 ml-0 pl-0 max-[480px]:hidden">
                                                    {/* <input 
                                                        type="search" 
                                                        placeholder="Search" 
                                                        id="" 
                                                        className="w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" 
                                                    /> */}

                                                    <div className='mt-4'>
                                                        <div className="bg-dark-olive font-semibold rounded-t-md pt-1 pb-1 pl-2">
                                                            <h4 className="text-white">
                                                                Upcoming Events
                                                            </h4>
                                                        </div>
                                                        <div className="bg-white pb-6">
                                                            <ol className="pl-2 pt-2 pr-2">
                                                                {
                                                                    upcomingEvents?.data?.length > 0 ? (
                                                                        upcomingEvents?.data?.map((event) => {
                                                                            return (
                                                                                <a href="#!" key={event?.id}>
                                                                                    <li className="text-sm text-forest-green-vintage mt-2">
                                                                                        {event?.attributes?.EventTitle}
                                                                                    </li>
                                                                                </a>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </ol>
                                                        </div>

                                                        <div className="bg-dark-olive font-semibold rounded-t-md pt-1 pb-1 pl-2 mt-6">
                                                            <h4 className="text-white">
                                                                What you probably missed
                                                            </h4>
                                                        </div>
                                                        <div className="bg-white pb-6">
                                                            <ol className="pl-2 pt-2 pr-2">
                                                                {
                                                                    recentEvents?.data?.length > 0 ? (
                                                                        recentEvents?.data?.map((event) => {
                                                                            return (
                                                                                <a href="#!">
                                                                                    <li className="text-sm text-forest-green-vintage mt-2">
                                                                                        <span className="text-black font-semibold">{counter++}.</span> {event?.attributes?.EventTitle}
                                                                                    </li>
                                                                                </a>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </ol>
                                                        </div>

                                                        <div className="h-[400px] mt-14 bg-green-500">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD3_kiuwdo.gif" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap w-full mt-6">
                                                    <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full w-3/4 mt-6">
                                                        {/* <input type="search" placeholder="Search" id="" className="md:hidden w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" /> */}
                                                        {
                                                            events?.data?.length > 0 ? (
                                                                events?.data?.map((event) => {
                                                                    return (
                                                                        <>
                                                                            <div key={event?.id}>
                                                                                <a href="#!" className="block h-54 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl">
                                                                                    {
                                                                                        event?.attributes?.CoverImage?.data?.attributes?.url ? (
                                                                                            <img src={`${event?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                                        ) : (
                                                                                            <div className="rounded-lg h-full bg-gray-200 flex items-center justify-center">
                                                                                                <span>No Image</span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </a>
                                                                                <div className="flex items-center justify-between mt-3">
                                                                                    <a href="#!">
                                                                                        <div>
                                                                                            <span className="font-medium text-sm">
                                                                                                {event?.attributes?.BlogCategory?.data?.attributes?.Category} - {event?.attributes?.Month} {event?.attributes?.Year}
                                                                                            </span>
                                                                                            <p className="flex items-center text-forest-green-vintage font-semibold mt-1">
                                                                                                {
                                                                                                    event?.attributes?.EventTitle
                                                                                                }
                                                                                            </p>
                                                                                            <button className="bg-dark0olive mt-4 pt-2 pr-4 pb-2 pl-4 rounded-lg text-sm text-radiant-yellow">
                                                                                                {
                                                                                                    event?.attributes?.Status
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            ) : (
                                                                <></>
                                                            )
                                                        }

                                                        {/* <EventsArticle /> */}
                                                    </div>
                                                    <div className="md:w-1/4 pl-6 pr-0 md:pr-3 pt-0">
                                                        <div className="hidden md:block">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716570301/Radiant%20Farm/Website%20ADs/GIFs/AD3_kiuwdo.gif" alt="Ad" className="w-full h-auto pt-0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <input 
                                    type="radio" 
                                    name="blog-tabs" 
                                    id="blogTab4" 
                                    checked={selectedBlogTab === 'blogTab4'} 
                                    onChange={handleBlogTabChange}
                                />
                                <label htmlFor="blogTab4">
                                    {
                                        blogCategories?.data?.length > 0 ? (
                                            blogCategories?.data?.map((category) => {
                                                return (
                                                    category?.attributes?.Category?.toLowerCase() === 'newsletters' ? (
                                                        <h2 className="text-xl md:font-normal">
                                                            {
                                                                category?.attributes?.Category
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="blog-tab text-left">
                                    {/* Newsletters go here */}
                                    <div className="overflow-auto h-140" id="blog-scroll">
                                        <div className="container px-2 py-2 mx-auto">
                                            <div className="flex flex-column">
                                                <div className="grid w-1/4 mt-4 pr-8 ml-0 pl-0 max-[480px]:hidden">
                                                    <input 
                                                        type="search" 
                                                        placeholder="Search" 
                                                        id="" 
                                                        className="w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" 
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />

                                                    <div className="mt-10">
                                                        <h3 className="text-3xl text-dark-olive">
                                                            Subscribe here
                                                        </h3>
                                                        <p className="text-dark-olive text-sm">
                                                            Subscribe to Radiant Farm's monthly 
                                                            news letter for indepth analysis and 
                                                            expertly curated articles about the 
                                                            agribusiness sector.
                                                        </p>
                                                        <button className="bg-dark-olive rounded-md mt-4 pl-2 pr-2 pt-1 pb-1 text-radiant-yellow" onClick={toggleSubscribeModal}>
                                                            Subscribe
                                                        </button>
                                                    </div>

                                                    <div className='mt-4'>
                                                        <div className="bg-dark-olive rounded-t-md p-1">
                                                            <h4 className="text-white">
                                                                Recent Newsletters
                                                            </h4>
                                                        </div>
                                                        <div className="bg-white pb-6">
                                                            <ol className="pl-2 pt-2 pr-2">
                                                                {
                                                                    recentNewsletterArticles?.data?.length > 0 ? (
                                                                        recentNewsletterArticles?.data?.map((article) => {
                                                                            return (
                                                                                <a href="#!" key={article?.id} onClick={(e) => handleNewslettersArticleClick(e, article)}>
                                                                                    <li className="text-sm text-forest-green-vintage mt-2">
                                                                                        {/* <span className="text-black font-semibold">{counter++}.</span>  */}
                                                                                        {article?.attributes?.Title}
                                                                                    </li>
                                                                                </a>
                                                                            )
                                                                        })
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </ol>
                                                        </div>

                                                        <div className="h-[400px] mt-14 bg-green-500">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716548112/Radiant%20Farm/Website%20ADs/RF_WEB_AD_4_copy_yhuutz.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap w-full mt-6">
                                                    <div className="flex-1 grid 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6 gap-y-12 w-full w-3/4 mt-6">
                                                        <input 
                                                            type="search" 
                                                            placeholder="Search" 
                                                            id="" 
                                                            className="md:hidden w-full h-8 hidden sm:block outline-none rounded bg-gray-100 p-2 mt-2" 
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                        />
                                                        {
                                                            filteredArticles?.length > 0 ? (
                                                                filteredArticles?.map((article) => {
                                                                    return (
                                                                        article?.attributes?.BlogCategory?.data?.attributes?.Category?.toLowerCase() === 'newsletters' ? (
                                                                            <>
                                                                                <div key={article?.id}>
                                                                                    <a href="#!" className="block h-54 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-x" onClick={(e) => handleNewslettersArticleClick(e, article)}>
                                                                                        {
                                                                                            article?.attributes?.CoverImage?.data?.attributes?.url ? (
                                                                                                <img src={`${article?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" className="rounded-lg h-full" />
                                                                                            ) : (
                                                                                                <div className="rounded-lg h-full bg-gray-200 flex items-center justify-center">
                                                                                                    <span>No Image</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </a>
                                                                                    <div className="flex items-center justify-between mt-3">
                                                                                        <a href="#!" onClick={(e) => handleNewslettersArticleClick(e, article)}>
                                                                                            <div>
                                                                                                <span className="font-medium text-sm">
                                                                                                    { article?.attributes?.BlogCategory?.data?.attributes?.Category } - {formatDate(article?.attributes?.Date)}
                                                                                                </span>
                                                                                                <p className="flex items-center text-forest-green-vintage font-semibold mt-1">
                                                                                                    { article?.attributes?.Title }
                                                                                                </p>
                                                                                                <p className="font-normal text-sm mt-1">
                                                                                                    {
                                                                                                        <ReactMarkdown>
                                                                                                            {createArticlePreview(article?.attributes?.BodyText, 10)}
                                                                                                        </ReactMarkdown>
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    )
                                                                })
                                                            ) : (
                                                                <>
                                                                    <p>No articles available</p>
                                                                </>
                                                            )
                                                        }

                                                        <NewsletterArticle />
                                                    </div>
                                                    <div className="md:w-1/4 pl-6 pr-0 md:pr-3 pt-0">
                                                        <div className="hidden md:block">
                                                            <img src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1716548112/Radiant%20Farm/Website%20ADs/RF_WEB_AD_2_copy_ysaow1.png" alt="Ad" className="w-full h-auto pt-0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Subscribe Modal */}
            <div className="fixed z-50 overflow-y-auto top-0 w-full left-0 hidden" id="subscribeModal">
                <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#203;</span>
                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby='modal-headline'>
                        <div className="bg-gray-200 py-3">
                            <p className="text-2xl text-radiant-dark-green font-semibold ml-6">Newsletter Signup</p>
                            <button className="py-2 px-4 text-black rounded mr-2 absolute right-0 -mt-9" onClick={toggleSubscribeModal}>
                                <FaCircleXmark className='text-radiant-dark-green size-6' />
                            </button>
                        </div>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <form onSubmit={handleNewsletterSubscriptionSubmit}>
                                <label htmlFor="names" className="font-medium text-gray-800 text-radiant-dark-green">Email Address</label>
                                <input type="email" name="email" id='email' className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3" onChange={handleSubscriberEmailChange} />

                                <button type="submit" className="rounded-3xl bg-radiant-dark-green hover:bg-gray-400 py-2 px-4 block w-full text-white justify-center whitespace-no-wrap">
                                    <span className="ml-4">Submit</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog