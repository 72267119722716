import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const NAV_LINKS_URI = '/nav-links?populate=*'
const FOOTER_LINKS_URI = '/footer-links?populate=*'

// Get Nav Links
const getAllNavLinks = async () => {

    const response = await axios.get(`${API_URL}${NAV_LINKS_URI}`)

    return response.data

}

const getAllFooterLinks = async () => {

    const response = await axios.get(`${API_URL}${FOOTER_LINKS_URI}`)

    return response.data
}

const navigationService = {
    getAllNavLinks,
    getAllFooterLinks
}

export default navigationService