import axios from "axios";

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const REGIONS_URI = '/regions?populate=*'
const DISTRICTS_URI = '/districts?populate=*&region='

// Get Regions
const getRegions = async () => {

    const response = await axios.get(`${API_URL}${REGIONS_URI}`)

    return response.data
}

// Get Districts
const getDistricts = async (id) => {

    const response = await axios.get(`${API_URL}${DISTRICTS_URI}${id}`, {
        params: {
            _limit: 1000,
            _start: 0
        }
    })

    console.log('Getting all districts:', response.data)

    return response.data
}

const locationService = {
    getRegions,
    getDistricts
}

export default locationService