import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import aboutUsPageService from './aboutUsPageService'

const initialState = {
    aboutUsTabs: [],
    aboutUsContent: [],
    companyValues: [],
    teamMembers: [],
    partners: [],
    services: [],
    companyVision: null,
    companyMission: null,
    isErrorAboutUs: false,
    isSuccessAboutUs: false,
    isLoadingAboutUs: false,
    message: ''
}

// Get About Us Tabs
export const getAboutUsTabs = createAsyncThunk('aboutUsTabs/list', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getAboutUsTabs()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get About Us Content
export const getAboutUsContent = createAsyncThunk('aboutUsContent/list', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getAboutUsContent()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Company Mission
export const getCompanyVision = createAsyncThunk('aboutUsContent/companyVision', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getCompanyVision()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Company Mission
export const getCompanyMission = createAsyncThunk('aboutUsContent/companyMission', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getCompanyMission()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Company Values
export const getCompanyValues = createAsyncThunk('aboutUsContent/companyValues', async(_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getCompanyValues()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Team Members
export const getTeamMembers = createAsyncThunk('aboutUsContent/teamMembers', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getTeamMembers()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Partners
export const getPartners = createAsyncThunk('aboutUsContent/partners', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getPartners()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Services
export const getServices = createAsyncThunk('aboutUsContent/services', async (_, thunkAPI) => {
    try {
        
        return await aboutUsPageService.getServices()

    } catch (error) {
        console.error(error)
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const aboutUsPageSlice = createSlice({
    name: 'aboutUs',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingAboutUs = false
            state.isErrorAboutUs = false
            state.isSuccessAboutUs = false
            state.message = ''
            state.aboutUsTabs = []
            state.aboutUsContent = []
            state.companyValues = []
            state.teamMembers = []
            state.partners = []
            state.services = []
            state.companyVision = null
            state.companyMission = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAboutUsTabs.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getAboutUsTabs.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.aboutUsTabs = action.payload
            })
            .addCase(getAboutUsTabs.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.aboutUsTabs = []
            })
            .addCase(getAboutUsContent.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getAboutUsContent.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.aboutUsContent = action.payload
            })
            .addCase(getAboutUsContent.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.aboutUsContent = []
            })
            .addCase(getCompanyVision.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getCompanyVision.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.companyVision = action.payload
            })
            .addCase(getCompanyVision.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.companyVision = null
            })
            .addCase(getCompanyMission.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getCompanyMission.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.companyMission = action.payload
            })
            .addCase(getCompanyMission.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.companyMission = null
            })
            .addCase(getCompanyValues.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getCompanyValues.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.companyValues = action.payload
            })
            .addCase(getCompanyValues.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.companyValues = []
            })
            .addCase(getTeamMembers.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getTeamMembers.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.teamMembers = action.payload
            })
            .addCase(getTeamMembers.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.teamMembers = []
            })
            .addCase(getPartners.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getPartners.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.partners = action.payload
            })
            .addCase(getPartners.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.partners = []
            })
            .addCase(getServices.pending, (state) => {
                state.isLoadingAboutUs = true
            })
            .addCase(getServices.fulfilled, (state, action) => {
                state.isLoadingAboutUs = false
                state.isSuccessAboutUs = true
                state.services = action.payload
            })
            .addCase(getServices.rejected, (state, action) => {
                state.isLoadingAboutUs = false
                state.isErrorAboutUs = true
                state.message = action.payload
                state.services = []
            })
    }
})

export const { reset } = aboutUsPageSlice.actions
export default aboutUsPageSlice.reducer