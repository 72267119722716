import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const MEDIA_TABS_URI = '/gallery-tabs?populate=*'
const IMAGES_URI = '/images?populate=*'
const GIFS_URI = '/gifs?populate=*'
const VIDEOS_URI = '/videos?populate=*'
const AUDIOS_URI = '/audios?popualte=*'

const getMediaTabs = async () => {

    const response = await axios.get(`${API_URL}${MEDIA_TABS_URI}`)

    return response.data
}

const getImages = async () => {

    const response = await axios.get(`${API_URL}${IMAGES_URI}`)

    return response.data
}

const getGIFs = async () => {

    const response = await axios.get(`${API_URL}${GIFS_URI}`)

    return response.data
}

const getVideos = async () => {

    const response = await axios.get(`${API_URL}${VIDEOS_URI}`)

    return response.data
}

const getAudios = async () => {

    const response = await axios.get(`${API_URL}${AUDIOS_URI}`)

    return response.data
}

const mediaService = {
    getMediaTabs,
    getImages,
    getGIFs,
    getVideos,
    getAudios
}

export default mediaService