import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/AboutUs.css'
import { Link as ScrollLink, scrollSpy } from 'react-scroll'
import { getAboutUsTabs, getAboutUsContent, getCompanyVision, getCompanyMission, getCompanyValues, getTeamMembers, getPartners, getServices } from '../../features/aboutUsPageContent/aboutUsPageSlice'

const AboutUs = ({ onNavClick }) => {

    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = useState('tab1')

    let { aboutUsTabs, aboutUsContent, companyVision, companyMission, companyValues, teamMembers, partners, services, isErrorAboutUs, isLoadingAboutUs, isSuccessAboutUs } = useSelector((state) => state.aboutUs)

    const handleTabChange = (event) => {
        setSelectedTab(event.target.id)
    }

    useEffect(() => {
        dispatch(getAboutUsTabs())
        dispatch(getAboutUsContent())
        dispatch(getCompanyVision())
        dispatch(getCompanyMission())
        dispatch(getCompanyValues())
        dispatch(getPartners())
        dispatch(getTeamMembers())
        dispatch(getServices())
    }, [])

    useEffect(() => {
        scrollSpy.update()
    }, [])

    if (isLoadingAboutUs) {
        return <p>Loading...</p>
    }

    if (isErrorAboutUs) {
        return <p>An error occurred...</p>
    }

    return (
        <>
            <div className="relative flex justify-center text-white sm:justify-start">
                <div className="container pl-20">
                    <h1 className='text-5xl text-dark-olive'>About Us.</h1>
                </div>
            </div>
            <div className="container">
                <section className="tabs-wrapper">
                    <div className="tabs-container">
                        <div className="tabs-block">
                            <div className="tabs">
                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab1" 
                                    checked={selectedTab === 'tab1'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab1">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'radiant farm' ? (
                                                        <h2 className='text-lg md:font-normal'>
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    <section className="overflow-x-hidden overflow-auto h-140 md:pr-3" id="about-us-scroll">
                                        {
                                            aboutUsContent?.data?.length > 0 ? (
                                                aboutUsContent?.data?.map((contentRecord) => {
                                                    return (
                                                        contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle.toLowerCase() === 'radiant farm' ? (
                                                            <>
                                                                <div className="p-2">
                                                                    <img src={`${contentRecord?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" />

                                                                    <h3 className="text-3xl pt-3 pb-3 text-dark-olive font-semibold">
                                                                        {
                                                                            contentRecord?.attributes?.Title
                                                                        }
                                                                    </h3>

                                                                    <div className="text">
                                                                        {
                                                                            contentRecord?.attributes?.BodyText
                                                                        }
                                                                    </div>

                                                                    <h3 className="text-3xl mt-8 underline text-dark-olive">
                                                                        Our Purpose
                                                                    </h3>

                                                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                                                        <div className="block rounded-lg shadow-lg bg-white text-center items-center duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 p-3 cursor-pointer">
                                                                            <img src={`${companyVision?.data?.attributes?.Icon?.data?.attributes?.url}`} className='h-24 w-24 rounded-full mx-auto' alt="" />
                                                                            <p className="text-xl mt-4 underline font-bold text-radiant-lime-green">
                                                                                Our Vision
                                                                            </p>
                                                                            <p className="mt-2">
                                                                                {
                                                                                    companyVision?.data?.attributes?.CompanyVision
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div className="block rounded-lg shadow-lg bg-white text-center items-center duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 p-3 cursor-pointer">
                                                                            <img src={`${companyMission?.data?.attributes?.Icon?.data?.attributes?.url}`} className='h-24 w-24 rounded-full mx-auto' alt="" />
                                                                            <p className="text-xl mt-4 underline font-bold text-radiant-lime-green">
                                                                                Our Mission
                                                                            </p>
                                                                            <p className="mt-2">
                                                                                {
                                                                                    companyMission?.data?.attributes?.CompanyMission
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <h3 className="text-3xl underline text-dark-olive">
                                                                        Our Values
                                                                    </h3>

                                                                    <div className="grid grid cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                                                        {
                                                                            companyValues?.data?.length > 0 ? (
                                                                                companyValues?.data?.map((record) => {
                                                                                    return (
                                                                                        <div className="block rounded-lg shadow-lg text-center items-center bg-white duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 p-3 cursor-pointer">
                                                                                            <img src={`${record?.attributes?.Icon?.data?.attributes?.url}`} className='h-24 w-24 rounded-full mx-auto' alt="" />
                                                                                            <p className="text-xl mt-4 font-semibold text-dark-olive">
                                                                                                {
                                                                                                    record?.attributes?.Title
                                                                                                }
                                                                                            </p>
                                                                                            <p className="mt-2">
                                                                                                {
                                                                                                    record?.attributes?.Description
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            ) : (
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className="mt-9">
                                                                        <ScrollLink 
                                                                            to="media" 
                                                                            spy={true}  
                                                                            className='bg-dark-olive text-radiant-yellow pl-4 pr-4 pt-2 pb-2 rounded-lg cursor-pointer'
                                                                            smooth={true} 
                                                                            onClick={() => onNavClick("media")}
                                                                        >
                                                                            {
                                                                                contentRecord?.attributes?.CallToAction
                                                                            }
                                                                        </ScrollLink>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )
                                                    )
                                                })
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </section>
                                </div>

                                {/* Team Members */}

                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab2" 
                                    checked={selectedTab === 'tab2'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab2">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'our team' || 
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'our people' ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                        {
                                            teamMembers?.data?.length > 0 ? (
                                                teamMembers?.data?.map((member) => {
                                                    return (
                                                        <>
                                                            <div className="block h-72 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 cursor-pointer">
                                                                <img src={`${member?.attributes?.MemberImage?.data?.attributes?.url}`} alt="" className='rounded-lg h-full w-full' />
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>

                                {/* Team Members */}

                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab3" 
                                    checked={selectedTab === 'tab3'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab3">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'radigro™ feeds' ||
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'radigro feeds' ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    {
                                        aboutUsContent?.data?.length > 0 ? (
                                            aboutUsContent?.data?.map((contentRecord) => {
                                                return (
                                                    contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle.toLowerCase() === 'radigro™ feeds' ||
                                                    contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle.toLowerCase() === 'radigro feeds' ? (
                                                        <>
                                                            <img src={`${contentRecord?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" />

                                                            <h3 className="text-3xl pt-3 pb-3 text-dark-olive font-semibold">
                                                                {
                                                                    contentRecord?.attributes?.Title
                                                                }
                                                            </h3>

                                                            <div className="text">
                                                                {
                                                                    contentRecord?.attributes?.BodyText
                                                                }
                                                            </div>

                                                            <div>
                                                            <ScrollLink 
                                                                to="shop" 
                                                                spy={true}  
                                                                className='bg-dark-olive text-radiant-yellow pl-4 pr-4 pt-2 pb-2 rounded-lg cursor-pointer'
                                                                smooth={true} 
                                                                onClick={() => onNavClick("shop")}
                                                            >
                                                                {
                                                                    contentRecord?.attributes?.CallToAction
                                                                }
                                                            </ScrollLink>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab4" 
                                    checked={selectedTab === 'tab4'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab4">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'farm tours' ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    {
                                        aboutUsContent?.data?.length > 0 ? (
                                            aboutUsContent?.data?.map((contentRecord) => {
                                                return (
                                                    contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle?.toLowerCase() === 'farm tours' ? (
                                                        <>
                                                            <img src={`${contentRecord?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" />

                                                            <h3 className="text-3xl pt-3 pb-3 text-dark-olive font-semibold">
                                                                {
                                                                    contentRecord?.attributes?.Title
                                                                }
                                                            </h3>

                                                            <div className="text">
                                                                {
                                                                    contentRecord?.attributes?.BodyText
                                                                }
                                                            </div>

                                                            <div className="mt-9">
                                                            <ScrollLink 
                                                                to="blog" 
                                                                spy={true}  
                                                                className='bg-dark-olive text-radiant-yellow pl-4 pr-4 pt-2 pb-2 rounded-lg cursor-pointer'
                                                                smooth={true} 
                                                                onClick={() => onNavClick("blog")}
                                                            >
                                                                {
                                                                    contentRecord?.attributes?.CallToAction
                                                                }
                                                            </ScrollLink>
                                                                {/* <button className="bg-dark-olive text-radiant-yellow pl-4 pr-4 pt-2 pb-2 rounded-lg cursor-pointer">
                                                                    {
                                                                        contentRecord?.attributes?.CallToAction
                                                                    }
                                                                </button> */}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab5" 
                                    checked={selectedTab === 'tab5'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab5">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === "radiant farmers' workshop" || 
                                                    tab?.attributes?.TabTitle?.toLowerCase() === "radiant farmers workshop" ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    {
                                        aboutUsContent?.data?.length > 0 ? (
                                            aboutUsContent?.data?.map((contentRecord) => {
                                                return (
                                                    contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle?.toLowerCase() === "radiant farmers' workshop" || 
                                                    contentRecord?.attributes?.AboutUsTab?.data?.attributes?.TabTitle?.toLowerCase() === 'radiant farmers workshop' ? (
                                                        <>
                                                            <img src={`${contentRecord?.attributes?.CoverImage?.data?.attributes?.url}`} alt="" />

                                                            <h3 className="text-3xl pt-3 pb-3 text-dark-olive font-semibold">
                                                                {
                                                                    contentRecord?.attributes?.Title
                                                                }
                                                            </h3>

                                                            <div className="text">
                                                                {
                                                                    contentRecord?.attributes?.BodyText
                                                                }
                                                            </div>

                                                            <div className="mt-9">
                                                                <button className="bg-dark-olive text-radiant-yellow pl-4 pr-4 pt-2 pb-2 rounded-lg cursor-pointer">
                                                                    {
                                                                        contentRecord?.attributes?.CallToAction
                                                                    }
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>

                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab6" 
                                    checked={selectedTab === 'tab6'} 
                                    onChange={handleTabChange} 
                                />
                                <label htmlFor="tab6">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'services' ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    <div className="grid grid cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                        {
                                            services?.data?.length > 0 ? (
                                                services?.data?.map((service) => {
                                                    return (
                                                        <>
                                                            <div className="block rounded-lg shadow-lg text-center items-center bg-white duration-500 hover:scale-105 hover:shadow-xl mt-3 mb-3 mr-2 ml-2 p-3 cursor-pointer">
                                                                {
                                                                    service?.attributes?.Icon?.data !== null ? (
                                                                        <>
                                                                            <img src={`${service?.attributes?.Icon?.data?.attributes?.url}`} className='h-24 w-24 mx-auto' alt="" />
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                <h3 className="text-xl pt-3 pb-3 text-radiant-lime-green font-semibold">
                                                                    {
                                                                        service?.attributes?.Service
                                                                    }
                                                                </h3>

                                                                <div className="text-dark-olive">
                                                                    {
                                                                        service?.attributes?.ServiceDescription
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* Partners */}
                                <input 
                                    type="radio" 
                                    name="tabs" 
                                    id="tab7" 
                                    checked={selectedTab === 'tab7'} 
                                    onChange={handleTabChange}
                                />
                                <label htmlFor="tab7">
                                    {
                                        aboutUsTabs?.data?.length > 0 ? (
                                            aboutUsTabs?.data?.map((tab) => {
                                                return (
                                                    tab?.attributes?.TabTitle?.toLowerCase() === 'partners' ? (
                                                        <h2 className="text-lg md:font-normal">
                                                            {
                                                                tab?.attributes?.TabTitle
                                                            }
                                                        </h2>
                                                    ) : (
                                                        <></>
                                                    )
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                    }
                                </label>
                                <div className="tab text-left">
                                    <div className="grid grid cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                                        {
                                            partners?.data?.length > 0 ? (
                                                partners?.data?.map((partner) => {
                                                    return (
                                                        <>
                                                            <div key={partner?.id} className='text-center items-center mt-3 mb-3 mr-2 ml-2'>
                                                                <a href={partner?.attributes?.WebsiteURL ? partner?.attributes?.WebsiteURL : '#!'} className='block h-64 rounded-lg shadow-lg bg-white duration-500 hover:scale-105 hover:shadow-xl cursor-pointer'>
                                                                    <img src={`${partner?.attributes?.PartnerLogo?.data?.attributes?.url}`} alt="Logo" className="rounded-lg h-full w-full" />
                                                                </a>
                                                                <div>
                                                                    <a href={partner?.attributes?.WebsiteURL ? partner?.attributes?.WebsiteURL : '#!'}>
                                                                        <p className="font-medium">
                                                                            {
                                                                                partner?.attributes?.PartnerName
                                                                            }
                                                                        </p>
                                                                    </a>
                                                                    <p className="font-bold text-radiant-lime-green">
                                                                        {
                                                                            partner?.attributes?.PartnerCategory?.data?.attributes?.PartnerCategory
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AboutUs