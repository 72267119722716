import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import mediaService from './mediaService'

const initialState = {
    mediaTabs: [],
    images: [],
    gifs: [],
    videos: [],
    audios: [],
    isErrorMedia: false,
    isSuccessMedia: false,
    isLoadingMedia: false,
    message: ''
}

// Get Media Tabs
export const getMediaTabs = createAsyncThunk('media/mediaTabs/list', async (_, thunkAPI) => {
    try {
        
        return await mediaService.getMediaTabs()
        
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Images
export const getImages = createAsyncThunk('media/images/list', async (_, thunkAPI) => {
    try {
        
        return await mediaService.getImages()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get gifs
export const getGIFs = createAsyncThunk('media/gifs/list', async (_, thunkAPI) => {
    try {
        
        return await mediaService.getGIFs()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Videos
export const getVideos = createAsyncThunk('media/videos/list', async (_, thunkAPI) => {
    try {
        
        return await mediaService.getVideos()
        
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Audios
export const getAudios = createAsyncThunk('nedia/audios/list', async (_, thunkAPI) => {
    try {
        
        return await mediaService.getAudios()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingMedia = false
            state.isErrorMedia = false
            state.isSuccessMedia = false
            state.message = ''
            state.mediaTabs = []
            state.images = []
            state.gifs = []
            state.videos = []
            state.audios = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMediaTabs.pending, (state) => {
                state.isLoadingMedia = true
            })
            .addCase(getMediaTabs.fulfilled, (state, action) => {
                state.isLoadingMedia = false
                state.isSuccessMedia = true
                state.mediaTabs = action.payload
            })
            .addCase(getMediaTabs.rejected, (state, action) => {
                state.isLoadingMedia = false
                state.isErrorMedia = true
                state.message = action.payload
                state.mediaTabs = []
            })
            .addCase(getImages.pending, (state) => {
                state.isLoadingMedia = true
            })
            .addCase(getImages.fulfilled, (state, action) => {
                state.isLoadingMedia = false
                state.isSuccessMedia = true
                state.images = action.payload
            })
            .addCase(getImages.rejected, (state, action) => {
                state.isLoadingMedia = false
                state.isErrorMedia = true
                state.message = action.payload
                state.images = []
            })
            .addCase(getGIFs.pending, (state) => {
                state.isLoadingMedia = true
            })
            .addCase(getGIFs.fulfilled, (state, action) => {
                state.isLoadingMedia = false
                state.isSuccessMedia = true
                state.gifs = action.payload
            })
            .addCase(getGIFs.rejected, (state, action) => {
                state.isLoadingMedia = false
                state.isErrorMedia = true
                state.message = action.payload
                state.gifs = []
            })
            .addCase(getVideos.pending, (state) => {
                state.isLoadingMedia = true
            })
            .addCase(getVideos.fulfilled, (state, action) => {
                state.isLoadingMedia = false
                state.isSuccessMedia = true
                state.videos = action.payload
            })
            .addCase(getVideos.rejected, (state, action) => {
                state.isLoadingMedia = false
                state.isErrorMedia = true
                state.message = action.payload
                state.videos = []
            })
            .addCase(getAudios.pending, (state) => {
                state.isLoadingMedia = true
            })
            .addCase(getAudios.fulfilled, (state, action) => {
                state.isLoadingMedia = false
                state.isSuccessMedia = true
                state.audios = action.payload
            })
            .addCase(getAudios.rejected, (state, action) => {
                state.isLoadingMedia = false
                state.isErrorMedia = true
                state.message = action.payload
                state.audios = []
            })
    }
})

export const { reset } = mediaSlice.actions
export default mediaSlice.reducer