import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const TESTIMONIALS_URI = '/testimonials'

// Get Testimonials
const getTestimonials = async () => {

    const response = await axios.get(`${API_URL}${TESTIMONIALS_URI}`)

    return response.data
}

const testimonialsService = {
    getTestimonials
}

export default testimonialsService