import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import navigationService from './navigationService'

const initialState = {
    navigationLinks: [],
    footerLinks: [],
    isErrorNavigation: false,
    isSuccessNavigation: false,
    isLoadingNavigation: false,
    message: ''
}

// Get Nav Links
export const getNavLinks = createAsyncThunk('navLinks/list', async (_, thunkAPI) => {
    try {
        return await navigationService.getAllNavLinks()
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)

    }
})

// Get Footer Links
export const getFooterLinks = createAsyncThunk('footerLinks/list', async (_, thunkAPI) => {
    try {
        return await navigationService.getAllFooterLinks()
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingNavigation = false
            state.isErrorNavigation = false
            state.isSuccessNavigation = false
            state.message = ''
            state.navigationLinks = []
            state.footerLinks = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNavLinks.pending, (state) => {
                state.isLoadingNavigation = true
            })
            .addCase(getNavLinks.fulfilled, (state, action) => {
                state.isLoadingNavigation = false
                state.isSuccessNavigation = true
                state.navigationLinks = action.payload
            })
            .addCase(getNavLinks.rejected, (state, action) => {
                state.isLoadingNavigation = false
                state.isErrorNavigation = true
                state.message = action.payload
                state.navigationLinks = []
            })
            .addCase(getFooterLinks.pending, (state) => {
                state.isLoadingNavigation = true
            })
            .addCase(getFooterLinks.fulfilled, (state, action) => {
                state.isLoadingNavigation = false
                state.isSuccessNavigation = true
                state.footerLinks = action.payload
            })
            .addCase(getFooterLinks.rejected, (state, action) => {
                state.isLoadingNavigation = false
                state.isErrorNavigation = true
                state.message = action.payload
                state.footerLinks = []
            })
    }
})

export const { reset } = navigationSlice.actions
export default navigationSlice.reducer