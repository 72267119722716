import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import testimonialsService from './testimonialsService'

const initialState = {
    testimonials: [],
    isErrorTestimonials: false,
    isSuccessTestimonials: false,
    isLoadingTestimonials: false,
    message: ''
}

// Get Testimonials
export const getTestimonials = createAsyncThunk('testimonials/list', async (_, thunkAPI) => {
    try {
        
        return await testimonialsService.getTestimonials()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const testimonialsSlice = createSlice({
    name: 'testimonials',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingTestimonials = false
            state.isErrorTestimonials = false
            state.isSuccessTestimonials = false
            state.message = ''
            state.testimonials = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestimonials.pending, (state) => {
                state.isLoadingTestimonials = true
            })
            .addCase(getTestimonials.fulfilled, (state, action) => {
                state.isLoadingTestimonials = false
                state.isSuccessTestimonials = true
                state.testimonials = action.payload
            })
            .addCase(getTestimonials.rejected, (state, action) => {
                state.isLoadingTestimonials = false
                state.isErrorTestimonials = true
                state.message = action.payload
                state.testimonials = []
            })
    }
})

export const { reset } = testimonialsSlice.actions
export default testimonialsSlice.reducer