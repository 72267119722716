import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const ORDERS_URI = '/orders'
const DELIVERY_MODES_URI = '/delivery-modes'
const ORDER_PRODUCTS_URI = '/order-products'
const PAYMENT_MODES_URI = '/payment-modes'

// Create Order Products
const createOrderProduct = async (productData, token) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }

    const response = await axios.post(`${API_URL}${ORDER_PRODUCTS_URI}`, {
        data: productData
    }, config)
    
    return response?.data?.data?.id
}

const createOrderProducts = async (cartItems) => {

    const user = JSON.parse(localStorage.getItem('radiantUser'))

    const token = user?.jwt

    const orderProductIds = []

    for (const item of cartItems) {
        const orderProductData = {
            Quantity: item.quantity,
            Amount: parseInt(item.attributes.UnitPrice) * parseInt(item.quantity),
            Product: item.id
        }

        try {

            const orderProductId = await createOrderProduct(orderProductData, token)
            orderProductIds.push(orderProductId)

        } catch (error) {
            console.error('Error creating order product:', error);
        }
    }
    
    return orderProductIds
}

// Update Order Product with OrderID
const updateOrderProduct = async (orderProductId, orderId) => {
    
    const user = JSON.parse(localStorage.getItem('radiantUser'))

    const token = user?.jwt

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }

    const response = await axios.put(`${API_URL}${ORDER_PRODUCTS_URI}/${orderProductId}`, {
        data: {
            Order: [orderId]
        }
    }, config)

    return response.data
}

// Submit Order
const submitOrder = async (data) => {

    const user = JSON.parse(localStorage.getItem('radiantUser'))

    const token = user?.jwt

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": 'application/json'
        }
    }

    const response = await axios.post(`${API_URL}${ORDERS_URI}`, {data: data}, config)        

    return response
}

const getDeliveryModes = async () => {

    const response = await axios.get(`${API_URL}${DELIVERY_MODES_URI}`)

    return response.data
}

const getPaymentModes = async () => {

    const response = await axios.get(`${API_URL}${PAYMENT_MODES_URI}`)

    return response.data
}

const ordersService = {
    submitOrder,
    createOrderProducts,
    updateOrderProduct,
    getDeliveryModes,
    getPaymentModes
}

export default ordersService