import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const CAROUSEL_CONTENT_URI = '/carousel-contents?populate=*'

// Retrieve carousel content
const getAllCarouselContent = async () => {

    const response = await axios.get(`${API_URL}${CAROUSEL_CONTENT_URI}`)
    
    return response.data

}

const homePageService = {
    getAllCarouselContent
}

export default homePageService