import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const REGISTER_URI = '/auth/local/register'
const LOGIN_URI = '/auth/local'
const USERS_URI = '/users/me?populate=UserNotifications'

// Login user
const login = async (userData) => {

    const config = {
        headers: {
            "Content-Type": 'application/json'
        }
    }

    const response = await axios.post(`${API_URL}${LOGIN_URI}`, JSON.stringify(userData), config)

    if (response.data) {
        // Store the token
        const token = response.data.jwt

        localStorage.setItem('radiantUser', JSON.stringify(response.data))

        const userRequestConfig = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        // Fetch the user data including notifications
        const userResponse = await axios.get(`${API_URL}${USERS_URI}`, userRequestConfig)

        if (userResponse) {
            // Handle the user data including notifications
            const notifications = userResponse?.data?.UserNotifications
            localStorage.setItem('radiantUserNotifications', JSON.stringify(notifications))
        } else {
            console.error('Error fetching data')
        }
    }

    return response.data

}

const register = async (userData) => {

    const config = {
        headers: {
            "Content-Type": 'application/json'
        }
    }

    const response = await axios.post(`${API_URL}${REGISTER_URI}`, JSON.stringify(userData), config)

    if (response.data) {
        // Store the token
        const token = response?.jwt

        localStorage.setItem('radiantUser', JSON.stringify(response.data))

        const userRequestConfig = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const userResponse = await axios.get(`${API_URL}${USERS_URI}`, userRequestConfig)

        if (userResponse) {
            // Handle the user data including notifications
            const notifications = userResponse?.data?.UserNotifications
            localStorage.setItem('radiantUserNotifications', JSON.stringify(notifications))
        } else {
            console.error('Error fetching data')
        }
    }

    return response.data
}

const getNotificationsPeriodically = async () => {

    const user = JSON.parse(localStorage.getItem('radiantUser'))

    const token = user?.jwt

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }

    // Fetch the user data with notifications
    const response = await axios.get(`${API_URL}${USERS_URI}`, config)

    if (response) {
        // Handle the user data including notifications
        const notifications = response?.data?.UserNotifications
        localStorage.setItem('radiantUserNotifications', JSON.stringify(notifications))
    } else {
        console.error('Error fetching data')
    }

    return response?.data?.UserNotifications
}

const logout = async () => {
    localStorage.removeItem('radiantUser')
    localStorage.removeItem('radiantUserNotifications')
}

const authService = {
    login,
    register,
    getNotificationsPeriodically,
    logout
}

export default authService