import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/Media.css'
import { getMediaTabs, getImages, getGIFs, getVideos, getAudios } from '../../features/media/mediaSlice'

const Media = () => {

    const dispatch = useDispatch()

    const [selectedMediaTab, setSelectedMediaTab] = useState('mediaTab1')

    let { mediaTabs, images, gifs, videos, audios, isSuccessMedia, isErrorMedia, isLoadingMedia } = useSelector((state) => state.media)

    useEffect(() => {
        dispatch(getMediaTabs())
        dispatch(getImages())
        dispatch(getGIFs())
        dispatch(getVideos())
        dispatch(getAudios())
    }, [])

    useEffect(() => {

        setTimeout(() => {

            const galleryItems = document.querySelectorAll('.gallery-item')
        
        const lightBoxContainer = document.createElement("div")
        const lightBoxContent = document.createElement("div")
        const lightBoxImg = document.createElement("img")
        const lightBoxPrev = document.createElement("div")
        const lightBoxNext = document.createElement("div")

        const leftIcon = document.createElement('img')
        leftIcon.src = 'https://res.cloudinary.com/dk8qdxlvl/image/upload/v1715704676/Radiant%20Farm/Icons/angle-left-solid_enaicr.svg'
        leftIcon.style = 'height:20px; background-color:white'
        lightBoxPrev.appendChild(leftIcon)

        const rightIcon = document.createElement('img')
        rightIcon.src = 'https://res.cloudinary.com/dk8qdxlvl/image/upload/v1715704676/Radiant%20Farm/Icons/angle-right-solid_v6zryb.svg'
        rightIcon.style = 'height:20px; background-color:white'
        lightBoxNext.appendChild(rightIcon)

        lightBoxContainer.classList.add("lightbox")
        lightBoxContent.classList.add("lightbox-content")
        lightBoxPrev.classList.add("lightbox-prev")
        lightBoxNext.classList.add("lightbox-next")

        lightBoxContainer.appendChild(lightBoxContent)
        lightBoxContent.appendChild(lightBoxImg)
        lightBoxContent.appendChild(lightBoxPrev)
        lightBoxContent.appendChild(lightBoxNext)

        document.body.appendChild(lightBoxContainer)

        let index = 1

        function showLightBox(n) {
            if (n > galleryItems.length) {
                index = 1
            } else if (n < 1) {
                index = galleryItems.length
            }

            const imageLocation = galleryItems[index - 1].children[0].getAttribute('src')
            lightBoxImg.setAttribute('src', imageLocation)
        }

        function currentImage() {
            lightBoxContainer.style.display = 'block'

            const imageIndex = parseInt(this.getAttribute('data-index'), 10)
            showLightBox((index = imageIndex))
        }

        galleryItems.forEach((item) => {
            item.addEventListener('click', currentImage)
        })

        function slideImage(n) {
            showLightBox((index += n))
        }

        function prevImage() {
            slideImage(-1)
        }

        function nextImage() {
            slideImage(1)
        }

        lightBoxPrev.addEventListener('click', prevImage)
        lightBoxNext.addEventListener('click', nextImage)

        function closeLightBox(event) {
            if (event.target === lightBoxContainer) {
                lightBoxContainer.style.display = 'none'
            }
        }

        lightBoxContainer.addEventListener('click', closeLightBox)

        return () => {
            document.body.removeChild(lightBoxContainer)
            galleryItems.forEach((item) => {
                item.removeEventListener('click', currentImage)
            })
        }
            
        }, 15000);

    }, [])

    const handleMediaTabChange = (event) => {
        setSelectedMediaTab(event.target.id)
    }

    if (isLoadingMedia) {
        return <p>Loading...</p>
    }

    if (isErrorMedia) {
        return <p>An error occurred...</p>
    }

    return (
        <>
            <div className="relative flex justify-center text-white sm:justify-start" id='media-page-title-element'>
                <div className="container pl-20">
                    <h1 className="text-5xl text-dark-olive">
                        Media.
                    </h1>
                </div>
            </div>
            <div className='container'>
                <section className="media-tabs-wrapper">
                    <div className="media-tabs-container">
                        <div className="media-tabs-block">
                            <div className="media-tabs">
                                <input 
                                    type="radio" 
                                    name="media-tabs" 
                                    id="mediaTab1" 
                                    checked={selectedMediaTab === 'mediaTab1'} 
                                    onChange={handleMediaTabChange}
                                />
                                <label htmlFor="mediaTab1">
                                    <h2 className="text-2xl font-normal">
                                        Gallery
                                    </h2>
                                </label>
                                <div className="media-tab text-left">
                                    {/* Image Gallery Goes Here */}

                                    <section className="overflow-x-hidden overflow-auto text-gray-700 h-140" id='media-scroll'>
                                        <div className="container md:px-5 py-2 md:mx-auto">
                                            <div className="flex flex-wrap -m-1 md:-m-2">
                                                {
                                                    images?.data?.length > 0 ? (
                                                        images?.data?.map((image, index) => {
                                                            return (
                                                                <div key={image.id} className={`flex flex-wrap w-full ${index % 2 === 0 ? 'md:w-1/2' : 'md:w-1/2'}`}>
                                                                    <div className={`w-${index % 3 === 0 ? 'full' : '1/2'} p-1 md:p-2 gallery-item cursor-pointer`} data-index={index + 1}>
                                                                        <img 
                                                                            src={image?.attributes?.Image?.data !== null ? image?.attributes?.Image?.data[0]?.attributes?.url : ''} 
                                                                            alt="Image" 
                                                                            className="block object-cover object-center w-full h-full rounded-lg"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </section>

                                </div>

                                <input 
                                    type="radio" 
                                    name="media-tabs" 
                                    id="mediaTab2"  
                                    checked={selectedMediaTab === 'mediaTab2'} 
                                    onChange={handleMediaTabChange}
                                />
                                <label htmlFor="mediaTab2">
                                    <h2 className="text-2xl font-normal">
                                        GIFs
                                    </h2>
                                </label>
                                <div className="media-tab text-left">
                                    {/* GIFs Go Here */}
                                    <section className="overflow-auto text-gray-700 h-140" id='media-scroll'>
                                        <div className="container px-5 py-2 mx-auto">
                                            <div className="flex flex-wrap -m-1 md:-m-2">
                                                {
                                                    gifs?.data?.length > 0 ? (
                                                        gifs?.data?.map((gif, index) => {
                                                            return (
                                                                <div key={gif.id} className={`flex flex-wrap w-full ${index % 2 === 0 ? 'md:w-1/2' : 'md:w-1/2'}`}>
                                                                    <div className={`w-${index % 3 === 0 ? 'full' : '1/2'} p-1 md:p-2 gallery-item cursor-pointer`} data-index={index + 1}>
                                                                        <img 
                                                                            src={gif?.attributes?.Gif?.data !== null ? gif?.attributes?.Gif?.data[0]?.attributes?.url : ''} 
                                                                            alt="Image" 
                                                                            className="block object-cover object-center w-full h-full rounded-lg"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <input 
                                    type="radio" 
                                    name="media-tabs" 
                                    id="mediaTab3"  
                                    checked={selectedMediaTab === 'mediaTab3'} 
                                    onChange={handleMediaTabChange}
                                />
                                <label htmlFor="mediaTab3">
                                    <h2 className="text-2xl font-normal">
                                        Videos
                                    </h2>
                                </label>
                                <div className="media-tab text-left">
                                    {/* Videos Go Here */}
                                    
                                    <section className="overflow-x-hidden overflow-auto text-gray-700 h-140" id='media-scroll'>
                                        <div className="container px-5 py-2 mx-auto">
                                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                                                {
                                                    videos?.data?.length > 0 ? (
                                                        videos?.data?.map((video, index) => {
                                                            return (
                                                                <div class="rounded iframe-wrapper" key={video?.id}>
                                                                    <div 
                                                                        dangerouslySetInnerHTML={{ __html: video?.attributes?.VideoLink }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <input 
                                    type="radio" 
                                    name="media-tabs" 
                                    id="mediaTab4"  
                                    checked={selectedMediaTab === 'mediaTab4'} 
                                    onChange={handleMediaTabChange}
                                />
                                <label htmlFor="mediaTab4">
                                    <h2 className="text-2xl font-normal">
                                        Audio
                                    </h2>
                                </label>
                                <div className="media-tab text-left">
                                    {/* Audios Go Here */}
                                    
                                    <section className="overflow-x-hidden overflow-auto text-gray-700 h-140" id="media-scroll">
                                        <div className="container px-5 py-2 mx-auto">
                                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                                                {
                                                    audios?.data?.length > 0 ? (
                                                        audios?.data?.map((audio, index) => {
                                                            return (
                                                                <div class="rounded iframe-wrapper" key={audio?.id}>
                                                                    <div 
                                                                        dangerouslySetInnerHTML={{ __html: audio?.attributes?.AudioLink }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Media