import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const BLOG_CATEGORIES_URI = '/blog-categories?populate=*'
const BLOG_ARTICLES_URI = '/blog-posts'
const BLOG_AUTHORS_URI = '/blog-authors/'
const EVENTS_URI = '/events?populate=*'

const getBlogCategories = async () => {

    const response = await axios.get(`${API_URL}${BLOG_CATEGORIES_URI}`)

    return response.data
}

// const getBlogArticles = async (categoryId) => {

//     const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}?filters=[BlogCategory][$eq]=<${categoryId}>`)

//     return response.data
// }

const getBlogArticles = async () => {

    const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}?populate=*`)

    return response.data
}

const getEvents = async () => {
    
    const response = await axios.get(`${API_URL}${EVENTS_URI}`)

    return response.data
}

const getBlogArticle = async (articleId) => {

    const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}/${articleId}?populate=*`)

    return response.data
}

const getBlogAuthor = async (authorId) => {

    const response = await axios.get(`${API_URL}${BLOG_AUTHORS_URI}${authorId}?populate=*`)

    return response.data
}

const getRecentResourcesArticles = async () => {

    const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}?populate=*&filters[BlogCategory][Category][$eq]=Resources&pagination[limit]=3&sort[0]=createdAt:desc`)

    return response.data
}

const getRecentAgribusinessArticles = async () => {

    const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}?populate=*&filters[BlogCategory][Category][$eq]=AgriBusiness&pagination[limit]=3&sort[0]=createdAt:desc`)

    return response.data
}

const getRecentEvents = async () => {

    const response = await axios.get(`${API_URL}${EVENTS_URI}?populate=*&pagination[limit]=3&sort[0]=createdAt:desc`)

    return response.data
}

const getUpcomingEvents = async () => {

    const response = await axios.get(`${API_URL}${EVENTS_URI}?filters[Status][$eq]=Upcoming&pagination[limit]=3&sort[0]=createdAt:desc`)

    return response.data
}

const getRecentNewsletterArticles = async () => {

    const response = await axios.get(`${API_URL}${BLOG_ARTICLES_URI}?populate=*&filters[BlogCategory][Category][$eq]=Newsletters&pagination[limit]=3&sort[0]=createdAt:desc`)

    return response.data
}

const blogService = {
    getBlogCategories,
    getBlogArticles,
    getBlogAuthor,
    getEvents,
    getBlogArticle,
    getRecentResourcesArticles,
    getRecentAgribusinessArticles,
    getRecentEvents,
    getUpcomingEvents,
    getRecentNewsletterArticles
}

export default blogService