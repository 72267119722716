import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import blogService from './blogService'

const initialState = {
    blogCategories: [],
    blogArticles: [],
    events: [],
    recentResourcesArticles: [],
    recentAgribusinessArticles: [],
    recentEvents: [],
    recentNewsletterArticles: [],
    upcomingEvents: [],
    blogArticle: null,
    blogAuthor: null,
    isErrorBlog: false,
    isSuccessBlog: false,
    isLoadingBlog: false,
    message: ''
}

// Get Blog Categories
export const getBlogCategories = createAsyncThunk('blog/categories', async (_, thunkAPI) => {
    try {
        
        return await blogService.getBlogCategories()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Blog Articles
// export const getBlogArticles = createAsyncThunk('blog/articles', async (categoryId, thunkAPI) => {
//     try {
        
//         return await blogService.getBlogArticles(categoryId)

//     } catch (error) {
        
//         const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

//         return thunkAPI.rejectWithValue(message)
//     }
// })

// Get Blog Articles
export const getBlogArticles = createAsyncThunk('blog/articles', async (_, thunkAPI) => {
    try {
        
        return await blogService.getBlogArticles()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Events
export const getEvents = createAsyncThunk('blog/events', async (_, thunkAPI) => {
    try {
        
        return await blogService.getEvents()

    } catch (error) {
        const message = (error.message && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Recent Newsletter Articles
export const getRecentNewsletterArticles = createAsyncThunk('blog/recent/newsletters', async (_, thunkAPI) => {
    try {
        
        return await blogService.getRecentNewsletterArticles()
        
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Recent Agribusiness Articles
export const getRecentAgribusinessArticles = createAsyncThunk('blog/recent/agribusiness', async (_, thunkAPI) => {
    try {
        
        return await blogService.getRecentAgribusinessArticles()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Recent Events
export const getRecentEvents = createAsyncThunk('blog/recent/events', async (_, thunkAPI) => {
    try {
        
        return await blogService.getRecentEvents()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Upcoming Events
export const getUpcomingEvents = createAsyncThunk('blog/upcoming/events', async (_, thunkAPI) => {
    try {
        
        return await blogService.getUpcomingEvents()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Recent Resources Articles
export const getRecentResourcesArticles = createAsyncThunk('blog/recent/resources', async (_, thunkAPI) => {
    try {
        
        return await blogService.getRecentResourcesArticles()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get a Blog Article
export const getBlogArticle = createAsyncThunk('blog/article', async (articleId, thunkAPI) => {
    try {
        
        return await blogService.getBlogArticle(articleId)

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get a Blog Author
export const getBlogAuthor = createAsyncThunk('blog/author', async (authorId, thunkAPI) => {
    try {
        
        return await blogService.getBlogAuthor(authorId)

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingBlog = false
            state.isErrorBlog = false
            state.isSuccessBlog = false
            state.message = ''
            state.blogCategories = []
            state.blogArticles = []
            state.events = []
            state.recentResourcesArticles = []
            state.recentAgribusinessArticles = []
            state.recentEvents = []
            state.recentNewsletterArticles = []
            state.upcomingEvents = []
            state.blogArticle = null
            state.blogAuthor = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBlogCategories.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getBlogCategories.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.blogCategories = action.payload
            })
            .addCase(getBlogCategories.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.blogCategories = []
            })
            .addCase(getBlogArticles.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getBlogArticles.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.blogArticles = action.payload
            })
            .addCase(getBlogArticles.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.blogArticles = []
            })
            .addCase(getEvents.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.events = action.payload
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.events = []
            })
            .addCase(getRecentNewsletterArticles.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getRecentNewsletterArticles.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.recentNewsletterArticles = action.payload
            })
            .addCase(getRecentNewsletterArticles.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.recentNewsletterArticles = []
            })
            .addCase(getRecentAgribusinessArticles.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getRecentAgribusinessArticles.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.recentAgribusinessArticles = action.payload
            })
            .addCase(getRecentAgribusinessArticles.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.recentAgribusinessArticles = []
            })
            .addCase(getRecentEvents.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getRecentEvents.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.recentEvents = action.payload
            })
            .addCase(getRecentEvents.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.recentEvents = []
            })
            .addCase(getUpcomingEvents.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getUpcomingEvents.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.upcomingEvents = action.payload
            })
            .addCase(getUpcomingEvents.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.upcomingEvents = []
            })
            .addCase(getRecentResourcesArticles.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getRecentResourcesArticles.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.recentResourcesArticles = action.payload
            })
            .addCase(getRecentResourcesArticles.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.recentResourcesArticles = []
            })
            .addCase(getBlogArticle.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getBlogArticle.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.blogArticle = action.payload
            })
            .addCase(getBlogArticle.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.blogArticle = null
            })
            .addCase(getBlogAuthor.pending, (state) => {
                state.isLoadingBlog = true
            })
            .addCase(getBlogAuthor.fulfilled, (state, action) => {
                state.isLoadingBlog = false
                state.isSuccessBlog = true
                state.blogAuthor = action.payload
            })
            .addCase(getBlogAuthor.rejected, (state, action) => {
                state.isLoadingBlog = false
                state.isErrorBlog = true
                state.message = action.payload
                state.blogAuthor = null
            })
    }
})

export const { reset } = blogSlice.actions
export default blogSlice.reducer