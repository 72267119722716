import React, { useState, useEffect } from 'react'
import './styles/Footer.css'
import { FaFacebook,FaWhatsapp, FaYoutube, FaX, FaTwitter, FaSquareXTwitter, FaEnvelope, FaPhone, FaLocationDot, FaRegEnvelope, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessHours, getCookiePolicy, getOfficeAddresses, getPrivacyPolicy, getSocialMediaHandles, getTermsAndConditions } from '../../features/footer/footerSlice'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import CookiePolicy from '../CookiePolicy/CookiePolicy'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import { signUpForNewsletter } from '../../features/newsletters/newsletterSlice'
import Swal from 'sweetalert2'
import './styles/Footer.css'

const FormattedText = ({ text }) => {
    return (
        <div dangerouslySetInnerHTML={{__html: text}} />
    )
}

const Footer = () => {

    const dispatch = useDispatch()

    const [footerSubscriberEmail, setFooterSubscriberEmail] = useState('')

    let { officeAddresses, businessHours, socialMediaHandles, privacyPolicy, cookiePolicy, termsAndConditions, isLoadingFooter, isErrorFooter } = useSelector((state) => state.footer)
    let { signedUp, isSuccessNewsletter } = useSelector((state) => state.newsletter)

    const orderedOperatingHours = ["Business Days", "Saturday", "Sunday"]

    useEffect(() => {
        dispatch(getOfficeAddresses())
        dispatch(getSocialMediaHandles())
        dispatch(getPrivacyPolicy())
        dispatch(getCookiePolicy())
        dispatch(getTermsAndConditions())
    }, [])

    const togglePopup = (e) => {
        const popup = document.getElementById('popupImage')
        if (popup.classList.contains('hidden')) {
            popup.classList.remove('hidden')
        } else {
            popup.classList.add('hidden')
        }
    }

    const handlePrivacyPolicyClick = async (e) => {

        await dispatch(getPrivacyPolicy())

        document.getElementById('privacy-policy-panel').classList.toggle('hidden')
    }

    const handleCookiePolicyClick = async (e) => {

        await dispatch(getCookiePolicy())

        document.getElementById('cookie-policy-panel').classList.toggle('hidden')
    }

    const handleTermsAndConditionsClick = async (e) => {

        await dispatch(getTermsAndConditions())

        document.getElementById('terms-conditions-panel').classList.toggle('hidden')
    }

    const handleSubscriberEmailChange = (e) => {
        setFooterSubscriberEmail(e.target.value)
    }

    const handleFooterNewsletterSubscriptionSubmit = (e) => {
        e.preventDefault()

        if (!footerSubscriberEmail) {
            Swal.fire({
                title: "Missing Email",
                text: "Please provide an email to successfully subscribe to our newsletter.",
                timer: 5000,
                timerProgressBar: true,
                icon: 'warning',
                showCancelButton: true,
            })

            return
        }

        try {
            
            const data = {
                SubscriberEmail: footerSubscriberEmail
            }

            dispatch(signUpForNewsletter(data))

            Swal.fire({
                icon: 'success',
                title: "Sign Up Successful",
                text: "Thanks for signing up for the Radiant Newsletter. Look out for newsletter notifications in your email and on our website.",
                timer: 6000,
                timerProgressBar: true,
                showCancelButton: false,
            }).then(() => {
                let reload = window.location.reload
            })

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: "An Error Occurred",
                text: "Please try to sign up for our newsletter once again.",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: true,
            })

            return
        }
    }

    return (
        <>
            <div className="max-w-screen-xl z-50 px-4 pt-16 pb-6 mx-auto sm:px-6 lg:px-8">
                <div className="relative justify-center text-white pt-12 sm:justify-start max-[480px]:text-center">
                    <h1 className='text-5xl'>Connect with Us.</h1>
                
                    <img src={require('../../resources/logos/emblem.png')} className="absolute w-10 h-10 top-8 right-0 max-[480px]:hidden" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 mt-10">
                    <div className="text-center md:text-left lg:text-left xl:text-left 2xl:text-left">
                        <h4 className="text-lg font-medium text-radiant-yellow">Where We Work</h4>

                        <p className="font-bold mt-5 text-white mb-5">
                            {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('farm') || 
                                            address?.attributes?.Office?.toLowerCase().includes('head') ? (
                                                <>
                                                    {address?.attributes?.Office}
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>
                        <FaLocationDot className='text-radiant-yellow mr-2 mt-1 max-[480px]:inline-block hidden' />
                        <p className='text-sm text-white md:flex items-center mt-2'>
                            <FaLocationDot className='text-radiant-yellow mr-2 -mt-5 max-[480px]:hidden' />
                            {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('farm') || 
                                            address?.attributes?.Office?.toLowerCase().includes('head') ? (
                                                <>
                                                    <FormattedText text={address?.attributes?.Address.split('\n').join('<br />')} />
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>

                        <h4 className="text-lg font-medium text-radiant-yellow mt-5">Get In Touch</h4>

                        <FaPhone className='text-radiant-yellow mr-2 mt-8 max-[480px]:inline-block hidden' />
                        <p className='mt-3 text-sm text-white md:flex flex-column'>
                            <FaPhone className='text-radiant-yellow mr-2 mt-1 max-[480px]:hidden' /> 
                            {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('farm') || 
                                            address?.attributes?.Office?.toLowerCase().includes('head') ? (
                                                <>
                                                    <FormattedText text={address?.attributes?.Telephones.split('\n').join('<br />')} />
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>

                        <FaEnvelope className='text-radiant-yellow mr-2 mt-8 max-[480px]:inline-block hidden' />
                        <p className='text-sm text-white md:flex mt-2'>
                            <FaEnvelope className='text-radiant-yellow mr-2 mt-1 max-[480px]:hidden' /> <a href="mailto:kampala@radiantfarmug.com">
                                {
                                    officeAddresses?.data?.length > 0 ? (
                                        officeAddresses?.data?.map((address) => {
                                            return (
                                                address?.attributes?.Office?.toLowerCase().includes('kampala') ? (
                                                    <>
                                                        {address?.attributes?.Email}
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </a>
                        </p>
                    </div>

                    <div className="text-center md:text-left lg:text-left xl:text-left 2xl:text-left">
                        <p className="font-bold mt-12 text-white mb-5">
                            {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('kampala') ? (
                                                <>
                                                    {address?.attributes?.Office}
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>
                        <FaLocationDot className='text-radiant-yellow md:mr-2 mt-1 max-[480px]:inline-block hidden' />
                        <p className='text-sm text-white md:flex mt-2 max-[480px]:w-16 max-[480px]:mx-auto'>
                            <FaLocationDot className='text-radiant-yellow md:mr-2 mt-1 max-[480px]:hidden' />
                            {
                                    officeAddresses?.data?.length > 0 ? (
                                        officeAddresses?.data?.map((address) => {
                                            return (
                                                address?.attributes?.Office?.toLowerCase().includes('kampala') ? (
                                                    <>
                                                        <FormattedText text={address?.attributes?.Address.split('\n').join('<br />')} />
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                        </p>
                    </div>

                    <div className="text-center md:text-left lg:text-left xl:text-left 2xl:text-left mt-1">

                        <h4 className="text-lg font-medium text-radiant-yellow mb-5">
                            Operating Hours
                        </h4>

                        <p className="font-bold text-white">
                        {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('farm') || 
                                            address?.attributes?.Office?.toLowerCase().includes('head') ? (
                                                <>
                                                    {address?.attributes?.Office}
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>
                        {
                            officeAddresses?.data?.length > 0 ? (
                                officeAddresses?.data?.map((address) => {
                                    if (
                                        address?.attributes?.Office?.toLowerCase().includes('farm') || 
                                        address?.attributes?.Office?.toLowerCase().includes('head') 
                                    ) {

                                        const sortedHours = [...address?.attributes?.operating_hours?.data]?.sort(
                                            (a, b) => 
                                            orderedOperatingHours.indexOf(a?.attributes?.Title) - 
                                            orderedOperatingHours.indexOf(b?.attributes?.Title)
                                        )

                                        return (
                                            <div key={address?.id}>
                                                {
                                                        sortedHours.map((hours) => (
                                                            <p className="text-white mt-2 text-sm" key={hours?.id}>
                                                                <span className="text-radiant-yellow font-bold mr">
                                                                    {
                                                                        hours?.attributes?.Title
                                                                    }:
                                                                </span>&nbsp;
                                                                {
                                                                    hours?.attributes?.Details
                                                                }
                                                            </p>
                                                        ))
                                                }
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            ) : (
                                <></>
                            )
                        }

                        {/* <p className='text-white mt-2 text-sm'>
                            <span className="text-radiant-yellow font-bold mr-2">Business Days:</span>
                            8am - 5pm
                        </p>
                        <p className='text-white text-sm'>
                            <span className="text-radiant-yellow font-bold mr-2">Weekends:</span>
                            8am - Noon
                        </p>
                        <p className='text-white text-sm'>
                            <span className="text-radiant-yellow font-bold mr-2">Sunday:</span>
                            Closed
                        </p> */}

                        <p className="font-bold text-white mt-4">
                        {
                                officeAddresses?.data?.length > 0 ? (
                                    officeAddresses?.data?.map((address) => {
                                        return (
                                            address?.attributes?.Office?.toLowerCase().includes('kampala') ? (
                                                <>
                                                    {address?.attributes?.Office}
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        )
                                    })
                                ) : (
                                    <></>
                                )
                            }
                        </p>

                        {
                            officeAddresses?.data?.length > 0 ? (
                                officeAddresses?.data?.map((address) => {
                                    if (
                                        address?.attributes?.Office?.toLowerCase().includes('kampala')
                                    ) {

                                        const sortedHours = [...address?.attributes?.operating_hours?.data]?.sort(
                                            (a, b) => 
                                            orderedOperatingHours.indexOf(a?.attributes?.Title) - 
                                            orderedOperatingHours.indexOf(b?.attributes?.Title)
                                        )

                                        return (
                                            <div key={address?.id}>
                                                {
                                                        sortedHours.map((hours) => (
                                                            <p className="text-white mt-2 text-sm" key={hours?.id}>
                                                                <span className="text-radiant-yellow font-bold mr">
                                                                    {
                                                                        hours?.attributes?.Title
                                                                    }:
                                                                </span>&nbsp;
                                                                {
                                                                    hours?.attributes?.Details
                                                                }
                                                            </p>
                                                        ))
                                                }
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            ) : (
                                <></>
                            )
                        }
                        
                    </div>
                    <div className='text-center md:text-left lg:text-left xl:text-left 2xl:text-left'>

                        <div className="w-full">
                            <h4 className='text-lg font-medium text-md text-radiant-yellow max-[480px]:text-center'>Read Our Newsletter</h4>
                            <div className="flex flex-col justify-center sm:flex-row gap-3 mt-3 w-full">
                                <form className='' onSubmit={handleFooterNewsletterSubscriptionSubmit}>
                                    <input 
                                        type="text" 
                                        name='email'
                                        placeholder='Email Address' 
                                        className='sm:w-3/4 h-9 -ml-6 text-gray-900 border border-solid border-gray-300 rounded-lg shadow' onChange={handleSubscriberEmailChange}
                                    />

                                    <button type="submit" className='sm:w-1/4 ml-2 pl-1 pr-1 h-9 text-sm text-white bg-radiant-yellow rounded-lg shadow transition-all duration-300 ease-in-out hover:bg-white'>
                                        Subscribe
                                    </button>
                                </form>

                            </div>
                        </div>
                        
                        <nav class="md:mt-8 max-[480px]:mt-12 max-[480px]:text-center">
                            <ul class="text-sm">
                                <li className='max-[480px]:text-xl'>
                                    <a class="text-white transition hover:text-white/75" href="#!" onClick={(e) => handlePrivacyPolicyClick(e)}>
                                        Privacy Policy
                                    </a>
                                </li>

                                <li className='mt-2 max-[480px]:text-xl'>
                                    <a class="text-white transition hover:text-white/75" href="#!" onClick={(e) => handleCookiePolicyClick(e)}>
                                        Cookie Policy
                                    </a>
                                </li>

                                <li className='mt-2 max-[480px]:text-xl'>
                                    <a class="text-white transition hover:text-white/75" href="#!" onClick={(e) => handleTermsAndConditionsClick(e)}>
                                        Terms &amp; Conditions
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <h3 className='text-2xl mt-10 text-white text-bold max-[480px]:text-center'>We are Social</h3>
                        
                        <ul className="flex justify-center gap-6 mt-4 md:gap-8 sm:justify-start">
                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'facebook' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaFacebook className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>

                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'whatsapp' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaWhatsapp className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>

                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'youtube' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaYoutube className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>

                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'x' ||
                                                handle?.attributes?.Platform.toLowerCase() === 'twitter' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaSquareXTwitter className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>
                        </ul>
                        <ul className="flex justify-center gap-6 mt-4 md:gap-8 sm:justify-start">
                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'linkedin' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaLinkedin className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>
                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'instagram' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaInstagram className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>
                            <li>
                                {
                                    socialMediaHandles?.data?.length > 0 ? (
                                        socialMediaHandles?.data?.map((handle) => {
                                            return (
                                                handle?.attributes?.Platform.toLowerCase() === 'tiktok' ? (
                                                    <a 
                                                        href={`${handle?.attributes?.URL}`} 
                                                        rel='noopener noreferrer' 
                                                        target='_blank' 
                                                        className='text-white transition hover:text-radiant-yellow'
                                                    >
                                                        <span className="sr-only">{ handle?.attributes?.Platform }</span>
                                                        <FaTiktok className='text-2xl text-radiant-yellow' />
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <p className="text-sm text-white mt-3 mb-3 max-[480px]:text-center">
                    <span className="block sm:inline"><span className="text-radiant-yellow">&copy;</span> 2024 Radiant Farm® Uganda Limited. All rights reserved.</span>
                </p>

                <div className="pt-6 md:pl-4 mt-8 w-full md:gap-32 border-t border-radiant-yellow flex flex-wrap items-center">
                    <img className='md:w-40 md:h-30 max-[480px]:w-full max-[480px]:mx-auto' src={require('../../resources/logos/RF PRIMARY LOGO PURE WHITE with slogan copy.png')} alt="" />
                    <img className='md:w-38 md:h-12 max-[480px]:w-full max-[480px]:mt-8 max-[480px]:mx-auto' src={require('../../resources/logos/RADIGRO LOGO IN PURE WHITE copy.png')} alt='' />
                    <img className='md:w-40 md:h-30 max-[480px]:w-full max-[480px]:mt-8 max-[480px]:mx-auto' src={require("../../resources/logos/RADIANT FARMERS' WORKSHOP LOGO IN PURE WHITE copy.png")} alt="" />
                    {/* <div className='max-[480px]:mx-auto max-[480px]:mt-6'>
                        <p className="min-[481px]:hidden max-[480px]:text-center text-xl text-radiant-yellow">Powered By</p>
                        <p className='text-radiant-yellow flex'>
                            <span className=' max-[480px]:hidden'>Powered By</span> <img src={require('../../img/AGROSPHERE_LOGO.png')} className='w-60 h-30 ml-4' alt="" />
                        </p>
                    </div> */}
                    <div className="relative inline-block">
                        <p className="text-radiant-yellow flex">
                            <span className='max-[480px]:hidden'>
                                Powered By
                            </span>
                            <img 
                                src={require('../../img/AGROSPHERE_LOGO.png')} 
                                className='w-60 h-30 ml-4 cursor-pointer'
                                alt="Agrosphere Logo" 
                                onMouseOver={togglePopup} 
                                onMouseOut={togglePopup} 
                                onClick={togglePopup}
                            />
                        </p>
                        <div 
                            id="popupImage"
                            className='absolute left-1/2 transform -translate-x-1/2 hidden z-50 popup-above h-96 w-72'
                        >
                            <img 
                                src="https://res.cloudinary.com/dk8qdxlvl/image/upload/v1720868901/AgroSphere/Untitled_design_cjkzru.gif" 
                                className='w-full h-full'
                                alt="Popup Image" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyPolicy />
            <CookiePolicy />
            <TermsAndConditions />
        </>
    )
}

export default Footer