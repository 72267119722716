import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ordersService from './ordersService'

const initialState = {
    orderSubmitted: false,
    orderProductSubmitted: false,
    deliveryModes: [],
    orderProductIds: [],
    paymentModes: [],
    orderSubmitResponse: null,
    isErrorOrders: false,
    isSuccessOrders: false,
    isLoadingOrders: false,
    message: ''
}

// Submit Order Products
export const submitOrderProducts = createAsyncThunk('orders/products/submit', async (data, thunkAPI) => {
    try {

        return await ordersService.createOrderProducts(data)

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Submit Order
export const submitOrder = createAsyncThunk('orders/submit', async (data, thunkAPI) => {
    try {
        
        return await ordersService.submitOrder(data)

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        
        return thunkAPI.rejectWithValue(message)
    }
})

// Get Delivery Modes
export const getDeliveryModes = createAsyncThunk('orders/deliveryModes', async (_, thunkAPI) => {
    try {
        
        return await ordersService.getDeliveryModes()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Payment Modes
export const getPaymentModes = createAsyncThunk('orders/paymentModes', async (_, thunkAPI) => {
    try {
        
        return await ordersService.getPaymentModes()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingOrders = false
            state.isErrorOrders = false
            state.isSuccessOrders = false
            state.message = ''
            state.orderSubmitted = false
            state.orderProductSubmitted = false
            state.deliveryModes = []
            state.orderProductIds = []
            state.paymentModes = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitOrderProducts.pending, (state) => {
                state.isLoadingOrders = true
            })
            .addCase(submitOrderProducts.fulfilled, (state, action) => {
                state.isLoadingOrders = false
                state.isSuccessOrders = true
                state.orderProductIds = action.payload
                state.orderProductSubmitted = true
            })
            .addCase(submitOrderProducts.rejected, (state, action) => {
                state.isLoadingOrders = false
                state.isErrorOrders = true
                state.message = action.payload
            })
            .addCase(submitOrder.pending, (state) => {
                state.isLoadingOrders = true
            })
            .addCase(submitOrder.fulfilled, (state, action) => {
                state.isLoadingOrders = false
                state.isSuccessOrders = true
                state.orderSubmitted = true
                state.orderSubmitResponse = action.payload
            })
            .addCase(submitOrder.rejected, (state, action) => {
                state.isLoadingOrders = false
                state.isErrorOrders = true
                state.message = action.payload
                state.orderSubmitted = false
            })
            .addCase(getDeliveryModes.pending, (state) => {
                state.isLoadingOrders = true
            })
            .addCase(getDeliveryModes.fulfilled, (state, action) => {
                state.isLoadingOrders = false
                state.isSuccessOrders = true
                state.deliveryModes = action.payload
            })
            .addCase(getDeliveryModes.rejected, (state, action) => {
                state.isLoadingOrders = false
                state.isErrorOrders = true
                state.message = action.payload
                state.deliveryModes = []
            })
            .addCase(getPaymentModes.pending, (state) => {
                state.isLoadingOrders = true
            })
            .addCase(getPaymentModes.fulfilled, (state, action) => {
                state.isLoadingOrders = false
                state.isSuccessOrders = true
                state.paymentModes = action.payload
            })
            .addCase(getPaymentModes.rejected, (state, action) => {
                state.isLoadingOrders = false
                state.isErrorOrders = true
                state.message = action.payload
                state.paymentModes = []
            })
    }
})

export const { reset } = ordersSlice.actions
export default ordersSlice.reducer