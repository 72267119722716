import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const SHOP_CATEGORIES_URI = '/shop-categories?populate=*'
const PRODUCTS_URI = '/products?populate=*'

// Get Shop Categories
const getShopCategories = async () => {

    const response = await axios.get(`${API_URL}${SHOP_CATEGORIES_URI}`)

    return response.data
}

const getProducts = async () => {

    const response = await axios.get(`${API_URL}${PRODUCTS_URI}`)

    return response.data
}

const shopService = {
    getShopCategories,
    getProducts
}

export default shopService