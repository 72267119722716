import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import footerService from './footerService'

const initialState = {
    officeAddresses: [],
    businessHours: [],
    socialMediaHandles: [],
    privacyPolicy: null,
    cookiePolicy: null,
    termsAndConditions: null,
    isErrorFooter: false,
    isSuccessFooter: false,
    isLoadingFooter: false,
    message: ''
}

// Get Office Addresses
export const getOfficeAddresses = createAsyncThunk('footer/officeAddresses', async (_, thunkAPI) => {
    try {
        
        return await footerService.getOfficeAddresses()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Business Hours
export const getBusinessHours = createAsyncThunk('footer/businessHours', async (_, thunkAPI) => {
    try {
        
        return await footerService.getBusinessHours()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Privacy Policy
export const getPrivacyPolicy = createAsyncThunk('footer/privacyPolicy', async (_, thunkAPI) => {
    try {
        
        return await footerService.getPrivacyPolicy()
        
    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Cookie Policy
export const getCookiePolicy = createAsyncThunk('footer/cookiePolicy', async (_, thunkAPI) => {
    try {
        
        return await footerService.getCookiePolicy()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Terms and Conditions
export const getTermsAndConditions = createAsyncThunk('footer/termsAndConditions', async (_, thunkAPI) => {
    try {
        
        return await footerService.getTermsAndConditions()

    } catch (error) {
        
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Social Media Handles
export const getSocialMediaHandles = createAsyncThunk('footer/socialMediaHandles', async (_, thunkAPI) => {
    try {
        
        return await footerService.getSocialMediaHandles()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingFooter = false
            state.isErrorFooter = false
            state.isSuccessFooter = false
            state.message = ''
            state.officeAddresses = []
            state.businessHours = []
            state.socialMediaHandles = []
            state.privacyPolicy = null
            state.cookiePolicy = null
            state.termsAndConditions = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOfficeAddresses.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getOfficeAddresses.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.officeAddresses = action.payload
            })
            .addCase(getOfficeAddresses.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.officeAddresses = []
            })
            .addCase(getBusinessHours.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getBusinessHours.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.businessHours = action.payload
            })
            .addCase(getBusinessHours.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.businessHours = []
            })
            .addCase(getPrivacyPolicy.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.privacyPolicy = action.payload
            })
            .addCase(getPrivacyPolicy.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.privacyPolicy = null
            })
            .addCase(getCookiePolicy.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getCookiePolicy.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.cookiePolicy = action.payload
            })
            .addCase(getCookiePolicy.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.cookiePolicy = null
            })
            .addCase(getTermsAndConditions.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getTermsAndConditions.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.termsAndConditions = action.payload
            })
            .addCase(getTermsAndConditions.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.termsAndConditions = null
            })
            .addCase(getSocialMediaHandles.pending, (state) => {
                state.isLoadingFooter = true
            })
            .addCase(getSocialMediaHandles.fulfilled, (state, action) => {
                state.isLoadingFooter = false
                state.isSuccessFooter = true
                state.socialMediaHandles = action.payload
            })
            .addCase(getSocialMediaHandles.rejected, (state, action) => {
                state.isLoadingFooter = false
                state.isErrorFooter = true
                state.message = action.payload
                state.socialMediaHandles = []
            })
    }
})

export const { reset } = footerSlice.actions
export default footerSlice.reducer