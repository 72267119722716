import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { FaCircleXmark } from 'react-icons/fa6'
import './styles/TermsAndConditions.css'
import { useSelector } from 'react-redux'

const TermsAndConditions = () => {

    const [processedText, setProcessedText] = useState('');

    let { termsAndConditions, isLoadingFooter, isErrorFooter } = useSelector((state) => state.footer)

    useEffect(() => {
        if (termsAndConditions?.data?.attributes?.BodyText) {
            // Replace <br /> tags with \n\n to create two line breaks
            const textWithLineBreaks = termsAndConditions?.data?.attributes?.BodyText?.replace(/<br\s*\/?>/gi, '\n\n');
            setProcessedText(textWithLineBreaks);
        }
    }, [])

    const handleTermsAndConditionsClose = () => {
        document.getElementById('terms-conditions-panel').classList.toggle('hidden')
    }

    return (
        <>
            <div className="hidden fixed top-0 right-0 z-50 bg-gray-200 w-full overflow-y-auto policy-scroll" id='terms-conditions-panel'>
                <div className="flex -mt-64">
                    <div className='flex flex-wrap w-full sm:h-full md:h-full lg:h-full xl:full'>
                        <div className="relative flex justify-center text-white sm:justify-start">
                            <div className="container md:pl-20 pl-5 pr-5">
                                <h1 className="text-5xl text-dark-olive">
                                    Terms &amp; Conditions
                                </h1>
                                
                                <FaCircleXmark className='fixed top-10 md:right-20 right-10 size-10 text-dark-olive cursor-pointer text-radiant-lime-green' id='closeResourceArticle' onClick={handleTermsAndConditionsClose}/>
                            </div>
                        </div>
                        <div className="container mt-4">
                            <div className="mt-6">
                                <ReactMarkdown>
                                    {
                                        processedText
                                    }
                                </ReactMarkdown>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </>
    )
}

export default TermsAndConditions