import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const ABOUT_US_TABS_URI = '/about-us-tabs?populate=*'
const ABOUT_US_CONTENT_URI = '/about-us-contents?populate=*'
const COMPANY_VISION_URI = '/company-vision?populate=*'
const COMPANY_MISSION_URI = '/company-mission?populate=*'
const COMPANY_VALUES_URI = '/company-values?populate=*'
const TEAM_MEMBERS_URI = '/teams?populate=*'
const PARTNERS_URI = '/partners?populate=*'
const SERVICES_URI = '/services?populate=*'

// Get About Us Tabs
const getAboutUsTabs = async () => {

    const response = await axios.get(`${API_URL}${ABOUT_US_TABS_URI}`)

    return response.data
}

const getAboutUsContent = async () => {

    const response = await axios.get(`${API_URL}${ABOUT_US_CONTENT_URI}`)

    return response.data
}

const getCompanyVision = async () => {

    const response = await axios.get(`${API_URL}${COMPANY_VISION_URI}`)

    return response.data
}

const getCompanyMission = async () => {

    const response = await axios.get(`${API_URL}${COMPANY_MISSION_URI}`)

    return response.data
}

const getCompanyValues = async () => {

    const response = await axios.get(`${API_URL}${COMPANY_VALUES_URI}`)

    return response.data
}

const getTeamMembers = async () => {

    const response = await axios.get(`${API_URL}${TEAM_MEMBERS_URI}`)

    return response.data
}

const getPartners = async () => {

    const response = await axios.get(`${API_URL}${PARTNERS_URI}`)

    return response.data
}

const getServices = async () => {

    const response = await axios.get(`${API_URL}${SERVICES_URI}`)

    return response.data
}

const aboutUsPageService = {
    getAboutUsTabs,
    getAboutUsContent,
    getCompanyVision,
    getCompanyMission,
    getCompanyValues,
    getTeamMembers,
    getPartners,
    getServices
}

export default aboutUsPageService