import React, { useEffect, useState } from 'react'
import { FaBagShopping, FaBasketShopping, FaCircleXmark, FaMinus, FaPlus, FaXmark } from 'react-icons/fa6'
import './styles/ShoppingCart.css'
import Swal from 'sweetalert2'
import { getRegions } from '../../features/location/locationSlice'
import { getDeliveryModes } from '../../features/orders/ordersSlice'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout } from '../../features/auth/authSlice'

const ShoppingCart = ({ setCartItemCount, cartItemCount }) => {
    const dispatch = useDispatch()

    const { user, isError, isSuccess } = useSelector((state) => state.auth)

    const [deliveryMode, setDeliveryMode] = useState('')
    const [cartItems, setCartItems] = useState([])
    const [cartTotal, setCartTotal] = useState(0)

    const [loginFormData, setLoginFormData] = useState({
        identifier: '',
        loginPassword: ''
    })

    const { identifier, loginPassword } = loginFormData

    const handleLoginChange = (e) => {

        setLoginFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

    }

    useEffect(() => {
        dispatch(getRegions())
        dispatch(getDeliveryModes())
    }, [])

    useEffect(() => {

        const shoppingCart = JSON.parse(localStorage.getItem('cart'))

        if (shoppingCart && shoppingCart?.items?.length > 0) {
            setCartItems(shoppingCart.items)

            let totalAmount = 0

            shoppingCart.items.forEach((item) => {
                totalAmount += parseFloat(item?.attributes?.UnitPrice) * item.quantity

                setCartTotal(totalAmount)
            })
        }

    }, [cartItemCount])

    const handleCartClose = () => {

        document.getElementById('cart-panel').classList.toggle('hidden')

    }

    const handleCheckoutClick = () => {

        if (user === null) {
            Swal.fire({
                icon: 'warning',
                title: "Login/ Sign Up",
                text: "Please login/ sign up to continue with your checkout",
                timer: 7000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400"
            }).then((result) => {
                if (result.isConfirmed) {
                    // Show Login Popup Modal
                    toggleLoginModal()
                }
            })

            return
        }

        const cart = JSON.parse(localStorage.getItem('cart'))

        if (cart?.items?.length < 1) {
            Swal.fire({
                icon: 'warning',
                title: "Empty Shopping Cart",
                text: "Please add at least one item to your shopping cart",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400",
            })

            return
        }

        if (!deliveryMode) {
            Swal.fire({
                icon: 'warning',
                title: "You've not selected a delivery mode",
                text: "Please select whether you want to pick up your order or have it delivered to you",
                timer: 6000,
                timerProgressBar: true,
                showCancelButton: false,
                color: "#99b400",
            })

            return
        }

        const customerDeliveryMode = {
            deliveryMode: deliveryMode
        }

        const existingDeliveryMode = localStorage.getItem('customerDeliveryMode')

        if (existingDeliveryMode) {
            localStorage.removeItem('customerDeliveryMode')
            localStorage.setItem('customerDeliveryMode', JSON.stringify(customerDeliveryMode))
        } else {
            localStorage.setItem('customerDeliveryMode', JSON.stringify(customerDeliveryMode))
        }

        document.getElementById('checkout-panel').classList.toggle('hidden')

    }

    const handleQuantityIncrement = (product) => {

        const checkLocalStorage = localStorage.getItem('cart')

        if (checkLocalStorage) {
            
            const shoppingCart = JSON.parse(localStorage.getItem('cart'))
            let shoppingCartItems = shoppingCart.items

            shoppingCartItems = shoppingCartItems.map((item) => {
                if (item.id === product.id) {
                    item.quantity += 1
                }
                return item
            })

            shoppingCart.items = shoppingCartItems
            shoppingCart.quantity = shoppingCartItems.reduce((total, item) => total + item.quantity, 0)

            localStorage.setItem('cart', JSON.stringify(shoppingCart))

            setCartItems(shoppingCartItems)
            setCartItemCount(shoppingCart.quantity)

        }

    }

    const handleQuantityDecrement = (product) => {
        
        const checkLocalStorage = localStorage.getItem('cart')

        if (checkLocalStorage) {
            
            const shoppingCart = JSON.parse(localStorage.getItem('cart'))
            let shoppingCartItems = shoppingCart.items

            shoppingCartItems = shoppingCartItems.map((item) => {
                if (item.id === product.id && item.quantity > 1) {
                    item.quantity -= 1
                }
                return item
            })

            shoppingCart.items = shoppingCartItems
            shoppingCart.quantity = shoppingCartItems.reduce((total, item) => total + item.quantity, 0)

            localStorage.setItem('cart', JSON.stringify(shoppingCart))

            setCartItems(shoppingCartItems)
            setCartItemCount(shoppingCart.quantity)
            
        }

    }

    const handleProductRemovalFromCart = (product) => {

        const checkLocalStorage = localStorage.getItem('cart')

        if (checkLocalStorage) {
            
            const shoppingCart = JSON.parse(localStorage.getItem('cart'))
            let shoppingCartItems = shoppingCart.items

            // Filter out the product to be removed
            shoppingCartItems = shoppingCartItems.filter((item) => item.id !== product.id)

            // Update the shopping cart and overall quantity
            shoppingCart.items = shoppingCartItems
            shoppingCart.quantity = shoppingCartItems.reduce((total, item) => total + item.quantity, 0)

            // Save the updated cart to localStorage
            localStorage.setItem('cart', JSON.stringify(shoppingCart))

            setCartItems(shoppingCartItems)
            setCartItemCount(shoppingCart.quantity)
            
        }

    }

    const handleLogin = (e) => {
        e.preventDefault()

        try {
            
            const userData = {
                identifier,
                password: loginPassword
            }

            dispatch(login(userData))

            if (isSuccess) {
                Swal.fire({
                    icon: 'success',
                    title: "Logged In",
                    text: "Your login was successful",
                    timer: 5000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    color: "#99b400",
                })
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "An error occurred. Please try again",
                timer: 5000,
                timerProgressBar: true,
                showCancelButton: false,
                color: '#99b400'
            })
        }

    }

    const handleLogout = () => {
        dispatch(logout())

        
    }

    const toggleLoginModal = (e) => {
        document.getElementById('loginModal').classList.toggle('hidden')
    }

    const toggleSignUpModal = (e) => {
        toggleLoginModal()
        document.getElementById('signUpModal').classList.toggle('hidden')
    }

    return (
        <>
            <div className="hidden fixed z-50 bg-gray-200 w-full h-full" id='cart-panel'>
                <div className="relative flex justify-center text-white sm:justify-start">
                    <div className="container pl-20">
                        <h1 className="text-4xl flex mt-6 text-dark-olive">
                            <span className=" mr-2">
                                <FaBasketShopping className='text-radiant-lime-green' />    
                            </span>Your Shopping Cart.
                        </h1>
                        <FaCircleXmark className='absolute top-12 right-10 sm:top-10 sm:right-20 md:top-10 md:right-20 lg:top-10 lg:right-20 xl:top-10 xl:right-20 size-10 text-dark-olive cursor-pointer' id='closeCartElement' onClick={handleCartClose} />
                    </div>
                </div>
                <div className="container mx-auto mt-4 overflow-y-auto h-full" id='cart-scroll'>
                    <div className="flex my-4">
                        <div className="w-full bg-transparent px-10">
                            <div className="mt-10 mb-5 px-6 hidden sm:flex md:flex lg:flex xl:flex">
                                <h3 className="font-semibold text-gray-600 text-xl uppercase w-2/5">
                                    PRODUCT
                                </h3>
                                <h3 className="font-semibold text-left text-gray-600 text-xl uppercase w-1/5">
                                    PRICE
                                </h3>
                                <h3 className="font-semibold text-left text-gray-600 text-xl uppercase w-1/5">
                                    QUANTITY
                                </h3>
                                <h3 className="font-semibold text-left text-gray-600 text-xl uppercase w-1/5">
                                    TOTAL
                                </h3>
                            </div>
                            <div className="cart-items-wrapper" >
                                {
                                    cartItems?.map((product) => {
                                        return (
                                            <div className="hidden sm:flex md:flex lg:flex xl:flex items-center hover:bg-gray-100 hover:rounded-md px-6 py-5 cursor-pointer">
                                                <div className="flex w-2/5">
                                                    {/* Product */}
                                                    <div className="w-20">
                                                        <img 
                                                            src={product?.attributes?.ProductImage?.data?.attributes?.formats?.thumbnail?.url} 
                                                            alt={product?.attributes?.ProductName} 
                                                            className="h-12 rounded-xl" 
                                                        />
                                                    </div>
                                                    <div className="flex flex-col justify-between ml-4 flex-grow">
                                                        <span className="font-semibold text-md text-dark-olive">
                                                            { product?.attributes?.ProductName }
                                                        </span>
                                                    </div>
                                                </div>

                                                <span className="text-left w-1/5 font-semibold text-md text-dark-olive">
                                                    {product?.attributes?.Currency?.data?.attributes?.Symbol} { product?.attributes?.UnitPrice }
                                                </span>

                                                <div className="flex text-center w-1/5">
                                                    <FaMinus className='cursor-pointer text-dark-olive mt-3 text-radiant-lime-green' onClick={() => handleQuantityDecrement(product)} />

                                                    <input type="text" className="mx-2 border rounded-md text-center w-14" value={product.quantity} readOnly />

                                                    <FaPlus className='cursor-pointer text-dark-olive mt-3  text-radiant-lime-green' onClick={() => handleQuantityIncrement(product)} />
                                                </div>

                                                <span className="text-left w-1/5 font-semibold text-md text-dark-olive">
                                                    {product?.attributes?.Currency?.data?.attributes?.Symbol} { product?.attributes?.UnitPrice * product.quantity }
                                                </span>
                                                <a href="#!" className="text-left font-semibold text-md text-dark-olive" onClick={() => handleProductRemovalFromCart(product)}>
                                                    <FaXmark />
                                                </a>
                                            </div>
                                        )
                                    })
                                }

                                {/* Mobile Screens */}
                                {
                                    cartItems?.map((product) => {
                                        return (
                                            <div className="inline-block w-full sm:hidden md:hidden lg:hidden xl:hidden mt-5" key={product.id}>
                                                <a href="#!" className="block h-64 shadow-lg bg-white hover:shadow-xl">
                                                    <img 
                                                        src={product?.attributes?.ProductImage?.data?.attributes?.formats?.thumbnail?.url} 
                                                        alt={product?.attributes?.ProductName} 
                                                        className="rounded-lg w-full h-full" 
                                                    />
                                                </a>
                                                <div className="flex items-center justify-between mt-3">
                                                    <div>
                                                        <a href="#!" className="font-medium">
                                                            { product?.attributes?.ProductName }
                                                        </a>
                                                        <a href="#!" className="flex items-center">
                                                            <span className="text-sm font-medium text-dark-olive">
                                                            {product?.attributes?.Currency?.data?.attributes?.Symbol} { product?.attributes?.UnitPrice }
                                                            </span>
                                                        </a>
                                                        <a href="#!" className="flex items-center">
                                                            <span className="text-sm font-bold text-dark-olive">
                                                            {product?.attributes?.Currency?.data?.attributes?.Symbol} { product?.attributes?.UnitPrice * product.quantity }
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <span className="flex items-center h-8 text-dark-olive text-3xl px-2 rounded cursor-pointer">
                                                        <FaMinus className='cursor-pointer text-dark-olive size-6 text-radiant-lime-green' onClick={() => handleQuantityDecrement(product)} />

                                                        <input type="text" className="mx-2 border rounded-md text-center w-12" value={product.quantity} readOnly />

                                                        <FaPlus className='cursor-pointer text-dark-olive size-6 text-radiant-lime-green' onClick={() => handleQuantityIncrement(product)} />
                                                    </span>
                                                    <FaCircleXmark className=' right-20 size-10 text-radiant-lime-green cursor-pointer' onClick={() => handleProductRemovalFromCart(product)} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    {/* Large Screens */}
                    <div className="hidden sm:flex md:flex lg:flex xl:flex my-10 pb-16">
                        <div className="w-full px-10">
                            <div className="flex items-center bg-dark-olive rounded-lg px-6 py-5">

                                <div className="w-2/4">
                                    <h3 className="text-xl font-semibold text-radiant-yellow -mt-4">CHOOSE DELIVERY MODE</h3>

                                    <div className='mt-4'>
                                        <input 
                                            type="radio" 
                                            name="delivery_mode" 
                                            value="pick_up" 
                                            className="bg-radiant-yellow size-8 cursor-pointer" 
                                            onChange={(e) => setDeliveryMode(e.target.value)}
                                        />
                                        
                                        <span className="p-8 text-md text-white">Pick up your Order</span>
                                    </div>
                                    <div className='mt-4'>
                                        <input 
                                            type="radio" 
                                            name="delivery_mode" 
                                            value="deliver" 
                                            className="bg-radiant-yellow size-8 cursor-pointer" 
                                            onChange={(e) => setDeliveryMode(e.target.value)}
                                        />
                                        
                                        <span className="p-8 text-md text-white">
                                            Deliver to your Location
                                        </span>
                                    </div>
                                </div>
                                <div className="w-2/4">
                                    <div className="flex text-white">
                                        <div className="w-2/4">
                                            <h3 className='text-md font-semibold'>SUB TOTAL</h3>
                                        </div>
                                        <div className="w-2/4">
                                            <h3 className='text-md'>UGX { cartTotal }</h3>
                                        </div>
                                    </div>
                                    <div className="flex text-white">
                                        <div className="w-2/4">
                                            <h2 className='text-md font-semibold'>GRAND TOTAL</h2>
                                        </div>
                                        <div className="w-2/4">
                                            <h2 className='text-md'>UGX { cartTotal }</h2>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <button className="flex text-md text-left bg-radiant-yellow text-dark-olive w-full p-2 rounded-lg" onClick={handleCheckoutClick}>
                                            <div className="w-2/4">
                                                <h3 className="font-semibold">
                                                    CHECKOUT
                                                </h3>
                                            </div>
                                            <div className="w-2/4">
                                                <h3 className='font-semibold'>
                                                    UGX { cartTotal }
                                                </h3>
                                            </div>
                                            
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    {/* Mobile Screens */}
                    <div className="flex sm:hidden md:hidden lg:hidden xl:hidden mb-30 mt-14 pb-28">
                        <div className="w-full px-10">
                            <div className="bg-dark-olive rounded-lg px-6 py-5">
                                <h3 className="text-xl font-semibold text-radiant-yellow">CHOOSE DELIVERY MODE</h3>
                                
                                <div className='mt-4'>
                                    <input 
                                        type="radio" 
                                        name="delivery_mode" 
                                        value="pick_up" 
                                        className="bg-radiant-yellow size-8 cursor-pointer" 
                                        onChange={(e) => setDeliveryMode(e.target.value)}
                                    />
                                    
                                    <span className="p-8 text-md text-white">Pick up your Order</span>
                                </div>
                                <div className='mt-4'>
                                    <input 
                                        type="radio" 
                                        name="delivery_mode" 
                                        value="deliver" 
                                        className="bg-radiant-yellow size-8 cursor-pointer" 
                                        onChange={(e) => setDeliveryMode(e.target.value)}
                                    />
                                    
                                    <span className="p-8 text-md text-white">
                                        Deliver to your Location
                                    </span>
                                </div>
                                <div className="flex text-white mt-6">
                                    <div className="w-2/4">
                                        <h3 className='text-md font-semibold'>SUB TOTAL</h3>
                                    </div>
                                    <div className="w-2/4">
                                        <h3 className='text-md'>UGX { cartTotal }</h3>
                                    </div>
                                </div>
                                <div className="flex text-white">
                                    <div className="w-2/4">
                                        <h2 className='text-md font-semibold'>GRAND TOTAL</h2>
                                    </div>
                                    <div className="w-2/4">
                                        <h2 className='text-md'>UGX { cartTotal }</h2>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button className="flex text-md text-left bg-radiant-yellow text-dark-olive w-full p-2 rounded-lg" onClick={handleCheckoutClick}>
                                        <div className="w-2/4">
                                            <h3 className="font-semibold">
                                                CHECKOUT
                                            </h3>
                                        </div>
                                        <div className="w-2/4">
                                            <h3 className='font-semibold'>
                                                UGX { cartTotal }
                                            </h3>
                                        </div>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Login Modal */}
            <div className="fixed z-50 overflow-y-auto top-0 w-full left-0 hidden" id="loginModal">
                <div className="flex items-center justify-center min-h-60vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#203;</span>
                    <div className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-9 sm:align-middle sm:max-w-6xl sm:w-full md:w-3/4 lg:w-3/3 xl:w-3/3 h-[80vh] md:h-[50vh]" role='dialog' aria-modal="true" aria-labelledby='modal-headline'>
                        <div className="bg-dark-olive py-3">
                            <p className="text-2xl text-radiant-lime-green font-semibold ml-6">Login</p>
                            <button className="py-2 px-4 text-black rounded mr-2 absolute right-0 -mt-9" onClick={toggleLoginModal}>
                                <FaCircleXmark className='text-radiant-lime-green size-6' />
                            </button>
                        </div>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-[70vh]">
                            {
                                user?.jwt ? (
                                    <>
                                        <div className="mt-8">
                                            <button className="rounded-3xl bg-radiant-lime-green hover:bg-gray-400 px-4 block w-full text-white justify-center whitespace-no-wrap" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <form onSubmit={handleLogin}>
                                            <input type="text" name='identifier' className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3" placeholder='Email Address' onChange={handleLoginChange} />

                                            <input type="password" name="loginPassword" id="" className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3" placeholder='Password' onChange={handleLoginChange} />

                                            <button type="submit" className="rounded-3xl bg-radiant-lime-green hover:bg-gray-400 py-2 px-4 block w-full text-white justify-center whitespace-no-wrap">
                                                <span className="ml-4">
                                                    Login
                                                </span>
                                            </button>
                                        </form>

                                        <p className="font-normal mt-2">Not a member? <a href="#!" onClick={toggleSignUpModal}><span className="text-radiant-lime-green">Sign Up Here</span></a></p>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShoppingCart