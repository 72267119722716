import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import retailersService from './retailersService'

const initialState = {
    retailers: [],
    nearestRetailers: [],
    isErrorRetailers: false,
    isSuccessRetailers: false,
    isLoadingRetailers: false,
    message: ''
}

// Get Retailers Data
export const getRetailers = createAsyncThunk('retailers/list', async (_, thunkAPI) => {
    try {
        
        return await retailersService.getRetailers()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get Nearest Retailers
export const getNearestRetailers = createAsyncThunk('retailers/nearest', async (_, thunkAPI) => {
    try {
        
        return await retailersService.getNearestRetailers()
        
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const retailersSlice = createSlice({
    name: 'retailers',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingRetailers = false
            state.isErrorRetailers = false
            state.isSuccessRetailers = false
            state.message = ''
            state.retailers = []
            state.nearestRetailers = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRetailers.pending, (state) => {
                state.isLoadingRetailers = true
            })
            .addCase(getRetailers.fulfilled, (state, action) => {
                state.isLoadingRetailers = false
                state.isSuccessRetailers = true
                state.retailers = action.payload
            })
            .addCase(getRetailers.rejected, (state, action) => {
                state.isLoadingRetailers = false
                state.isErrorRetailers = true
                state.message = action.payload
                state.retailers = []
            })
            .addCase(getNearestRetailers.pending, (state) => {
                state.isLoadingRetailers = true
            })
            .addCase(getNearestRetailers.fulfilled, (state, action) => {
                state.isLoadingRetailers = false
                state.isSuccessRetailers = true
                state.nearestRetailers = action.payload
            })
            .addCase(getNearestRetailers.rejected, (state, action) => {
                state.isLoadingRetailers = false
                state.isErrorRetailers = true
                state.message = action.payload
                state.nearestRetailers = []
            })
    }
})

export const { reset } = retailersSlice.actions
export default retailersSlice.reducer