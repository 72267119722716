import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import './styles/Home.css'
import { FaChevronLeft, FaChevronRight, FaTrademark } from 'react-icons/fa6'
import { Link as ScrollLink, scrollSpy } from 'react-scroll'
import { getAllCarouselContent } from '../../features/homePageContent/homePageSlice'

const Home = ({ onNavClick, activeLink }) => {

    const dispatch = useDispatch()
    const { carouselContent, isLoadingHome, isErrorHome, message } = useSelector((state) => state.homePage)

    const [currentSlide, setCurrentSlide] = useState(1)
    const [checked, setChecked] = useState(true)

    useEffect(() => {
        dispatch(getAllCarouselContent())
    }, [dispatch])

    useEffect(() => {
        scrollSpy.update()
    }, [])

    // Create a shallow copy of the carouselContent data before sorting
    const sortedCarouselContent = [...(carouselContent?.data || [])].sort((a, b) => a?.attributes?.Order - b?.attributes?.Order)

    if (isLoadingHome) {
        return <p>Loading...</p>;
    }

    if (!carouselContent || carouselContent?.data?.length === 0) {
        return <div>Loading...</div>;
    }

    if (isErrorHome) {
        return <p>An error occurred...</p>
    }

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 3 ? 1 : prevSlide + 1))
    }

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 1 ? 3 : prevSlide - 1))
    }

    const goToSlide = (slideNumber) => {
        setCurrentSlide(slideNumber)
    }

    const toggleSignUpModal = (e) => {

        document.getElementById('signUpModal').classList.toggle('hidden')
         
    }
    
    return (
        <>
            {
                sortedCarouselContent?.length > 0 ? (
                    <div className="carousel bg-gray-500 relative mx-auto carousel-height">
                        <div className="carousel-inner relative overflow-hidden w-full">
                            {/* <!--Slide 1--> */}
                            <input className="carousel-open hidden" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" defaultChecked />
                            <div className={`carousel-item absolute opacity-0 ${currentSlide === 1 ? "opacity-100" : ""}`} style={{height:"88vh"}}>
                                <div className="absolute h-full w-full bg-dark-olive opacity-50 top-22 right-0"></div>
                                <div className="block h-full w-full mx-auto flex md:pt-0 md:items-center bg-cover bg-right" style={{backgroundImage: `url(${sortedCarouselContent[0]?.attributes?.CarouselImage?.data?.attributes?.url})`}}>

                                    {/* <!--  add any content in this box  --> */}
                                    <div className="w-3/5 mx-auto z-10 text-white mt-24 md:-mt-38">
                                        <h1 className="text-4xl md:text-7xl">
                                            {
                                                sortedCarouselContent[0]?.attributes?.Title
                                            }
                                        </h1>
                                        <p className="mt-8 md:mt-2 text-xl md:text-2xl">
                                            {
                                                sortedCarouselContent[0]?.attributes?.SubText
                                            }
                                        </p>
                                        {
                                            sortedCarouselContent[0]?.attributes?.SubText2 !== null ? (
                                                <p className="mt-4 md:mt-2 text-xl md:text-2xl">
                                                    {
                                                        sortedCarouselContent[0]?.attributes?.SubText2
                                                    }
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {/* <h1 className="text-5xl md:text-7xl">We are <span className="text-radiant-yellow font-semibold">Progressive AgriBusiness</span>.</h1> */}
                                        {/* <p className="mt-8 md:mt-2 text-xl md:text-2xl">
                                            Welcome to our Society of High Achievers. If you are a Livestock farmer, or on your way to becoming one, <span className="text-radiant-yellow">Sign up</span> to <span className="text-radiant-yellow">buy Feeds, Merch</span> &amp; get <span className="text-radiant-yellow">Teaching Resources</span>.
                                        </p> */}
                                        <button className="bg-radiant-yellow text-2xl text-dark-olive font-semibold mt-20 md:mt-10 pt-2 pb-2 pl-4 pr-4 cursor-pointer rounded-lg" onClick={toggleSignUpModal}>
                                            {
                                                sortedCarouselContent[0]?.attributes?.ButtonText
                                            }
                                        </button>
                                    </div> 
                                </div>
                            </div>
                            
                            <label for="carousel-4" className="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 left-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 1 ? 4 : prevSlide - 1))}>‹</label>
                            <label for="carousel-2" className="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 right-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 4 ? 1 : prevSlide + 1))}>›</label>

                            {/* <!--Slide 2--> */}
                            <input className="carousel-open hidden" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="" />
                            <div className={`carousel-item absolute opacity-0 ${currentSlide === 2 ? "opacity-100" : ""} bg-cover bg-right`} style={{height:"88vh"}}>
                                <div className="absolute h-full w-full bg-dark-olive opacity-50 top-22 right-0"></div>
                                <div className="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-right" style={{backgroundImage: `url(${sortedCarouselContent[1]?.attributes?.CarouselImage?.data?.attributes?.url})`}}>

                                    <div className="w-3/5 mx-auto z-10 text-white mt-20 md:-mt-38">
                                        <h1 className="text-4xl md:text-7xl">
                                            {
                                                sortedCarouselContent[1]?.attributes?.Title
                                            }
                                        </h1>
                                        <p className="mt-8 md:mt-2 text-xl md:text-2xl">
                                            {
                                                sortedCarouselContent[1]?.attributes?.SubText
                                            }
                                        </p>
                                        {
                                            sortedCarouselContent[1]?.attributes?.SubText2 !== null ? (
                                                <p className="mt-4 md:mt-2 text-xl md:text-2xl">
                                                    {
                                                        sortedCarouselContent[1]?.attributes?.SubText2
                                                    }
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }

                                        <div className="mt-10">
                                            <ScrollLink 
                                                    to="shop" 
                                                    spy={true}  
                                                    className='bg-radiant-yellow text-2xl text-dark-olive font-semibold mt-20 md:mt-10 pt-4 pb-4 pl-4 pr-4 cursor-pointer rounded-lg'
                                                    smooth={true} 
                                                    onClick={() => onNavClick("shop")}
                                                >
                                                    {
                                                        sortedCarouselContent[1]?.attributes?.ButtonText
                                                    }
                                            </ScrollLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label for="carousel-1" className="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 left-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 1 ? 4 : prevSlide - 1))}>‹</label>
                            <label for="carousel-3" className="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 right-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 4 ? 1 : prevSlide + 1))}>›</label>

                            {/* <!--Slide 3--> */}
                            <input className="carousel-open hidden" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="" />
                            <div className={`carousel-item absolute opacity-0 ${currentSlide === 3 ? "opacity-100" : ""}`} style={{height:"88vh"}}>
                            <div className="absolute h-full w-full bg-dark-olive opacity-50 top-22 right-0"></div>
                                <div className="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-center" style={{backgroundImage: `url(${sortedCarouselContent[2]?.attributes?.CarouselImage?.data?.attributes?.url})`}}>

                                    <div className="w-3/5 mx-auto z-10 text-white mt-20 md:-mt-38">
                                        <h1 className="text-4xl md:text-7xl">
                                            {
                                                sortedCarouselContent[2]?.attributes?.Title
                                            }
                                        </h1>
                                        <p className="mt-8 md:mt-2 text-xl md:text-2xl">
                                            {
                                                sortedCarouselContent[2]?.attributes?.SubText
                                            }
                                        </p>
                                        {
                                            sortedCarouselContent[2]?.attributes?.SubText2 !== null ? (
                                                <p className="mt-4 md:mt-2 text-xl md:text-2xl">
                                                    {
                                                        sortedCarouselContent[2]?.attributes?.SubText2
                                                    }
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }

                                        <div className="mt-10">
                                            <ScrollLink 
                                                    to="shop" 
                                                    spy={true}  
                                                    className='bg-radiant-yellow text-2xl text-dark-olive font-semibold mt-20 md:mt-10 pt-4 pb-4 pl-4 pr-4 cursor-pointer rounded-lg'
                                                    smooth={true} 
                                                    onClick={() => onNavClick("shop")}
                                                >
                                                    {
                                                        sortedCarouselContent[2]?.attributes?.ButtonText
                                                    }
                                            </ScrollLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label for="carousel-2" className="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 left-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 1 ? 4 : prevSlide - 1))}>‹</label>
                            <label for="carousel-4" className="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 right-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 4 ? 1 : prevSlide + 1))}>›</label>

                            {/* <!--Slide 4--> */}
                            <input className="carousel-open hidden" type="radio" id="carousel-4" name="carousel" aria-hidden="true" hidden="" />
                            <div className={`carousel-item absolute opacity-0 ${currentSlide === 4 ? "opacity-100" : ""}`} style={{height:"88vh"}}>
                            <div className="absolute h-full w-full bg-dark-olive opacity-50 top-22 right-0"></div>
                                <div className="block h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-cover bg-center" style={{backgroundImage: `url(${sortedCarouselContent[3]?.attributes?.CarouselImage?.data?.attributes?.url})`}}>

                                    <div className="w-3/5 mx-auto z-10 text-white mt-20 md:-mt-38">

                                        <h1 className="text-4xl md:text-7xl">
                                            {
                                                sortedCarouselContent[3]?.attributes?.Title
                                            }
                                        </h1>
                                        <p className="mt-8 md:mt-2 text-xl md:text-2xl">
                                            {
                                                sortedCarouselContent[3]?.attributes?.SubText
                                            }
                                        </p>
                                        {
                                            sortedCarouselContent[3]?.attributes?.SubText2 !== null ? (
                                                <p className="mt-4 md:mt-2 text-xl md:text-2xl">
                                                    {
                                                        sortedCarouselContent[3]?.attributes?.SubText2
                                                    }
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                        }

                                        <div className="mt-10">
                                            <ScrollLink 
                                                    to="shop" 
                                                    spy={true}  
                                                    className='bg-radiant-yellow text-2xl text-dark-olive font-semibold mt-20 md:mt-10 pt-4 pb-4 pl-4 pr-4 cursor-pointer rounded-lg'
                                                    smooth={true} 
                                                    onClick={() => onNavClick("shop")}
                                                >
                                                    {
                                                        sortedCarouselContent[3]?.attributes?.ButtonText
                                                    }
                                            </ScrollLink>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <label for="carousel-3" className="prev control-4 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 left-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 1 ? 4 : prevSlide - 1))}>‹</label>
                            <label for="carousel-1" className="next control-4 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-radiant-yellow hover:text-white rounded-full bg-opacity-20 bg-dark-olive hover:bg-gray-900  leading-tight text-center z-10 inset-y-0 right-0 my-auto" onClick={() => setCurrentSlide((prevSlide) => (prevSlide === 4 ? 1 : prevSlide + 1))}>›</label>

                            {/* <!-- slide indicators --> */}
                            <ol className="carousel-indicators">
                                <li className="inline-block mr-3">
                                    <label for="carousel-1" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900" onClick={() => setCurrentSlide(1)}>•</label>
                                </li>
                                <li className="inline-block mr-3">
                                    <label for="carousel-2" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900" onClick={() => setCurrentSlide(2)}>•</label>
                                </li>
                                <li className="inline-block mr-3">
                                    <label for="carousel-3" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900" onClick={() => setCurrentSlide(3)}>•</label>
                                </li>
                                <li className="inline-block mr-3">
                                    <label for="carousel-4" className="carousel-bullet cursor-pointer block text-4xl text-gray-400 hover:text-gray-900" onClick={() => setCurrentSlide(4)}>•</label>
                                </li>
                            </ol>

                        </div>
                    </div>
                ) : (
                    ''
                )
            }
        </>
    )
}

export default Home