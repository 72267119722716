import axios from 'axios'

const NODE_ENV = process.env.REACT_APP_NODE_ENV

const API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL
const RETAILERS_URI = '/retailers?populate[districts][populate]=*&populate[retailer_catalogues][populate][products]=*&populate[retailer_catalogues][populate][unit_of_measure]=*&populate[retailer_catalogues][populate][currencies]=*'
const NEAREST_RETAILERS_URI = '/retailers'

// Get Retailers
const getRetailers = async () => {

    const response = await axios.get(`${API_URL}${RETAILERS_URI}`)

    return response.data
}

const getNearestRetailers = async (region, district) => {

    const response = await axios.get(`${API_URL}${NEAREST_RETAILERS_URI}?region=${region}&district=${district}`)

    return response.data
}

const retailersService = {
    getRetailers,
    getNearestRetailers
}

export default retailersService