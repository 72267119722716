import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/Testimonials.css'
import { getTestimonials } from '../../features/testimonials/testimonialsSlice'

const Testimonials = () => {

    const dispatch = useDispatch()

    const [selectedSlide, setSelectedSlide] = useState(0)

    let { testimonials, isLoadingTestimonials, isErrorTestimonials } = useSelector((state) => state.testimonials)

    useEffect(() => {
        dispatch(getTestimonials())
    }, [])

    const handleRadioChange = (index) => {
        setSelectedSlide(index)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedSlide((prev) => (prev + 1) % testimonials?.data?.length)
        }, 5000) // Change slide every 5 seconds

        return () => clearInterval(interval)
    }, [testimonials?.data?.length])

    return (
        <>
            <div className="relative flex justify-center text-white sm:justify-start bg-white">
                <div className="container pl-20 pt-20">
                    <h1 className="text-5xl text-dark-olive">
                        Testimonials.
                    </h1>
                </div>
            </div>
            <div className="testimonials-section">
                <div className="container text-center">
                    {
                        testimonials?.data?.length > 0 ? (
                            testimonials?.data?.map((testimonial, index) => {
                                return <input 
                                    key={index}
                                    type="radio" 
                                    name='slider' 
                                    title={`slide${index + 1}`} 
                                    checked={selectedSlide === index} 
                                    onChange={() => handleRadioChange(index)} 
                                    className='slider__nav'
                                />
                            })
                        ) : (
                            <></>
                        )
                    } 
                </div>
                
                <div className="slider__inner" style={{ left: `-${selectedSlide * 100}%` }}>
                    {
                        testimonials?.data?.length > 0 ? (
                            testimonials?.data?.map((testimonial, index) => {
                                return (
                                    <div key={index} className="slider__contents p-3">
                                        <span className="quote text-radiant-lime-green">
                                            &rdquo;
                                        </span>
                                        <p className="slider__txt">
                                            {
                                                testimonial?.attributes?.Remarks
                                            }
                                        </p>
                                        <h2 className="slider__caption">
                                            {
                                                testimonial?.attributes?.ClientNames
                                            }
                                        </h2>
                                    </div>
                                )
                            })
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Testimonials