import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import locationService from './locationService'

const initialState = {
    regions: [],
    districts: [],
    district: null,
    isErrorLocations: false,
    isSuccessLocations: false,
    isLoadingLocations: false,
    message: ''
}

// Get Regions
export const getRegions = createAsyncThunk('locations/allRegions', async (_, thunkAPI) => {
    try {
        
        return await locationService.getRegions()

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


// Get Districts
export const getDistricts = createAsyncThunk('locations/allDistricts', async (id, thunkAPI) => {
    try {
        
        return await locationService.getDistricts(id)

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

// Get District By Name
export const getDistrictByName = createAsyncThunk('locations/getDistrictByName', async (district, thunkAPI) => {
    try {
        
        return await locationService.getDistrictByName(district)

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingLocations = false
            state.isErrorLocations = false
            state.isSuccessLocations = false
            state.message = ''
            state.regions = []
            state.districts = []
            state.district = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRegions.pending, (state) => {
                state.isLoadingLocations = true
            })
            .addCase(getRegions.fulfilled, (state, action) => {
                state.isLoadingLocations = false
                state.isSuccessLocations = true
                state.regions = action.payload
            })
            .addCase(getRegions.rejected, (state, action) => {
                state.isLoadingLocations = false
                state.isErrorLocations = true
                state.message = action.payload
                state.regions = []
            })
            .addCase(getDistricts.pending, (state) => {
                state.isLoadingLocations = true
            })
            .addCase(getDistricts.fulfilled, (state, action) => {
                state.isLoadingLocations = false
                state.isSuccessLocations = true
                state.districts = action.payload
            })
            .addCase(getDistricts.rejected, (state, action) => {
                state.isLoadingLocations = false
                state.isErrorLocations = true
                state.message = action.payload
                state.districts = []
            })
            .addCase(getDistrictByName.pending, (state) => {
                state.isLoadingLocations = true
            })
            .addCase(getDistrictByName.fulfilled, (state, action) => {
                state.isLoadingLocations = false
                state.isSuccessLocations = true
                state.district = action.payload
            })
            .addCase(getDistrictByName.rejected, (state, action) => {
                state.isLoadingLocations = false
                state.isErrorLocations = true
                state.message = action.payload
                state.district = null
            })
    }
})

export const { reset } = locationSlice.actions
export default locationSlice.reducer